.businessMap > img{
    width: 100%;
    border-radius: 2em;
}

.businessDescr{
    font-size: clamp(2vw, 18px, 28px)
}

.businessContacts{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 1em;
    padding: 2em;
}

.businessContacts i{
    color: var(--b800);
    font-size: 1.3em;
    background: #ffffff17;
    padding: 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.businessSocials-item:hover a{
    color: var(--b800)
}

.full_business__faqContainer{
    flex-wrap: wrap;
    font-size: 18px;
}

.full_business__faqContainer .spoiler_body{
    border-top: 1px dashed var(--d400);
    padding-top: 1em;
    width: 94%;
    margin: 1em auto 0 auto;
}

.full_business__faqContainer > .spoiler{
    flex: 1 1 calc(50% - 1em);
}

.full_business__aboutContainer > img{
    float: right;
    clear: both;
    display: table;
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.full_business__mediaContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}
.full_business__mediaContainer > .mediaImage{
    border-radius: 1em;
    flex: 0 0 calc(33.333% - 1em);
    overflow: hidden;
    width: calc(33.333% - 1em);
    padding-bottom: 24%;
    position: relative;
}

.full_business__advantagesContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: flex-start;
}

.full_business__advantagesContainer > div{
    flex: 0 0 calc(25% - 1em);
}

.advantagesListWithIcons__item{
    display: inline-flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    justify-content: left;
    background: var(--d800);
    border-radius: 20px;
    padding: 5px 10px;
}

/*.advantagesListWithIcons__item{
    display: inline-flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
}*/
.advantagesListWithIcons__item img{
    width: 34px;
}

.checkElements .checkElements__item{
    position: relative;
    padding-left: 30px;
    display: inline-flex;
    align-items: center;
}

.checkElements .checkElements__item::before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: var(--b800); /* Цвет круга */
    border-radius: 50%; /* Делаем элемент круглым */
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkElements .checkElements__item::after{
    content: '✔';
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 12px;
}

.full_business__servicesContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}
.full_business__servicesContainer > div{
    flex: 0 0 calc(33.333% - 1em)
}

.full_business__spacer{
    position: relative;
    height: 1px;
    width: 100%;
    margin: 60px auto;
    font-size: 24px;
    display: flex;
    align-items: center;
}
.full_business__spacer > span {
    padding: 0 20px;
    flex: 1 1 auto;
    text-align: center;
    white-space: nowrap;
}
.full_business__spacer::before, .full_business__spacer::after{
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--d400);
}

.full_business__advantagesCategorySpacer{
    position: relative;
    height: 1px;
    width: 100%;
    margin: 10px 0 0 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.full_business__menu{
    position: relative;
}
.full_business__menu > *{
    display: inline-block;
    padding: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
}
.full_business__cover{
    z-index: 1;
    filter: blur(10px);
    animation-delay: 0.2s;
    animation: businessCover 0.8s ease forwards;
}
.full_business__header{
    position: relative;
    width: 100%;
    padding-bottom: 20%;
    min-height: 30vh;
    overflow: hidden;
}
.full_business__header::before{
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.333);
    z-index: 2;
}
.full_business__header-body{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    z-index: 3;
}
.full_business__header-bottom{
    position: absolute;
    bottom: 0;
    padding: 20px 30px 20px 20px;
    left: 0;
    width: 100%;
    z-index: 3;
    user-select: none;
}
.full_business__header-info{
    transform: translateY(-50%) scale(1.3);
    opacity: 0;
    animation: businessShrink 0.8s ease forwards;
    animation-delay: 0.2s;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.63);
}

@keyframes businessCover {
    from {
        filter: blur(10px);
    }
    to {
        filter: blur(0);
    }
}

@keyframes businessShrink {
    from {
        transform: translateY(-50%) scale(1.3);
        opacity: 0.5;
    }
    to {
        transform: translateY(-50%) scale(1);
        opacity: 1;
    }
}

.full_business__header-logo{
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;
    border-radius: 16px;
    z-index: 1;
    box-shadow: 0 0 14px rgba(0,0,0,0.7);
    flex: 0 0 120px;
}

.full_business__menu::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--nav-before-left, 0);
    height: 80%;
    border-radius: 50px;
    background: white;
    transition: all 0.3s ease;
    width: var(--nav-before-width, 0);
    z-index: -1;
    opacity: 0.2;
    padding: var(--nav-before-padding, 0);
    margin: var(--nav-before-margin, 0);
}
.full_business__menu li {
    z-index: 2;
    cursor: pointer;
}
.full_business__menu li.active {
    text-shadow: none;
}

.businessMenuSticky {
    position: fixed !important;
    top: 0;
    bottom: auto !important;
    background: rgba(39, 48, 58, 0.5);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid var(--d600);
}



.businessRatingFull{
    background: var(--d800);
    border-radius: var(--base-radius-16);
    padding: 1em;
}
.businessRatingFull-rating{
    font-size: 56px;
}
.businessRatingFull-rateBox{
    padding: 0.5em 1em;
    align-items: center;
}
.businessRatingFull-rateUser{
    padding-left: 2em;
    margin-left: 1em;
    border-left: 1px solid var(--d700);
}
.businessRatingFull-ratingMore .rateStar{
    font-size: 16px;
}
.businessRatingFull-reviewBtn{
    padding-top: 10px;
    border-top: 1px solid var(--d700);
    cursor: pointer;
}






.businessOverallRating{
    flex: 1 0 auto;
}
.rateBoxes{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    flex: 1 0 auto;
    padding: 0 20px;
}

.rateBoxes .rateBox{
    flex: 1;
    height: 8px;
    background: var(--d700);
    border-radius: 2px;
}

.rateBoxes .rateBox.active{
    background: var(--b800);
}

.overallRating-userReview{
    border-width: 1px 0;
    border-color: var(--d750);
    border-style: solid;
    padding: 20px 0;
}
.businessReview-images{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.businessReview-images .businessReview-images--item{
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    border-radius: var(--base-radius);
}


.businessReview-rating .rateStar{
    justify-content: left;
    font-size: 1em;
}