@media screen and (max-width: 1000px) {

    .relative_posts .box_wht {width: 50%}

    .content__l,
    .content__r, .block__l { display: none }
    .post_content { padding: 10px 10px 0 10px }

    /*.form-item__label {padding: 10px 0 0 0;font-weight: bold; line-height:20px}*/
    .form-item__label, .form-item__control { display:block; width: 100%}
    .form-item__control > [class^=col_] {width:100%}

    .min_info {font-size: 12px;padding: 0 5px}


    .full_business__header-bottom{
        display: none;
    }

    .full_business__advantagesContainer > div {
        flex: 0 0 calc(33.333% - 1em);
    }

    .auth_info{
        display: none;
    }

}