:root{
    --white: #FFF;

    --g500: #92979c;
    --g400: #a7a7a7;
    --g300: #b7b7b7;
    --g100: #e7e7e7;
    --g50: #f5f5f5; /* линии между йтемами */

    --gb: #ecf0f2;

    --j900: #152734;
    --j800: #273e4e;

    --d1000: #131313;
    --d950: #101419;
    --d900: #151a20;
    --d800: #1b2129;
    --d700: #27303a;
    --d640: #393c3f;
    --d600: #475463;
    --d540: #545b64;
    --d400: #6a727b;

    --b1000: #238ae1;
    --b800: #1aa7ec;
    --b600: #1d9ad8; /* только  на сайте */
    --b500: #75b0e4;
    --b400: #59c1e8;
    --b200: #c0cfdf; /* Тусклый синий для текстов иногда */

    --green800: #0dc90d;
    --green400: #81b91e;

    --y300: #FFFBA8;

    --r1100: #b31b23;
    --r1000: #ff0000;
}