@media screen and (max-width: 430px) {

    .prof_username {font-size: 20px}

    .moz_mny > .moz__item,
    .moz_mny > .moz-v { height: 250px }

    .short_bikes li {width:100%}
    .short_bikes.short_for_main li {width: 50%}

    .album_short { width: 100%}
}