.mapSelectArea .circle{
    position: absolute;
    border: 2px dashed rgba(173, 181, 189, 0.8);
    border-radius: 50%;
    pointer-events: none;
    background: rgba(173, 181, 189, 0.2);
}

.mapSelectArea .radius{
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: rgba(0, 0, 0, 0.5);
    color: #FFF;
    padding: 10px;
    text-align: center;
    transform: translate(-50%, -50%);
}

.mapEventNotifications-menu{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.mapEventNotifications-menu .item{
    background: var(--b800);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 18px;
    border-radius: 50%;
}

.mapEventNotifications-menu .removeArea{
    background: var(--r1000);
}

.mapEventNotifications-menu .crossArea{
    background: var(--g600);
}