.full_event_detailBlock__name{
    font-size: 24px;
}

.full_event_detailBlock__name .dots_menu{
    top: 0;
    right: 0;
}

.full_event__cover{
    z-index: 1;
    animation-delay: 0.2s;
    animation: businessCover 0.8s ease forwards;
}
.full_event__header{
    position: relative;
    width: 100%;
    max-width: 1080px;
    padding-bottom: 20%;
    min-height: 30vh;
    overflow: hidden;
    border-radius: var(--base-radius-16);
    margin: auto;
}
.full_event__header::before{
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.333);
    z-index: 2;
}
.full_event__header-body{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    z-index: 3;
}
.full_event__header-bottom{
    position: absolute;
    bottom: 0;
    padding: 20px 30px 20px 20px;
    left: 0;
    width: 100%;
    z-index: 3;
    user-select: none;
}
.full_event__header-info{
    transform: translateY(-50%) scale(1.3);
    opacity: 0;
    animation: businessShrink 0.8s ease forwards;
    animation-delay: 0.2s;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.63);
}

.full_event_shareBtn{
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--d700);
    border-radius: 100%;
    border: 1px solid var(--b800);
    color: white;
}
.full_event_shareBtn:hover{
    background: var(--d600);
}


.full_event__body{
    padding: 30px 15px;
}

.fe-head-date{
    flex: 0 0 auto;
    text-align: center;
    font-size: 32px;
}
.fe-head-date > span{
    display: block;
}
.fe-head-date > .month{
    text-transform: uppercase;
    color: var(--r200);
    line-height: 1.6em;
    font-weight: 500;
}
.fe-head-date > .day{
    font-size: 26px;
}