#map{width: 100%;height: 100%}
#map .gm-style > div {
    border: none!important;
}
#routePoints {margin-bottom: 20px}

.mapConstructor *:focus{outline: none;border: none}
.mapConstructor input[type=text]::placeholder {color: var(--g200)}

.waypoints--label{margin-top: -8px;font-weight: bold; font-size: 20px}

.point_type {display: flex;padding: 20px 40px;position: relative}
.point_type > div.point_type--item {width: 20%}
.point_type--overlay {position: absolute;top: 0;left: 0;height: 100%;width: 100%;z-index: 1;text-align: center}
.point_type--overlay~.point_type--item{opacity: 0.2}
.point_type--overlay > span{display: block;position: absolute;top: 50%;transform: translateY(-50%)}
.point_type input[type=radio]{display: none}
.point_type label{cursor: pointer;width: 100%;height: 100%;display: block}
.point_type label > img{transform: scale(0.7);margin: auto}
.point_type label:hover > img{transform: scale(0.85)}
.point_type input[type=radio]:checked+label > img{transform: scale(1)}
.point_edit_address{text-align: center;}
.windowData{font-size: 14px;padding: 6px;text-align: center;max-width: 400px}

.photoMarker{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: white;
    border: 2px solid white;
    -webkit-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
}

.photoMarker .photo{
    border-radius:50%; width: 100%;
    -webkit-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
}

.photoMarker .photoContainer{
    overflow: hidden;
    border-radius: 50%;
}

.photoMarker:before{
    content: '';
    display: block;
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    -webkit-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
}

.photoMarker:hover{
    border-color: var(--r1000);
}
.photoMarker:hover::before{
    border-color: var(--r1000) transparent transparent transparent;
}
.photoMarker:hover .photo{
    transform: scale(1.2);
}

.map_sidebar {
    width: 360px;
    color: var(--g100);
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    display: none
}
.crossMap {
    position: absolute;
    z-index: 7;
    background: #00000080;
    color: #FFF;
    border: 0;
    right: 10px;
    top: 10px;
    font-size: 16px;
    padding: 12px;
    border-radius: 8px;
    display: none;
    backdrop-filter: blur(4px);
}
.maps_sidebar--bottom {
    display: none;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 15px;
    text-align: center;
    color: var(--g100);
}
.map_search_location {
    display: none;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
}
.map_search_location > i {
    position: absolute;
    top: 12px;
    left: 16px;
    color: var(--d540);
}
.map_search_location input[type=text] {
    border: 0;
    background: var(--white);
    color: var(--d540) !important;
    padding: 0 24px 0 48px;
    border-radius: 48px;
}
.maps_sidebar--bottom > .event, .maps_sidebar--bottom > .route{
    display: none;
}
.maps_sidebar--bottom.event > .event{
    display: block;
}
.maps_sidebar--bottom.route > .route{
    display: block;
}
.map_container{position: relative;width: 100%;height: 100%}

.mapContainer.fullMap{
    position: fixed;
    left: 0;
    top: 63px;
    width: 100%;
    height: calc(100% - 63px);
    z-index: 999;
    display: block;
}
.full_map{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.mapContainer.fullPageMap{
    display: flex;
    flex: 1;
    flex-direction: column;
}

.mapContainer.fullPageMap #map{
    flex: 1;
}

.route {padding: 10px 0;overflow-y: auto}
.route::-webkit-scrollbar{width: 3px;}
.route::-webkit-scrollbar-track{background: rgba(0, 0, 0, 0.1)}
.route::-webkit-scrollbar-thumb{background: var(--g500);border-radius: 3px}
.route-ditance {padding: 10px}
.route li {line-height: 30px;padding: 10px 0; border-bottom: 1px solid var(--d700)}
.route_container{
    background: var(--d900);
    padding: 20px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    user-select: none
}
.route-item{
    display: flex;
    position: relative
}
.route-item--icon, .route-item--action{
    flex: 0 0 auto;
    padding: 0;
    width: 22px;
    text-align: center;
    position: relative;
    font-size: 0.7em;
}
.add-route-item .route-item--icon,
.add-route-item .route-item--action{
    width: 40px
}
.route-item--icon > img{width: 22px; height: 26px; margin: 2px 0}
.route-item--address{flex: 1 1 auto; padding: 0 8px}

.route_container input[type=text]{background: transparent;color: var(--d400);line-height: 30px;border: 0;width: 100%;padding: 0;border-radius: 0}

.route-item{display: flex;border-radius: 15px;line-height: 30px}

.route-item--add_after {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -20px;
    fill: var(--d400);
    background: var(--d700);
    border-radius: 22px;
    padding: 5px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.route-item--add_after:hover{
    background: var(--b600);
    fill: var(--white);
}
.route-item--add_after:active{
    background: var(--d400);
}
.map_sidebar ul.route>li:last-child .route-item--add_after{
    display: none;
}
.route-item--icon .micon-plus,
.route-item--action .micon-cross{
    width: 14px;
    margin: auto;
    fill: var(--g100);
}
.route-item--action .micon-cross{
    width: 10px;
}

.add-route-item > .route-item {
    background: #27303a;
    border-radius: 0;
    margin: 0;
}

.mapConstructor{
    position: relative;
    height: 400px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: var(--d540);
}

.mapContainer {
    width: 100%;
    height: 100%;
    position:relative;
}

.mapConstructor > .overlay{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: 1;
    font-size: 1.4em;
    user-select: none;
    cursor: pointer;
}
.mapConstructor > .overlay > span{
    display: none;
}

.mapConstructor > .overlay:hover{
    background: rgba(0,0,0,0.5);
}
.mapConstructor > .overlay:hover > span{
    display: block;
}


.mapConstructor.full{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    background: rgba(0,0,0,0.8);
    z-index: 1001;
    border-radius: 0;
}

.routeType .crossMap{
    right: 370px;
}

.mapConstructor.full .routeType .map_sidebar{
    display: block;
}
.mapConstructor.full .map_search_location,
.mapConstructor.full .crossMap,
.mapContainer.fullMap .crossMap
{
    display: block;
}
.mapConstructor.full .maps_sidebar--bottom{
    display: flex;
}

.mapConstructor.full > .overlay{
    display: none;
}

.mapConstructor #map{
    height: 100%;
    width: 100%;
}

.s_event_type{
    display: flex;
    text-align: center;
    line-height: 30px;
    color: var(--white);
}
.s_event_type > div{
    width: 50%;
    padding: 10px;
    user-select: none;
}
.s_event_type-b{
    padding: 20px;
    background: var(--d900);
    border-radius: 10px;
}
.s_event_type-b:hover{
    background: var(--d600);
}

.s_event_type-b:active{
    background: var(--d800);
}

.s_event_type i{
    font-size: 50px;
}
.center_dialog_title{
    text-align: center;
    padding-top: 15px;
}

#location {
    margin-bottom: 30px;
}

#location-address {
    text-align: center;
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
}

#location-address > span:first-child{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 4px
}

.linkOpenMap{
    text-align: center;
    display: block;
    margin: 20px 0;
    color: var(--b800) !important;
}
.linkOpenMap:hover{
    color: var(--b600) !important;
}

#next-btn {
    font-size: 1.1em;
    padding: 4px 26px;
    border-radius: 25px;
    margin: 0 10px;
}

.map_routesListContainer {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
}

.map_routesListContainer.vertical{
    top: 0;
    bottom: auto;
    width: 360px;
    height: 100%;
}

.map_routesListContainer.vertical .m_route-short{
    margin-bottom: 20px;
}

.map_routesListContainer.vertical .carousel{
    max-height: 100%;
    overflow-y: auto;
}

.m_route-short{
    background: rgba(0,0,0,0.63);
    border-radius: 0.5em;
    backdrop-filter: blur(4px);
    user-select: none;
    padding: 0.75em 0.75em 0 0.75em;
    margin: 1em;
    border:1px solid transparent;
    max-width: 340px;
}
.m_route-short:hover{
    border-color: #6eff0073;
}
.m_route-short_head i{
    font-size: 1.4em;
}
.m_route-short .image{
    margin: 0 1em 0 0;
    width: 70px;
    height: 70px;
    border-radius: 0.5em;
    overflow: hidden;
}
.m_route-short .image img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.m_route-short_head .text{
    font-size: 0.8em;
}
.m_route-short_bottom{
    padding: 10px 0;
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.mapContainer.sidebarShown .map_routesListContainer{
    display: none;
}



.map_eventsListContainer {
    position: absolute;
    left: 0;
    top: 10px;
    height: 100px;
    padding: 0 20px;
    width: 100%;
}

.map_eventsListContainer.vertical{
    top: 0;
    bottom: auto;
    width: 360px;
    height: 100%;
    padding: 20px;
}

.map_eventsListContainer.vertical .m_event-short{
    margin-bottom: 20px;
}

.map_eventsListContainer.vertical .carousel{
    max-height: 100%;
    overflow-y: auto;
}

.m_event-short{
    background: rgba(0,0,0,0.63);
    padding: 14px;
    border-radius: 4px;
    backdrop-filter: blur(4px);
    user-select: none;
    border: 1px solid transparent;
}

.m_event-short:hover{
    border-color: #4eb2e7ad;
}


.mapContainer.sidebarShown .map_eventsListContainer{
    display: none;
}



.mapMenuMobile{
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    color: var(--white);
}

.mapMenuMobile .bottomMenu__left,
.mapMenuMobile .bottomMenu__right{
    display: flex;
    align-items: center;
    width: 108px;
    height: 39px;
    position: absolute;
    bottom: 0;
    gap: 0.5em;
    user-select: none;
}

.mapMenuMobile .bottomMenu__left:hover,
.mapMenuMobile .bottomMenu__right:hover{
    color: var(--b800)
}

.mapMenuMobile .bottomMenu__left{
    justify-content: left;
    margin-right: 16px;
    padding-left: 16px;
    right: 100%;
    background: url("/template/svg/map_menu_left.svg");
}

.mapMenuMobile .bottomMenu__right{
    justify-content: right;
    margin-left: 16px;
    padding-right: 16px;
    left: 100%;
    background: url("/template/svg/map_menu_right.svg");
}

.mapMenuMobile .buttonMenu{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8px;
    width: 50px;
    height: 50px;
    background: var(--b800);
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 28px;
}

.mapMenuMobile__items{
    position: absolute;
    bottom: calc(100% + 70px);
    right: -22px;
    flex-direction: column;
    color: var(--d540);
    align-items: flex-end;
    display: none;
}

.mapMenuMobile__items.active{
    display: flex;
}

.mapMenuMobile__item{
    opacity: 0;
    transform: translateY(-20px);
    display: flex;
    align-items: center;
    animation: fadeInDown 0.1s ease-out forwards;
}

.mapMenuMobile__item:nth-child(1) {
    animation-delay: 0.2s;
    transform: translateY(-40px);
}

.mapMenuMobile__item:nth-child(2) {
    animation-delay: 0.1s;
}

.mapMenuMobile__items.active .mapMenuMobile__item{
    animation: fadeInUp 0.1s ease-out forwards;
}

.mapMenuMobile__item-text{
    text-align: center;
    padding: 5px 14px;
    border-radius: 20px;
    background: var(--white);
}
.mapMenuMobile__item-btn{
    width: 50px;
    height: 50px;
    background: var(--white);
    display: flex;
    border-radius: 50%;
    color: var(--d540);
    align-items: center;
    justify-content: center;
    font-size: 28px;
}

.mapMenuDesktop{
    display: block;
    position: absolute;
    top: 3em;
    right: calc(100% - 50%);
    transform: translateX(-50%);
}

@keyframes fadeInUp {
    to {
        opacity: 1; /* Конечная прозрачность */
        transform: translateY(0); /* Конечное положение (без сдвига) */
    }
}

@keyframes fadeInDown {
    to {
        opacity: 0; /* Конечная прозрачность */
        transform: translateY(0); /* Конечное положение (без сдвига) */
    }
}


@media screen and (max-width: 800px){
    .mapConstructor.full{
        padding: 0;
    }
    .mapConstructor.full .map_sidebar{
        display:none !important;
    }

    .mapConstructor.full .routeType .crossMap {
        right: 10px !important;
    }

    .maps_sidebar--bottom{
        width: 90%;
        font-size: 0.8em;
        padding: 10px;
    }
    #next-btn{
        padding: 2px 26px;
    }

    .crossMap{
        top: 10px;
        right: 10px;
    }
}