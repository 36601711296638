@media screen and (min-width: 1300px){

    .full{
        width: 1300px;
    }

    .header_container{
        max-width: 1300px;
    }

}