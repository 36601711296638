*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    box-sizing: border-box
}
*:focus {outline: none}

:root{
    --gap-1: 5px;
    --gap-2: 10px;
    --gap-3: 15px;
    --gap-4: 20px;

    --base-radius: 10px;
    --base-radius-16: 16px;
    --base-radius-32: 32px;

    --base-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    --padding-1: 5px;
    --padding-2: 10px;
    --padding-3: 15px;
    --padding-4: 20px;
}

::-moz-selection {
    background-color: #21a6ff !important;
    color: #fff;
}
::selection {
    background-color: #21a6ff !important;
    color: #fff;
}

@supports (mix-blend-mode: exclusion) {
    body::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('/template/img/business-bg.jpg') no-repeat center;
        background-size: cover;
        mix-blend-mode: exclusion;
        filter: blur(100px);
        z-index: -1;
        opacity: 0.5;
    }
}

input[type=text]:focus, textarea:focus {border: 1px solid #40b8ff}

html, body {height: 100%}
body {font-size: 16px; color: var(--g700); background: var(--white); font-family: 'Roboto', sans-serif}
body.hideScroll{
    overflow: hidden;
}

.hr{
    height: 0;
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--d400);
}

[placeholder]:empty::before {
    content: attr(placeholder);
    color: #555;
}

[placeholder]:empty:focus::before {
    content: "";
}



.textEllipsis{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.textLineClamp_2{
    -webkit-line-clamp: 2;
}
.textLineClamp_3{
    -webkit-line-clamp: 3;
}
.textLineClamp_4{
    -webkit-line-clamp: 4;
}

.fixedLineClamp_2{
    line-height: 1.4;
    max-height: 2.8em;
    min-height: 2.8em;
    height: 2.8em;
}

.icon_ghost-contain{
    position: relative;
    display: inline-block;
}

.icon_ghost{
    animation: ghost .5s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.showMore_5{
    max-height: 5em;
    overflow: hidden;
}

.showMoreBtn{
    color: var(--b800);
    cursor: pointer;
}
.showMoreBtn:hover{
    color: var(--b500);
}

#container_full{display: flex; flex-direction: column; min-height: 100%; padding-top: 60px}
header, footer{flex: 0 0 auto}
.full_content{flex: 1 0 auto}

.container-fluid{
    width: 100%;
    max-width: 1100px;
    margin: auto;
}

.actlink{color: #00A0E4}

#header{position: fixed; top: 0; left: 0; width: 100%; z-index: 997; border-bottom: 3px solid var(--b800)}
#header-business{
    width: 100%;
    /*border-bottom: 1px solid var(--g700);*/
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}
#header-full-page{width: 100%; z-index: 9}

#header-full-page .header{
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
}

.text-nowrap{white-space:nowrap}

table { border-collapse: collapse; border-spacing: 0}
iframe{ border: none}
td,th { box-sizing: border-box}
img { border: 0; display: block}
a {color: #545b64; text-decoration: none}
a:hover {color: var(--b800)}
p {margin-bottom: 10px}


li.customCheckbox {
    line-height: 32px;
    font-size: 18px;
}

li.customCheckbox input{
    margin-right: 6px;
}

/*=== черная svg иконка добавленная в img.src станет белой ===*/
/* для других цветов - invert(1) sepia(1) saturate(5) hue-rotate(175deg)*/
.svgBlackToWhite{
    filter: invert(1);
}
.svgBlackToBlue{
    filter: invert(56%) sepia(60%) saturate(700%) hue-rotate(180deg) brightness(100%);
}

.svgBlackToOrange {
    filter: invert(50%) sepia(100%) saturate(600%) hue-rotate(-10deg) brightness(100%);
}

.bikelogs_category_check input[type=checkbox] {display:none}
.bikelogs_category_check input[type=checkbox]+label {position: relative; padding-left: 24px; margin-top: 2px; cursor: pointer; display:block}
.bikelogs_category_check input[type=checkbox]+label:before {
    content: '';
}
.bikelogs_category_check input[type=checkbox]+label:after {
    box-sizing: border-box;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.129);
    border-top-color: rgba(0,0,0,0.25);
    border-radius: 4px;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 0;
    margin-right: 4px;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.bikelogs_category_check input[type=checkbox]+label:hover:after { border-color: rgba(89,89,89,0.8)}
.bikelogs_category_check input[type=checkbox]:checked + label:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 4px;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg) translateY(-100%);
    top: 50%;
    left: 8px;
    z-index: 2;
}
.bikelogs_category_check input[type=checkbox]:checked + label:hover:before { background-position: 0 0 }




/*============= checkbox google android ==============*/

.checkbox-google {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    margin-right: 10px;
    position: relative;
    vertical-align: middle;
    font-size: 14px;
    user-select: none;
}
.checkbox-google .checkbox-google-switch {
    display: inline-block;
    width: 36px;
    height: 14px;
    border-radius: 20px;
    position: relative;
    top: 6px;
    vertical-align: top;
    background: #9f9f9f;
    transition: .2s;
}
.checkbox-google .checkbox-google-switch:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -3px;
    left: -1px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
    transition: .15s;
}
.checkbox-google input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.checkbox-google input[type=checkbox]:checked + .checkbox-google-switch {
    background: #9ABEF7;
}
.checkbox-google input[type=checkbox]:checked + .checkbox-google-switch:before {
    background: #1a73e8;
    transform:translateX(18px);
}

/* Hover */
.checkbox-google input[type="checkbox"]:not(:disabled) + .checkbox-google-switch {
    cursor: pointer;
    border-color: rgba(0, 0, 0, .3);
}

/* Active/Focus */
.checkbox-google input[type="checkbox"]:not(:disabled):active + .checkbox-google-switch:before,
.checkbox-google input[type="checkbox"]:not(:disabled):focus + .checkbox-google-switch:before {
    animation: checkbox-active-on 0.5s forwards linear;
}
@keyframes checkbox-active-on {
    0% {
        box-shadow: 0 0 0 0 rgba(212,212,212, 0);
    }
    99% {
        box-shadow: 0 0 0 10px rgba(212,212,212, 0.5);
    }
}

.checkbox-google input[type="checkbox"]:not(:disabled):checked:active + .checkbox-google-switch:before,
.checkbox-google input[type="checkbox"]:not(:disabled):checked:focus + .checkbox-google-switch:before {
    animation: checkbox-active-off 0.5s forwards linear;
}
@keyframes checkbox-active-off {
    0% {
        box-shadow: 0 0 0 0 rgba(154,190,247, 0);
    }
    99% {
        box-shadow: 0 0 0 10px rgba(154,190,247, 0.5);
    }
}

/* Disabled */
.checkbox-google input[type=checkbox]:disabled + .checkbox-google-switch {
    filter: grayscale(60%);
    border-color: rgba(0, 0, 0, .1);
}
.checkbox-google input[type=checkbox]:disabled + .checkbox-google-switch:before {
    background: #eee;
}

/*====================================================*/






.buttonCheckbox {
    display: block;
    margin-bottom: 5px;
    user-select: none;
}
.buttonCheckbox input[type="checkbox"] {
    display: none;
}
.buttonCheckbox label {
    display: inline-block;
    background-color: var(--d600);
    padding: 8px 20px;
    border: 1px solid var(--d600);
    cursor: pointer;
    border-radius: calc(var(--base-radius) * 3);
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
.buttonCheckbox input[type="checkbox"]:checked + label {
    border: 1px solid var(--b800)
}
.buttonCheckbox label::before{
    display: none
}
.buttonCheckbox input[type="checkbox"]:checked + label::after{
    display: none
}




input[type=text], input[type=password], input[type=number], select {
    border: 1px solid #D2D2D2;
    color: #5C5D5E;
    padding: 0 35px 0 15px;
    width: 100%;
    line-height: 40px;
    border-radius: var(--base-radius);
    font-size: 14px;
}
input[type=text].min,
input[type=password].min,
input[type=number].min,
select.min { max-width: 100px}
input,textarea,select {font-size: 14px; font-family: Arial, Helvetica, sans-serif; background: #FFF}
fieldset { border: 0}
u { text-decoration: underline }

.fs_1 { font-size: 0.5rem}
.fs_2 { font-size: 1rem}
.fs_3 { font-size: 1.5rem}
.fs_4 { font-size: 2rem}

.fw-normal {font-weight: normal}
.fw-medium {font-weight: 500}
.fw-bold {font-weight: 700}

.fs-10 { font-size: 10px !important}
.fs-12 { font-size: 12px !important}
.fs-14 { font-size: 14px !important}
.fs-16 { font-size: 16px !important}
.fs-18 { font-size: 18px !important}
.fs-20 { font-size: 20px !important}
.fs-24 { font-size: 24px !important}
.fs-28 { font-size: 28px !important}

.lh_1 { line-height: 0.5em}
.lh_2 { line-height: 1em}
.lh_3 { line-height: 1.5em}
.lh_4 { line-height: 2em}

.gap_1 { gap: 0.5rem}
.gap_2 { gap: 1rem}
.gap_3 { gap: 1.5rem}
.gap_4 { gap: 2rem}
.gap_6 { gap: 3rem}
.gap_8 { gap: 4rem}

.gap_03_em { gap: 0.3em}
.gap_05_em { gap: 0.5em}

.opacity { opacity: 1 }
.opacity_8 { opacity: .8 }
.opacity_6 { opacity: .6 }
.opacity_4 { opacity: .4 }
.opacity_2 { opacity: .2 }

.break-word{
    word-break: break-word;
}


.text-decoration-underline{
    text-decoration: underline;
}
.text-uppercase{
    text-transform: uppercase;
}

.bg-d600{
    background-color: var(--d600) !important;
}

.bg-d700{
    background-color: var(--d700) !important;
}


.bg-d800{
    background-color: var(--d800) !important;
}

.bg-d900{
    background-color: var(--d900) !important;
}

.color-white{
    color: var(--white) !important;
}

.color-r1000{
    color: var(--r1000) !important;
}

.color-b800{
    color: var(--b800) !important;
}

.color-d540{
    color: var(--d540) !important;
}

.color-d400 {
    color: var(--d400) !important;
}

.color-g500 {
    color: var(--g500) !important;
}

.color-b800 {
    color: var(--b800) !important;
}

.color-green800 {
    color: var(--green800) !important;
}

.shadow{
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.base_shadow{
    box-shadow: 0 5px 23px rgba(0,0,0,.11);
}

.overflowVisible{
    overflow: visible !important;
}

.overflowHidden{
    overflow: hidden !important;
}

:focus::-webkit-input-placeholder,
:focus::-moz-placeholder,
:focus:-moz-placeholder,
:focus:-ms-input-placeholder{color:transparent}

.cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.position_relative{
    position: relative !important;
    top: auto !important;
    left: auto !important;
}

.absolute-cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.position_absolute_left_center{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}



.img_absolute { width: auto !important; height: 100% !important; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%)}
.img_absolute.img_w { height: auto !important; width: 100% !important}
.img_absolute-h {height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%)}

.ecl {white-space:nowrap; overflow:hidden; text-overflow:ellipsis}

.container_blur{filter:blur(2px)}

.stop {position: relative; z-index: 101}
.pagebox {padding: 20px 0}
.left_menu {display: block; float: left; max-width: 240px}
.pagebox__inner { display: flex; align-items: stretch}
.pagebox__main { width: 100%; overflow: hidden; padding: 0 20px}
.pagebox__main > h1{ margin: 20px;}
.pagebox__main > h1.topH1{ margin: 0 20px 20px 20px}
.pagebox__head { padding: 14px; position: relative}
.sp_b { border-bottom: 1px solid #ecf0f2; }
.pagebox__title { position: relative; padding-right: 50px }
.pagebox__title h1 { font-weight: normal }
.pagebox__favorite { width: 24px; height: 24px; position: absolute; font-size: 24px; right: 0; top: 0; box-sizing: content-box; color: #e0e5e8 }
.pagebox__favorite.active { color: #E82C3D }
.pagebox__favorite:hover { opacity: 0.8 }
.pagebox__info { width: 100%; font-size: 13px; color: #6b6b6b; padding-top: 10px }
.pagebox__info li { list-style: none; display: inline-block; padding-right: 20px }
.pagebox__icon { float: left; margin: 7px 3px 0 0; width: 16px; opacity: 0.9; }
.pagebox__image { position: relative; width: 100%; overflow: hidden}
.fullstory_image img { max-width: 100% }
.pagebox__image-overlay { position: absolute; bottom: 0; left: 0; padding: 20px; background: rgba(0,0,0,0.5); width: 100%; z-index: 1 }
.pagebox__image-overlay div { margin-right: 10px; display: inline-block; }
.box_share { line-height: 40px; color: #eaeaea; font-size: 18px }
.box_share * { vertical-align: top; }
.box_share a { display: inline-block; width: 40px; height: 40px; margin-left: 15px; background: url("/template/img/sprites.png") }
.box_share a.gl_40 { background-position: -504px -0px}
.box_share a.fb_40 { background-position: -504px -96px}
.box_share a.tw_40 { background-position: -504px -48px}
.box_share a:hover { opacity: 0.8 }
.box_share a:active { margin-top: 1px }
.share_center { text-align: center }
.share_center a { margin: 0 7px }
.pagebox__descr { padding: 10px 20px 20px }
.pagebox__descr-btn { line-height: 50px; padding: 0 10px; background: #fff; border-bottom: 1px solid #f1f1f1}

.btn_bikelog { float: right; background: #37b8eb; height: 30px; line-height: 30px; border-radius: 2px; overflow: hidden; margin-top: 10px; }
.btn_bikelog a { display: inline-block; color: #FFF; vertical-align: top; padding: 0 17px }
.btn_bikelog a:hover { background: rgba(255,255,255,0.16) }
.btn_bikelog a:active { background: #348dff }
.btn_bikelog a.add_bikelog { border-right: 1px solid #FFF; padding: 0 12px; float: left; }
.pagebox__descr-title { line-height: 40px; border-bottom: 3px solid #ecf0f2; margin-bottom:15px}
.pagebox__descr-title span { border-bottom: 3px solid #1b98b4; bottom: -3px; position: relative; display: inline-block; font-size: 17px }
.pagebox__descr-text {line-height: 1.6em; word-break: break-all}
.pagebox__bike { max-width: 720px }
.pagebox__left { width: 320px; flex-shrink: 0; margin-right: 20px }
.pagebox__right { width: 320px; flex-shrink: 0; margin-left: 20px }
.block__map { height: 300px; overflow: hidden }
.block__params { padding: 3px 20px }
.block__item { padding: 15px 0; display: table }
.item__icon,
.item__text { display: table-cell }
.item__icon { width: 40px; padding-right: 10px; vertical-align: middle; font-size: 24px; color: #565656 }
.box_wht,
.box_wht-mb-20 { background: #FFF; margin-bottom: 20px; border-radius: var(--base-radius); box-shadow: 0 8px 24px rgba(13,13,18,0.14)}
.box_wht__header { line-height: 30px; padding: 15px 20px 0 20px; font-weight: 500; position: relative; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.box_wht__header-min {
    font-size: 14px; font-weight: 500; padding: 0 10px; height: 38px; line-height: 38px; position: relative;
    display: flex;
    flex-direction: row;
}
.box_wht__header-min a { color: #636363 }
.box_wht {border-radius: 16px; overflow: hidden}
.header-min_title {
    flex: 1 1 auto;
}
.header-min_see_all {
    position: absolute;
    right: 10px;
    font-weight: normal;
}
.box_wht__header-min-i {
    position: absolute;
    right: 10px;
    height: 16px;
    width: 16px;
    font-size: 16px;
    color: #6b6b6b;
    box-sizing: content-box;
    cursor: pointer;
    opacity: 0.7;
    top: 10px;
}
.box_wht__header-min-i:hover { opacity: 1 }
.box_wht__content { padding: 10px; position: relative }
.box_wht__content-min { padding: 0 0 10px 0}
.box_wht__bottom { display: block; line-height: 40px; padding: 10px 40px 0 0; clear: both; position: relative; font-size: 15px; text-align: center }

.box_wht.empty_content {
    width:100%;
    padding: 50px 0;
}

.text-center {
    text-align: center;
}

*[class^=box_wht] .box_wht {
    box-shadow: none;
}

.box_modify {text-align: right; padding: 0 15px 10px 0; font-size: 0.8em}
.content {width: 100%; padding: 20px 0; box-sizing: border-box; display: flex}

.content__l, .block__l {width: 210px; flex-shrink: 0;}
.content__m {width: 100%; flex-shrink: 2}
.content__r {width: 260px; flex-shrink:0}
.content__r .box_wht:last-child,
.content__l .box_wht:last-child{ margin-bottom: 0}
.content__l-stick{
    position: -webkit-sticky; position: sticky; top: 70px;
}

.fav_on {color: #0dabdc}
.show_all {line-height: 40px; text-align: center; border-radius: 0 0 10px 10px}
.show_all:hover { background: #f5f5f5}
.show_all a { display: block; color: #3D3D3D}

.box_wht__bar {line-height: 40px; height: 40px; padding: 5px 15px; white-space: nowrap;overflow: hidden; text-overflow: ellipsis;}
.box_wht__bar h1 {font-size: 18px;color: #444; line-height: 30px; height: 30px}

.background_small_widget_statistics{
    background: rgba(71, 84, 99, 0.21) !important;
}

.no_shadow {
    box-shadow: none !important;
}

.short_count_bikelogs {
    width: 28px;
    height: 28px;
    line-height: 26px;
    font-size: 12px;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 11px;
    color: #000;
    border-radius: 50%;
    z-index: 9;
    cursor: default;
    border-style: solid;
    border-color: #CCC;
    border-width: 0 0 2px 2px
}
.scb_1 { border-color: #96cbe8}
.scb_2 { border-color: #6f9baf}
.scb_3 { border-color: #4490ea}
.scb_4 { border-color: #6b44ea}
.scb_5 { border-color: #b844ea}

/*=== Общие положения трансформации ===*/
.ev_info,
.zoom,
.foto_edit_box,
.progress .load_line,
.short_image img,
.prev_list,
.short_image,
.img_absolute-h,
.img_absolute,
.moz__item,
.slidemenu,
.video_btn,
.short_overlay,
.flow, .transition { -webkit-transition: all .2s ease-in-out; -moz-transition: all .2s ease-in-out; -o-transition: all .2s ease-in-out; transition: all .2s ease-in-out}
.login_btn,
.use_app,
.notify_wrap,
.prev_list li img,
.listmenu__arrow,
.bike_prev__img,
.switch label:before,
.action_menu__dropdown,
.dropdown_menu,
.liked_prev_users > a { -webkit-transition: all .1s ease-in-out; -moz-transition: all .1s ease-in-out; -o-transition: all .1s ease-in-out; transition: all .1s ease-in-out }
sup { line-height: 1em }
.select_all_checkbox { text-align: right; width: 100%; display: block; cursor: pointer }
.select_all_checkbox:hover { text-decoration: underline; }
.transitionTransform { -webkit-transition: transform .2s ease-in-out; -moz-transition: transform .2s ease-in-out; -o-transition: transform .2s ease-in-out; transition: transform .2s ease-in-out}
/*=========================================*/


.charCounter {
    position: absolute;
    right: 0.75em;
    bottom: 0.5em;
    font-size: 0.8em;
    color: var(--d540);
}


/*=========================================*/


.overscroll-behavior-contain{
    overscroll-behavior: contain;
}

.custom-scrollbar::-webkit-scrollbar,
.chosen-results::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.custom-scrollbar::-webkit-scrollbar-track,
.chosen-results::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.chosen-results::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 3px;
}

/*==== Админские штучки ====*/

/*===== Реклама приложения =============*/
.app_ad {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: #ececec;
}
.app_add-text{
    flex: 1 1 auto;
}
.app_add-image{
    flex: 0 0 auto;
    width: 25%;
    margin-left: 40px
}
.app_ad .btns_bl {
    text-align: center;
    border-top: 1px solid #e2e2e2;
    padding: 15px;
}
.app_ad .btns_bl > a {
    display: inline-block;
    background: #FFF;
    padding: 10px 30px;
    color: #2f5062;
    border-radius: 50px;
    margin: 0 10px;
}

.app_ad p.h4 {
    font-size: 1.3em;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}



.app_ad img{max-width: 100%; border-radius: 5px}
.banner_app_sqa {display: none}
/*======================================*/

/*===== Поделиться =============*/
.shared_first_open__text { font-size: 1.5em; text-align: center; padding: 0 0 10px 0 }
.shared_first_open__text-m { font-size: 0.7em }
/*===== доп меню по клику======*/

.dropdown {
    position: relative;
}

.dropdown.dots_menu {
    position: absolute;
    top: 20px;
    right: 20px;
}
.dots_menu > button {
    background: none;
    border: none;
    color: var(--d400);
    font-size: 20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}
.dots_menu > button:hover{
    background: var(--d800);
    color: var(--g500);
}
.dropdown_menu {
    font-size: 16px;
    position: absolute;
    z-index: 500;
    top: 130%;
    right: 0;
    line-height: 30px;
    background: var(--white);
    border-radius: var(--base-radius);
    font-weight: normal;
    min-width: 220px;
    width: auto;
    overflow: hidden;
    padding: 10px 0;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 8px 24px rgba(13,13,18,0.14);;
}
.dropdown_menu[data-position=left]{
    right: auto;
    left: 0;
}
.dropdown.active > .dropdown_menu{
    visibility: visible;
    opacity: 1;
    top: 100%;
}
.dropdown > .dropdown_button .icon-chevron-down{
    margin-left: 5px;
}
.dropdown.active > .dropdown_button .icon-chevron-down {
    transform: rotate(
            180deg
    );
}
.dropdown_menu--item {
    padding: 3px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px
}
.dropdown_menu--item > .descr{
    display: block;
    font-size: 0.8em;
    line-height: 0.8em;
    opacity: 0.6;
    padding-bottom: 5px;
}
.dropdown_menu--spacer{
    display: block;
    height: 1px;
    background: var(--g100);
    width: 90%;
    margin: 10px auto;
}
.dropdown_menu--item:hover{
    background: var(--g100);
}
/*==================================*/

/*====== формы ====*/
.form-group {background: #f7f7f7; padding: 5px; margin-bottom: 10px}
.form-group .form-item {padding:0 10px}
.form-group h2 {margin: 0 0 10px 0; text-align:center}

form button.btn_send {
    background: var(--b800);
    border-radius: 20px;
    border: 0;
    color: var(--white);
    font-size: 16px;
    padding: 8px 26px;
    position: relative;
}
form button.btn_send.success{
    background: var(--green400);
}
/*=====================*/

/*====== Подсветить временно элемент ================*/
.hlight { -webkit-animation: hlight 3s; animation: hlight 3s }
@keyframes hlight {
0% {background: #FFFBA8}
100% {background: transparent}
}
@-webkit-keyframes hlight {
0% {background: #FFFBA8}
100% {background: transparent}
}

@keyframes hlight_dark {
    0% {box-shadow: 0 0 16px 0 #fffba8 inset}
    100% {box-shadow: 0 0 0 0 transparent inset}
}
@-webkit-keyframes hlight_dark {
    0% {box-shadow: 0 0 16px 0 #fffba8 inset}
    100% {box-shadow: 0 0 0 0 transparent inset}
}
/*===================================================*/

.spoiler_overline { position: absolute; bottom: 0; width: 100%; height: 30px; line-height: 30px; text-align: center; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAMAAADaS4T1AAAAS1BMVEX///////////////////////////////////////////////////////////////////////////////////////////////////+yVYcAAAAAGXRSTlPx1url38vEvLSro5qRiH91bGNaUUhAODAoAUIFKQAAAC5JREFUCNc9wcUBACEAACCuu0P3n1Rfgij4fV6P2+V02G1Wi9lkNOh1qrzVqBUJM1sBLiS4jM8AAAAASUVORK5CYII='); display: none }
/*===== Вывод юзера ===============*/
.user_rides_block { padding: 5px 0 0 0; font-size: 12px }
.user_rides_block > div > a { font-weight: bold }
.user_social_links a,
.user_social_links span { color: #415461; font-size: 24px; margin-right: 5px; vertical-align: middle }
.user_social_links span { color: #aaa }
.user_social_links a:hover { color: #00BBFF }

.rating {text-align: center;
    position: absolute;
    z-index: 50;
    bottom: 10px;
    left: 50%;
    font-size: 13px;
    background: rgba(55, 200, 235, 0.72);
    color: #FFF;
    padding: 3px 10px;
    border-radius: 3px;
    transform: translate(-50%, 0)
}

.rating.rbike { bottom: auto; top: 10px}

.subs_block {border-bottom: 1px solid #ecf0f2;margin-bottom: 10px}
.subs_block:last-child { border: 0; margin-bottom: 0}
.subs_title {font-size: 18px;font-weight: bold;margin-bottom: 20px}
.subss { flex-wrap:wrap; width: 100%}
.subs {width: 50%;height: 70px;margin-bottom: 10px}
.subs__image {width: 70px;height: 70px;overflow: hidden;float: left;margin-right: 12px;border-radius:35px}
.subs__image img { max-width: 100%}
.subs__content .subs_name {display: block;font-weight: bold;line-height: 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis}

.subs__content .subs_owner {font-size: 13px;line-height: 20px}
	.subs__content .subs_owner i {font-size: 0.8em}
.subs__content .subs_btns { line-height: 30px}

.subs .i_subscribe{ padding:0; font-size:12px}
/*==================================*/


/*===== NOT FOUND ================*/
.not_found > h1 {
    text-align: center;
    padding: 30px 20px;
    font-size: 24px;
    color: #272727;
}
.not_found-c{
    display: flex;
    flex-direction: column;
    padding: 0 30px;
}
.not_found-c_item {
    font-size: 18px;
    line-height: 1.6em;
}
.not_found-cc{
    display: flex;
    flex-direction: row;
    padding: 60px 0;
}
.not_found-cc_pages{
    line-height: 36px;
    font-size: 20px;
    padding: 0 30px;
    flex: 0 0 auto;
}
.not_found-cc_img{
    padding: 0 30px;
    flex: 1 1 auto;
}
.not_found-cc_img>img{
    margin: auto;
}
/*================================*/

/*===== доп меню с наведением======*/
.action_menu { width: 30px; height: 30px; display: block; background: url("/template/img/sprites.png") -225px -58px; box-sizing: content-box; border-radius: 3px; position: absolute; cursor: pointer; right: 10px; top: 50%; margin-top: -15px; }
.action_menu__dropdown { position: absolute; top: 45px; right: -10px; visibility: hidden; opacity: 0; display: block; line-height: 30px; border: 1px solid #dcdcdc; background: #FFF; border-radius: 3px; padding: 5px 0; z-index: 99; min-width: 100px }
.action_menu__dropdown li { padding: 0 20px; text-align: center; font-size: 0.9em; cursor: pointer; white-space: nowrap; }
.action_menu__dropdown li a { color: #636363 }
.action_menu__dropdown li:hover { background: #f1f2f3 }
.action_menu:hover > .action_menu__dropdown { top: 25px; visibility: visible; opacity: 1 }
.action_menu__dropdown:before,
.action_menu__dropdown:after { width: 0; height: 0; content: ''; display: block; position: absolute; right: 14px; top: -14px;
    border: 7px solid transparent;
    border-bottom-color: #dcdcdc;
}
.action_menu__dropdown:after,
.dropdown_menu:after { top: -13px; border-color: transparent transparent #FFF transparent }
/*=================================*/

.likes {display: inline-block; padding: 0 10px}
	.likes > span {display: inline-block;vertical-align: middle}
    .likes > .lk {font-size: 22px; vertical-align: text-bottom;}



.lk, .dlk {cursor:pointer}
.lk:hover, .dlk:hover{ color: #6e9fca}

.lk:active{color: #53de00}
.dlk:active{ color: #b31b23}

.likes.a_lk .lk, .likes.a_dlk .dlk, .likes.a_lk .count_lk{color: var(--b800)}

.count_lk {padding: 0 10px; cursor: pointer}

.more_dots {background: rgba(0,0,0,0.5);position: absolute; width: 100%; height: 100%; box-shadow: 0 0 0 1px rgba(255,255,255,0.5) inset; border-radius: 50%;font-size: 1.4em;}
.more_dots > i {position: absolute;color: #FFF;top: 50%;left: 50%;transform: translate(-50%, -50%)}

.dialog_liked {display: flex; flex-wrap: wrap}

.dialog_liked > a {width: 25%; display: inline-block; font-size: 13px; line-height: 1.2em; margin-bottom: 15px}

.dialog_liked > a > img {width: 70%; max-width: 80px; border-radius: 50%; margin: auto}

.comment_content .likes {font-size:14px; padding: 0 10px 0 0}
.comment_content .likes .count_lk { padding: 0 10px}


@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.pulse {
    -webkit-animation: pulse .3s; animation: pulse .3s;
}

.count_subscribers { margin-left: 5px}

.height_inherit { width: 100% !important; position: relative !important; top: inherit !important; left: inherit !important; transform: none !important; -webkit-transform: none !important; height: initial !important; }
.right { float: right !important; }
ul li { list-style: none}

h1,h2,h3{ font-weight: bold; color: #000; line-height: 1.3em}
h1 {font-size: 20px}
h2 {font-size: 18px}
h3 {font-size: 16px}

#loading-layer { display: none; font-size: 13px; background: rgba(0,0,0,0.6); border-radius: 3px; padding: 14px; text-align: center; color: #C9C9C9; border-bottom: 3px solid #353535; position: absolute; overflow: hidden; min-width: 250px; min-height: 50px }
[id^=tab_] { display: none }
.tab_active { display: block !important; opacity: 1; padding: 10px 0}
#tabs > ul { text-align: center; box-shadow: 0 -1px 0 #efefef inset }
.search_result #tabs > ul { border-bottom: none }
#tabs > ul li.tabs { display: inline-block; padding: 5px 10px; line-height: 36px; cursor: pointer; }
.tabs_active { border-bottom: 3px solid #72b1e8; color: #72b1e8 }
.filters_item { padding: 0 0 10px 0 }
.filters_item-label { font-size: 11px; padding: 0 0 5px 0; font-weight: bold }

.tabs_baseTheme .tab-nav{
    border-bottom: 1px solid var(--d600);
}

.tab-nav a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px 5px 0 0;
    transition: 0.3s;
    color: var(--g500);
}

.tabs_baseTheme .tab-nav a{
    padding: 10px 20px;
}

.tab-nav a.active {
    color: white;
}

.tabs_baseTheme .tab-nav a.active {
    color: white;
    box-shadow: 0 1px 0 var(--b800);
}

.tab-content {
    display: none;
    border-top: none;
}

.tab-content.active {
    display: block;
}

/*==== Админские штучки =====*/

.recept_edit { width: 24px; height: 24px; display: block; position: absolute; bottom: 10px; right: 10px; padding: 5px; border-radius: 3px; background: rgba(0,0,0,0.2); z-index: 20; color: #FFF; opacity: 0; }
.recept_edit > i { width: 24px; height: 24px; display: block; background: url("/template/img/sprites.png") 0 -72px; opacity: 0.7 }
.recept_edit:hover > i { opacity: 1; }
.short .prev:hover > .recept_edit,
.image_full:hover > .recept_edit { opacity: 1 }
/*=====================================*/


/*==== Основные элементы страниц ===*/
.complain { opacity: 0.8; text-decoration: underline }
.complain:hover { opacity: 1; cursor: pointer }
.clr { clear: both }
.hide { display: none !important }
.row { width: 100%}
[class^=col_] {min-height: 1px; box-sizing: border-box; float: left}
.col_1 { width: 8.33333% }
.col_2 { width: 16.66666% }
.col_3 { width: 24.99999% }
.col_4 { width: 33.22222% }
.col_5 { width: 41.55555% }
.col_6 { width: 49.88888% }
.col_7 { width: 58.11111% }
.col_8 { width: 66.33333% }
.col_9 { width: 74.66666% }
.col_10 { width: 82.99999% }
.col_11 { width: 91.22222% }
.col_12 { width: 100% }
.mtb_10 { margin: 10px 0 }
.mt_20 { margin-top: 20px}
.p_5 { padding: 5px}
.p_10 { padding: 10px}
.p_15 { padding: 15px}
.p_20 { padding: 20px}
.p_30 { padding: 30px}
.pl_10 { padding-left: 10px}
.pt_10 { padding-top: 10px}
.pt_20 { padding-top: 20px}

.p-1{padding: 0.5em}
.p-2{padding: 1em}
.p-3{padding: 1.5em}
.p-4{padding: 2em}

.py-1{padding-top: 0.5em; padding-bottom: 0.5em}
.py-2{padding-top: 1em; padding-bottom: 1em}
.py-3{padding-top: 1.5em; padding-bottom: 1.5em}
.py-4{padding-top: 2em; padding-bottom: 2em}

.px-1{padding-left: 0.5em; padding-right: 0.5em}
.px-2{padding-left: 1em; padding-right: 1em}
.px-3{padding-left: 1.5em; padding-right: 1.5em}
.px-4{padding-left: 2em; padding-right: 2em}

.px-10{
    padding-left: 10px;
    padding-right: 10px;
}
.px-20{
    padding-left: 20px;
    padding-right: 20px;
}

.py-10{
    padding-top: 10px;
    padding-bottom: 10px;
}

.py-20{
    padding-top: 10px;
    padding-bottom: 10px;
}

.mt_5 { margin-top: 5px}
.mt_10 { margin-top: 10px}
.mb_10 { margin-bottom: 10px}
.mb_40 { margin-bottom: 40px}
.mb_30 { margin-bottom: 30px}
.mb_20 { margin-bottom: 20px}
.ml_10 { margin-left: 10px}
.ml_20 { margin-left: 20px}
.ml_40 { margin-left: 40px}
.mr_10 { margin-right: 10px}
.mr_20 { margin-right: 20px}
.pd_20 { padding: 20px}
.m_10 { margin: 10px}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.my-20{
    margin-top: 20px;
    margin-bottom: 20px;
}
.my-40{
    margin-top: 40px;
    margin-bottom: 40px;
}

.wrp { padding: 20px; background: rgba(255,255,255,0.1); border-radius: 3px; margin: 20px 0}
.conteiner { display: flex; flex-wrap: nowrap; width: 100%; min-height: 900px}
.side_left,
.side_right { width: 290px; }
.side_left img { width: 100%; }
.side_left.ev,
.side_right.ev { width: 280px; padding: 10px; background-color: #273e4e; border-radius: 7px; background-size: 90%; flex-shrink: 0}
.side_left .title { color: #FFF; line-height: 30px; padding: 0 0 10px 0; text-align: center; text-transform: uppercase; position: relative; overflow: hidden }
.middle { flex: 1 0 auto; background: #2a4354; color: #FFF; box-sizing: border-box; padding: 23px 12px; }
.side_title { padding: 10px; font-size: 20px; text-transform: uppercase; text-align: center; border-bottom: 3px solid #2a4354; }
.border-red { border: 1px solid #F00 }
/*=========================================*/

.empty_block {
    width: 80%;
    text-align: center;
    padding: 20px;
    margin: 0 auto 60px auto
}

.empty_block__header {
    font-size: 2em;
    padding: 0 0 20px 0;
    display: block;
}
.empty_block__btn { padding-top: 16px; margin-top: 16px}
.empty_block__btn i { font-size: 0.8em }
.empty_block__btn .btn {
    font-size: 16px;
    padding: 5px 30px;
}
.empty_block__image {
    padding: 0 0 30px 0;
    margin: auto;
    box-sizing: content-box;
}
.empty_block__image > img{
    margin: auto;
    max-height: 180px;
}

.empty_block__image > svg{
    margin: auto;
    max-height: 140px;
    fill: var(--g700);
}

.empty_block__text{
    font-size: 18px;
}
.pointer { cursor: pointer }

#comments{
    clear: both;
}

.comments_empty .empty_block__image {
    height: auto;
}

.comments_auth {
    padding: 40px;
    color: #232323;
    border: 1px dashed #545454;
    border-radius: var(--base-radius);
    font-size: 1.2em;
}
.comments_auth a {
    color: #00a1ff;
    text-decoration: underline;
}

/*мелкие блоки с юзерами*/
.friends_list_min__friend {padding-bottom: 7px}
.friends_list_min__friend-foto { width: 20px; height: 20px; position: relative; float: left; overflow: hidden; border-radius: 50% }
.friends_list_min__friend-foto img { height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); border-radius: 50%}
.friends_list_min__friend-inner {padding: 8px 14px}
.friends_list_min__friend-inner:hover{background: #f3f3f3}
.friends_list_min__friend-name { line-height: 20px; overflow: hidden; height: 20px; font-size: 14px; text-overflow: ellipsis; white-space: nowrap}
.friends_send_message {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    color: #66777f;
}
/*=====================*/

#whait_box { display: block; height: 100px; text-align: center; position: relative }
.anim { position: absolute; top: 50%; left: 50%; width: 32px; height: 8px; margin-left: -16px; margin-top: -4px; display: block }
/* ==== Кнопки === */
.group_btn {
    color: #FFF !important; text-decoration: none; height: 50px; line-height: 50px; padding: 0 20px 0 55px; font-weight: bold;
    background: #3a5795 url("/template/img/fb_group.png") no-repeat;
    display: inline-block; box-sizing: border-box; text-align: left; border-radius: 4px; }
.btn { line-height: 26px; position: relative; border-radius: 6px; border: 0; background: #3b9fe6; text-align: center; color: #FFF; display: inline-block; padding: 0 17px; cursor: default; font-size: 12px; user-select: none}
.btn.big { width: 100% !important}
.btn.big_h { width: 100% !important; height: 34px; line-height: 34px}
.btn.big_h > i {font-size: 15px;vertical-align: sub}
.btn.min { line-height: 24px; height: 24px; font-size: 13px }
.btn.large { line-height: 40px; height: 40px; font-size: 15px; padding: 0 36px}
.create_book_btn { line-height: 22px !important; padding: 0 7px !important; float: right; margin-left: 5px; background: #3899C5 }
.btn:hover { opacity: 1; background: #52b7ff }
a.btn {color: #FFF !important;}
a.btn:hover { color: #FFF }
.btn:active { box-shadow: 2px 2px 2px rgba(0,0,0,0.3) inset }
sup.ob {color: #F00} /*обязательные поля*/
.red { background: #D42626 !important }
.yel { background: #E45332 !important}
.green { background: #2ECC71 !important; }
.gray { background: #466880 !important; }
.dark { background: var(--d600) !important; }
.blue { border: 0; text-decoration: none; color: #FFF }
.btn.blue:hover { opacity: 0.9; color: #FFF }
.white_blue { background: #37b8eb; }
.white_blue:hover { background: #36c76b; color: #FFF }
.btn.abs { position: absolute; top: 50%; right: 10px; margin-top: -12px }
.black { background: rgba(0,0,0,0.9) !important }
.red-text {color: #F00}
.btn.hover-r1000:hover{
    background: var(--r1000) !important;
}

.radius_base{
    border-radius: var(--base-radius);
    overflow: hidden;
}
.radius_base_16{
    border-radius: var(--base-radius-16);
    overflow: hidden;
}
.radius_base_32{
    border-radius: var(--base-radius-32);
    overflow: hidden;
}

.btn-empty{
    background: transparent;
    border: none;
}

.btn-empty{
    background: transparent;
    border: none;
}

.noselect{
    user-select: none;
}

.btn-header-question{
    color: var(--white)
}


.add_friends,
.send_message {
    background: rgba(255, 255, 255, 0);
    cursor: default;
    border: 1px solid #ffffffe8;
    white-space: nowrap;
}
.add_friends:hover,
.send_message:hover { color: #FFF; background: #36c76b; border: 1px solid #36c76b;}
.add_friends.gray {border: 1px solid #466880} /*костыль*/

.show_details { max-width: 200px; background: #3bafe6; cursor: pointer; margin: 20px auto; }
.transp { background: none; border: 0 }
.btn_q_comment { cursor: pointer; }
.btn_q_comment:hover { color: #000; text-decoration: underline }
.btn.del { height: 24px; line-height: 24px; min-width: 0; padding: 0 12px; background: #dedede; color: #6b6b6b; font-weight: normal; font-size: 13px }
.btn.del:hover { background: #969696; color: #fff }
/* ============================== */

/*========*/
.epmap { width: 100%; min-height: 60px; font-size: 11px; color: #212121; padding: 10px 10px 7px 50px; border-bottom: 1px solid #ececec; position: relative }
.epmap:last-child { border: 0 }
.epmap > li:first-child { margin-bottom: 4px; font-size: 12px; }
.epmap > li:first-child a { font-weight: bold; color: #444; text-transform: uppercase }
.epmap > li:first-child a:hover { color: #21B4F1 }
/*=======*/

/*========= Дата над картинкой =====*/
.tdate { position: absolute; top: 0; right: 0; z-index: 1; padding: 7px 15px; color: #FFF; font-size: 13px; text-align: center; text-shadow: 0 0 6px rgb(0, 0, 0); border-radius: 0 0 0 20px }
.tdate__day { font-size: 18px; font-weight: bold; display: block }
.epmap .tdate { left: 0; top: 9px; right: auto; padding: 0; width: 40px; height: 40px; background: #5aa4ff; border-radius: 50px; color: #FFF; font-size: 11px; font-weight: bold }
.epmap .tdate__day { padding-top: 3px }
/*==================================*/


/*========= Видео ========*/
.video_btn { display: block; width: 60px; height: 60px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.5; z-index: 3}
.short_video_duration { position: absolute; color: #FFF; bottom: 5px; right: 10px; z-index: 3; background: rgba(0, 0, 0, 0.57);padding: 2px 5px;font-size: 13px;border-radius: 2px}
.short_videos { display: flex; flex-wrap: wrap; justify-content: flex-start; }
.short_videos li { width: 25%; padding: 5px; display: inline-block; box-sizing: border-box; }
.short_video { box-sizing: border-box; margin: 0 0 10px 0; border-radius: 3px; overflow: hidden; position: relative; border-bottom: 1px solid #e0e0e0; background: #FFF; }
.short_video .short_image { width: 100%; position: relative; overflow: hidden; }
.short_video .short_image img { width: 100%; }
.short_video_title { text-align: left; line-height: 20px; padding: 10px; box-sizing: border-box; }
.short_video_title span { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; color: #5a6f79; font-size: 14px; }
.short_video__name { color: #283f4f !important; font-weight: bold; }
.video_btn { display: block; width: 60px; height: 60px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.5; }
.short_video:hover .video_btn { opacity: 0.8 }
.short_video_duration { position: absolute; color: #FFF; bottom: 5px; right: 10px; text-shadow: 1px 1px rgba(0, 0, 0, 0.5); }

.video_relative_block { width: 450px}
.video_inner { display:block; border-right: 470px solid transparent; position:relative; width: 100%}
.video_inner .pagebox__main { width: 100%;}
.video_relative_block { width: 450px; margin-right: -470px; right: 0; top: 0; position:absolute}

/*
.short_videos { display: flex; flex-wrap: wrap; justify-content: flex-start}
.short_videos li { width: 20%; padding: 5px; display: inline-block; box-sizing: border-box; }
.short_video { box-sizing: border-box; margin: 0 0 10px 0; border-radius: 3px; overflow: hidden; position: relative; border-bottom: 1px solid #e0e0e0; background: #FFF; }
.short_video .short_image { width: 100%; position: relative; overflow: hidden}
.short_video .short_image .img {width: 100%}
.short_video_title { text-align: left;padding: 7px; box-sizing: border-box}
.short_video_title span {display: block;color: #5a6f79; font-size: 13px;line-height: 1.2em;height: 2.4em; overflow: hidden}
.short_video__name { color: #283f4f !important; font-weight: bold; }


.short_overlay { position:absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; background: #000; opacity: 0}

.short_video:hover .video_btn { opacity: 0.8 }
.short_video:hover .img { opacity: 0.8}
.short_video:hover .short_overlay { opacity: 0.5}

.short_video_data { font-size: 11px; color: #767676; padding-top: 5px;}

.rel_video {width: 100%;clear: both; height: 116px; padding: 10px 0; border-bottom: 1px solid #eaeaea}
.rel_video:first-child {padding: 0 0 10px 0; height: 106px}
.rel_video:last-child { border: 0; padding: 10px 0 0 0; height: 106px}

.rel_video_image {width: 170px; float:left; position: relative;}
.rel_video_image .img { width: 100%}

.rel_video_duration {position: absolute; color: #FFF; bottom: 5px; right: 10px; z-index: 3; background: rgba(0, 0, 0, 0.57);padding: 2px 5px;font-size: 13px;border-radius: 2px}

.rel_video_title {float: left;width: 240px; margin: 0 0 0 5px}
.rel_video__name { font-size: 14px; font-weight:bold; color: #404040}
.rel_video_data {font-size: 13px; color: #767676;margin-top: 10px}
.rel_video_data > i { font-size: 0.8em}

@media screen and (max-width: 900px) {
		.short_videos li { width: 25%}
}

@media screen and (max-width: 640px) {
		.short_videos li { width: 50%}
}

@media screen and (max-width: 430px) {
		.short_videos li { width: 100%}
}

.video_title-full {font-size: 20px; padding: 15px; color: #444}

.video_views-full {padding: 7px; font-size: 20px}
.video_views-full i { font-size: 0.8em}

.video_likes-full {height: 30px; padding: 5px; color: #444}

.video_data-full {font-size: 13px; padding: 5px 10px; line-height: 30px; color: #8c8c8c; border-top: 1px solid #f0f0f0; clear: both}
.video_data-full li { display:inline-block}
.video_data-full i {font-size: 0.9em}
.video_data-full .likes { color:#444; font-size: 15px}

.video_author {height: 50px;line-height: 25px;padding: 0 10px 10px 15px}
.video_author-img { display: inline-block}
.video_author-img > img{ border-radius: 50%; width:40px}
.video_authot-data { display:inline-block; padding: 7px; vertical-align:top; font-size: 13px}
.video_authot-data > a {font-weight: bold}

.video_sorts_block {line-height: 50px}
.video_sorts_block a {display: inline-block;padding: 0 15px;color: #444}
.video_sorts_block a.active {box-shadow: 0 -5px 0 #1ac3e1 inset;}
.video_sorts_block a:hover {box-shadow: 0 -3px 0 rgba(26, 195, 225, 0.47) inset;}
.video_sorts_block a i {font-size: 0.8em}

.read_more {padding: 0 15px 15px 15px; font-size: 13px}*/
/*==================================*/


/*========= Форма =================*/
.appearance .select {
    background: url("/template/svg/arrow_down.svg") no-repeat 100% 50%;
}
.form { position: relative}
.form-item { display: flex; flex-direction: column; gap: 0.75rem; margin-bottom: 1em;}

.form-item_header{
    display: flex;
    flex-direction: column;
    gap: 0.25rem
}

.form-item__label,
.form-item__label-f { color: var(--white)}
.form-item__label-f { width: 130px}

.form-item__hint{
    color: var(--d400);
    font-size: 0.75rem;
}

.form-item__control { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center}
.form-item__control.min { line-height: 24px}
.textfield { position: relative; box-sizing: border-box; display: block; width: 100%; height: 40px; margin: 0; padding: 6px 10px; background-color: white; border: 1px solid rgba(17, 17, 17, 0.17); border-radius: 5px; color: #000; font-family: "Helvetica Neue", "Arial", "Helvetica", sans-serif; font-size: 20px; line-height: 23px; -webkit-appearance: none}
.form-item textarea {
    width: 100%;
    min-height: 140px;
    border: 1px solid #d2d2d2;
    padding: var(--padding-3);
    font-size: 16px;
    border-radius: var(--base-radius);
    resize: none
}
.min_info {font-size: 0.8em; line-height: 1.2em; padding-left: 7px; color: #2c689c;}
.add_h1 { padding: 0 0 30px 0; font-weight: bold }
.form-item input {color: #000}
.form-item input::placeholder{color: #bfbfbf}


.form-item_auth {width: 100%;line-height: 40px;padding: 7px 40px}
.form-item_auth__label,
.form-item_auth__label-f {display: block; line-height: 22px;color: #FFF;
    padding-left: 5px;
    padding-bottom: 5px;}
.form-item_auth__control {vertical-align: top}
.form-item_auth__control.min {line-height: 24px}

.whymail {
    line-height: 1.2em;
    padding: 10px;
    font-size: 14px;
}

.btn_auth {
    line-height: 46px;
    border-radius: 100px;
    border: 0;
    background: var(--b1100);
    text-align: center;
    color: var(--white);
    cursor: default;
    font-size: 16px;
    width: 100%;
    position: relative;
}

.addPostFormItem{
    padding: 0 30px;
    margin-bottom: 0;
}

.addPostFormItem .titleInput{
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 20px;
    font-size: 24px;
}
.addPostFormItem .titleInput:focus{
    border: none;
}
.addPostFormItem .titleInput::placeholder{
    color: #777 !important;
}

.addPostFormBottom{
    padding: 5px 30px;
}
/*==================================*/


/*======== HINT ========*/
.hint_profile{
    width: 300px;
}
.hint_profile-photo{
    width: 50px; height: 50px;
    border-radius: 50%;
}

.hint_counters{
    display: flex;
    justify-content: space-around;
}
.hint_counters-item{
    flex: 0 0 calc(33.333% - 1px);
    border-right: 1px solid var(--d800);
}
.hint_counters-item:last-child{
    border: none;
}


.hint_bike{
    width: 300px;
}
.hint_bike .preview{
    width: 100%;
    padding-top: 60%;
    position: relative;
    display: block;
    border-radius: 10px;
    overflow: hidden;
}

/*==================================*/


/*===============*/

.spoiler_btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-1);
    user-select: none;
}
.spoiler_body{
    display: none
}
.spoiler.active .spoiler_body{
    display: block;
}
.businessSpoiler .spoiler_btn:hover{
    color: var(--b800)
}

.spoiler_click { cursor: pointer}
.spoiler_click:active {background: #e4e4e4}
.spoiler_click:before { font-family: 'icomoon'; content: '\e908'; margin-right: 10px; display:none}

/*=================================*/
.message{border: 1px solid #72b1e8; background: #72b1e8; padding: 10px 10px 10px 40px; margin: 0 0 20px 0; color: #FFF; font-size: 14px; box-sizing: border-box; clear: both; position: relative; line-height: 1.8em; width: 100%; font-family: Sans-serif, serif}
.message > i[class^=icon-] { position: absolute; left: 12px; top: 23px; font-size: 17px}
.message.red,
.message-error { background: #ff3030 !important; border: none; color: #FFF !important}
.message.blue,
.message-info { border-color: #AFDDFF !important; background: #E5F4FF !important; color: #153F5D !important; }
/*=====================================*/



/*=== Авторизация===*/

.auth_social_btns{
    display: flex;
    justify-content: space-between;
    gap: 1em;
    padding: 10px;
}

.auth_spacer{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 60px;
    gap: 1em;
    white-space: nowrap;
}

.auth_spacer::before,
.auth_spacer::after{
    content: '';
    height: 1px;
    width: 100%;
    background: var(--g500);
}



.socialAuth_btn {
    text-decoration: none;
    display: flex;
    box-sizing: border-box;
    text-align: center;
    border-radius: 100px;
    font-size: 14px;
    position: relative;
    flex: 1 0 auto;
    width: calc(33.333% - 1em);
    align-items: center;
    justify-content: center;
    line-height: 2.4em;
    gap: 5px;
}
.socialAuth_btn i {
    font-size: 1.2em;
}
.fb_auth {
    color: #FFF !important;
    background: #1877F2;
}
.apple_auth{
    background: #101010 !important;
    color: #FFF;
}
.apple_auth i{
    font-size: 0.9em;
}
.google_auth{
    background: #101010 !important;
    color: #FFF;
}
.google_auth i{
    font-size: 0.9em;
}
.socialAuth_btn:hover{
    color: inherit;
    opacity: 0.8;
}
.fb_auth img{display: inline-block}
.form-signin{max-width: 450px; margin: 0 auto 20px; border-radius: var(--base-radius)}

input[type=text].auth_input,
input[type=password].auth_input {
    height: auto;
    background-color: var(--d800);
    color: var(--white) !important;
    width: 100%;
    line-height: 50px;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 0 24px;
    border: none;
}

.form-signin .form-signin-heading,
.form-signin .checkbox{ margin-bottom: 10px }
.radio,
.checkbox { min-height: 20px; padding-left: 20px; display: block }
.message-info { color: #3a87ad; background-color: #d9edf7; border: 1px solid #bce8f1; padding: 10px; border-radius: 4px }
.message-info:before { content: '' }
.reg_confirm { background: #FFF; color: #000; padding: 10px 20px; border-radius: 3px; max-width: 400px; margin: auto; text-align: center; }

.logo_auth {padding: 0 40px}
.logo_auth h1 {text-align:center; line-height: 3em}
.logo_auth img{margin: auto}



.form-item_auth__links{
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 50px;
}
.form-item_auth__links > div{
    width: 50%
}
.form-item_auth__links > div:last-child{
    text-align: right;
}
.form-item_auth__links a, .form-item_auth__agrements > a{
    color: #FFF;
    text-shadow: 1px 1px 0 #00000073;
}
.form-item_auth__links a:hover, .form-item_auth__agrements > a:hover{
    color: #b8c3cc
}
.form-item_auth__agrements {
    text-align: center;
    padding: 20px 20px 30px 20px;
    font-size: 14px;
}

.auth_form_block{
    display: flex;
    padding: 60px 0 0 0;
    color: #FFF;
    gap: 100px;
    justify-content: center;
    align-items: center;
}
.auth_form_block h1{
    color: var(--white);
    font-size: 30px;
    font-weight: 500;
    line-height: normal;
    padding: 16px 24px;
}
.auth_form_block .message.message-error{
    background: #ff1d1d !important;
}


.auth_info{
    text-align: center;
    font-size: 24px;
}
.auth_info_mru{
    font-size: 50px;
}
.auth_info_ab{
    color: var(--b200);
    font-size: 30px;
}
.auth_info_advantages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4em;
    margin: 50px auto 0 auto;
}
.auth_info_advantages i{
    color: var(--b1100);
    font-size: 1.2em;
}

/*=====================================*/

/*=== Главная ======*/
.conteiner_main { padding: 0 0 40px 0; position: relative; flex-direction: column}
.conteiner_main:before,
.conteiner_main:after { left: 50%; opacity: 0.2 }
.main_row { display: flex; width: 100% }
.main_row__first { width: 100%; position: relative; overflow: hidden }
.main_title_c,
.main_bottom_c { font-size: 20px; font-weight: bold; text-transform: uppercase; color: #292929; margin-bottom: 10px; padding: 0 10px; text-align: center }
.main_bottom_c .mr_b { font-size: 15px; display: inline-block; background: #f55120; color: #FFF; padding: 0 15px; line-height: 30px }

.control { display: block; width: 27px; height: 100%; text-indent: -10000px; position: absolute; top: 0; cursor: pointer; z-index: 10; opacity: 0.8 }
.control:hover { opacity: 1 }
#leftControl:before,
#rightControl:before { content: ''; display: block; width: 27px; height: 40px; position: absolute; top: 50%; margin-top: -20px; background: url("/template/img/sprites.png") -504px -192px }
#rightControl { right: 0 }
#rightControl:before { background-position: -504px -144px }

.users_last { display:flex; width:100%; flex-wrap:wrap}
.users_last_main { width: 33.333%; display: block; padding: 0 2px 5px 2px}
.users_last_main img { max-width: 100%}


.feed_switcher {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    z-index: 10;
}
.feed_switcher__inner{
    display: flex;
    align-items: center;
    padding: 6px;
    background: var(--white);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    border-radius: 100px;
}
.feed_switcher__btn {
    padding: 4px 24px;
    border-radius: 14px;
    margin-right: 6px;
}
.feed_switcher__btn:hover{
    background: var(--d700);
    color: var(--white)
}
.feed_switcher__btn.actlink{
    background: var(--g700);
    color: var(--white);
}
.feed_switcher__inner > .feed_switcher__btn:last-child {
    margin-right: 0;
}

.selected_local_feed {
    line-height: 40px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 20px;
}
.selected_local_feed:hover{
    cursor: pointer;
    color: var(--b800)
}

/*========================*/

.marker { background: #f55120; color: #FFF; height: 30px; line-height: 30px; padding: 0 10px; position: absolute; top: -30px; right: 20px; }
#slideshow { overflow: hidden; }
.feed_short { line-height: 15px }
.feed_short > li { padding: 0 0 5px 40px; position: relative; margin-bottom: 5px; border-bottom: 1px solid #f3f3f3; font-size: 14px}
.feed_short .go_to_post { position: absolute; top: 0; left: 0; width: 30px; height: 30px; cursor: pointer; padding: 7px; transform: rotate(-90deg); -webkit-transform: rotate(90deg); opacity: 0.4 }
.feed_short .go_to_post:hover { color: #F00; opacity: 1 }
.feed_short > li:last-child { border-bottom: 0; margin-bottom: 0 }
/*если находится справа*/
.feed_short.on_right > li { padding: 0 0 5px 25px }
.feed_short.on_right .go_to_post { transform: rotate(90deg); -webkit-transform: rotate(90deg); right: auto; left: 0; width: 20px; height: 20px }
.feed_short a { color: #3c3c3c }
.feed_short a:hover { color: #39beff }
.feed_short .fdate { font-size: 11px; display: block; color: #b3b3b3; line-height: 15px }
.filters_line { height: 41px; box-sizing: content-box; padding-right: 50px; position: relative }
.filters__select { float: right; width: 300px}
.filters_line__setting { height: 40px; padding: 9px; color: #e6e6e6; font-weight: normal; font-size: 22px; position: absolute; right: 0; top: 0 }
.filters_line__setting:hover { color: #444}
.filters_line__setting:active { color: #16A9F0}

.email_notifications, .setting_notifications { line-height: 1.5em}


.businessMarker{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF;
    padding: 4px;
    background: #ff4336;
    border-radius: 16px;
    width: auto;
    min-width: 32px;
    height: 24px;
    z-index: 77;
}

.businessMarker-icon{
    width: 12px;
    height: 12px;
    margin: 0 2px;
}

.businessMarker::before,
.businessMarker::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 4px;
    border-style: solid;
    border-color: #FFF transparent transparent transparent;
    transform: translateX(-50%);
    left: 50%;
}

.businessMarker::before{
    border-width: 5px;
    bottom: -10px;
}

.businessMarker::after {
    border-color: #ff4336 transparent transparent transparent;
    bottom: -8px;
}

/*========================================*/

/*=== FeedBack ====*/
.box_wht__content.feedback_content {
    padding: 30px;
    line-height: 1.6em;
}
.box_wht__content.feedback_content a{
    color: var(--b800);
}
.mess_feedback { padding: 20px; background: #F9F9F9; border: 1px solid #EAEAEA; box-sizing: border-box; }
.feed { min-width: 320px; margin: 20px auto}
.feed input[type=text] { line-height: 26px; border-radius: 3px; font-size: 15px; border: 1px solid #CCC; margin: 0 0 10px 0; padding: 5px 10px; }
.feed textarea {margin: 0; padding: 5px 10px; border: 1px solid #CCC; border-radius: 3px; width: 100%; min-height: 110px; font-size: 15px}
.resp {margin-left: 20px}
/*=====================================*/





/*=== Спидбар ===*/
.speedbar { width: 100%; line-height: 30px; padding: 10px; box-shadow: 0 5px 23px rgba(0,0,0,.11); font-size: 18px}
.speedbar_contain {
    max-width: 1240px;
    margin: auto;
}
.speedbar a {color: #545b64;font-weight: 500; display: inline-block;}
.speedbar a:hover { text-decoration: underline}


.speedbar-sep {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 26px;
    background: url(/template/img/sprites.png) 0 -240px no-repeat;
    vertical-align: middle;
}


.speedbar_profile{
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding: 20px;
    font-weight: 500;
}
.speedbar_profile.standAlone{
    border-bottom: none;
    padding: 0;
}
.speedbar_profile a{
    color: #566168; font-weight: normal
}
.speedbar_profile a:hover { text-decoration: underline; color: #292929 }
.speedbar_profile__user{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    color: #505050
}
.spu_ava{
    padding: 0 10px 0 0;
}
.spu_ava > img{
    border-radius: 50%;
    display: block;
    width: 30px;
    height: 30px;
}
/*=====================================*/

/*=============== ГАРАЖ ===============*/
.garageContainer{
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.garageContainer h1{
    padding: 0 20px;
}
.garage{
    padding-bottom: 20px;
    align-items: start;
}
.garage_content{
    width: calc(100% - 260px - 1.5em);
    float: left;
}
.garage_sidebar{
    float: right;
    width: 260px;
}
.garageItems{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.garage_item{
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    background: var(--d800);
    border-radius: var(--base-radius-16);
    overflow: hidden;
}
.garage_item__previewContainer{
    width: 40%;
    position: relative;
    flex: 0 0 auto;
}
.garage_item__preview{
    width: 100%;
    height: 100%;
}
.garage_item__preview img{
    position: absolute;
    width: 100%; height: 100%; object-fit: cover;
}
.garage_item__info {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: 20px;
    flex: 1 1 auto;
}
.garage_item-title {
    font-size: 18px;
    font-weight: 500;
}

.garage_item-status {
    font-size: 14px;
}
.garage_item-year {
    line-height: 1.6em;
    font-size: 14px;
    display: flex;
    gap: 0.8em;
}

.garage_item-descr{
    margin: 10px 0;
    min-height: 3em;
}

.garage_item-stats {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1em;
    text-align: center;
}
.garage_item-stats > div{
    display: flex;
    flex-direction: column;
    gap: 0.2em
}
.garage_item-stats__spacer{
    width: 1px;
    background: var(--d700);
}
/*=====================================*/

/*=============== Навигация по страницам =================*/
#clsLink3 {
    font-family: Tahoma, serif; font-size: 15px; margin-bottom: 0; margin-top: 0; text-align: center; padding: 30px 0; line-height: 40px; clear: both; }
#clsLink3 a { border: 1px solid #d8d8d8; background-color: #ffffff; padding: 7px 12px; margin-left: -1px; text-decoration: none; color: #445f71; }
#clsLink3 a:first-child,
#clsLink3 span:first-child { border-radius: 3px 0 0 3px }
#clsLink3 a:last-child,
#clsLink3 span:last-child { border-radius: 0 3px 3px 0 }
#clsLink3 a:hover { background-color: #363839; color: #FFF; border: 0; padding: 8px 13px; }
#clsLink3 span { background-color: #363839; color: #FFF; padding: 8px 13px; margin-left: -1px; }
.ruler { clear: both; width: 100%; display: block; height: 90px; margin-top: 30px; }
/*========================================================*/

/* ==================== Рекомендуемые байки ==============*/
.rec_bikes{
    padding: 15px 15px 30px 15px;
    overflow: hidden;
}

.rec_bikes > ul {
    overflow: hidden;
    white-space: nowrap;
}

.rec_bike{
    width: 140px;
    margin-right: 20px;
    text-align: center;
    display: inline-block;
}
.rec_bike > a{
    /*color: #333;*/
    text-decoration: none;
    display: block;
    border-radius: var(--base-radius);
    overflow: hidden;
}

.rec_bike__image{
    width: inherit;
    height: 140px;
    position: relative;
}
.rec_bike__image > .short_count_bikelogs{
    position: absolute;
    border-radius: 50px;
    width: 24px;
    height: 24px;
    background: #FFF;
    line-height: 24px;
    bottom: 6px;
    right: 6px;
}
.rec_bike__image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--base-radius);
}
.rec_bike__title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 20px;
    overflow: hidden;
    margin-top: 8px;
    height: 40px;
    white-space: normal;
    font-size: 14px;
}

.rec_bike__bottom .btn {
    line-height: 28px;
    height: 28px;
    width: 70%;
    margin: 10px auto 0 auto;
    font-size: 13px;
}
/*============================================================*/


/*===================== ПЛАВАЮЩИЙ INPUT ======================*/
/* Общие стили для контейнера */
.floating-input-container {
    position: relative;
}

/* Стили для поля ввода */
.input-field {
    width: 100%;
    padding: 12px 0 8px 0;
    font-size: 16px;
    border: none;
    border-bottom: 2px solid #ccc;
    background: transparent;
    outline: none;
}

.input-field:focus{
    outline: 2px solid var(--b800)
}

/* Стили для метки */
.floating-label {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: var(--d600);
    transition: 0.2s ease all;
    pointer-events: none;
    line-height: normal;
}

/* Метка поднимается, если поле в фокусе или уже есть введенные данные */
.input-field:focus + .floating-label,
.input-field:not(:placeholder-shown) + .floating-label {
    top: 2px;
    font-size: 12px;
    transform: translateY(0);
}

/* Акцент на границе при фокусе на поле */
.input-field:focus {
    border-bottom: 2px solid #000;
}
/*============================================================*/

/*===================== SLY =============================*/
.sly{
    position: relative;
}
.sly .arrow{
    position: absolute;
    top: 50%;
    width: 44px;
    height: 44px;
    margin: auto 14px;
}

.sly .arrow.disabled{
    display: none;
}

.sly .arrow::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 100%;
    background: #fff;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out;
    -webkit-transition-property: -webkit-transform,-webkit-box-shadow;
    transition-property: transform,box-shadow;
    transition-property: transform,box-shadow,-webkit-transform,-webkit-box-shadow;
}
.sly .arrow::after{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: no-repeat 50% 50% url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><g fill='none' stroke='%23000' stroke-width='2'><path d='M1 9h14M9 2l7 7-7 7'/></g></svg>");
}

.sly .arrowPrev{
    left: -10px;
    transform: translateY(-50%) scale(-1,1);
    -webkit-transform: translateY(-50%) scale(-1,1);
}

.sly .arrowNext{
    right: -10px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.sly.rec_bikes .arrow{
    top: 88px;
}

.sly .arrow:hover:before{
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-box-shadow: 0 2px 8px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.2);
}
.sly .arrow.disabled:after{
    opacity: 0.1;
}
/*====================================================*/

/*===================== ADD EVENTS ===================*/
.event_cover_upload > #filesUpload{
    cursor: pointer
}
.event_cover_upload:hover{
    opacity: 0.85;
}
.event_cover_upload {
    position: relative;
    border-radius: var(--base-radius);
    overflow: hidden;
}
.event_cover_upload > img{
    width: 100%;
}
.event_cover_upload > label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 22px;
    border-radius: 20px;
    color: var(--white);
    border: 2px solid rgba(255, 255, 255, 0.4);
}
.form_item-container {
    padding: 20px;
    max-width: 80%;
    margin: auto;
}

.form-item__spacer {
    height: 1px;
    background: var(--g100);
    display: block;
    margin: 50px -14%;
}

.form-item__spacer.min{
    margin: 20px -14%;
}

.form-h {
    margin-bottom: 25px;
    padding: 10px 0;
}

.form-h.spoiler {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 -10px 20px -10px;
    border-radius: 5px;
    cursor: pointer;
}

.form-h.spoiler + fieldset{
    display: none;
}

.form-h.spoiler:hover{
    background: var(--d700);
}

.form-h.spoiler > .icon {
    padding: 0 20px;
    width: 20px;
    position: relative;
}

.form-h.spoiler > .icon > i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.form-h h2 {
    font-size: 28px;
}
.form-h .descr > span, .form-h > span{
    line-height: 1em;
    font-size: 14px;
}

.add_event-price > div {
    padding-right: 10px;
}

.form-h40 input, .form-h40 select, .form-h40 .chosen-container-multi .chosen-choices li.search-field input[type=text], .form-h40 .chosen-single{
    line-height: 40px !important;
    height: 40px !important;
    min-width: 70px !important;
    padding-top: 0 !important; padding-bottom: 0 !important; margin: 0 !important;
}

.form-h40 .chosen-choices {
    line-height: 40px !important;
}

.form-h40 li.search-choice{
    padding: 7px 20px 7px 5px !important;
}
.form-h40 li.search-choice .search-choice-close{
    top: 8px !important;
}
.form-hide_item > .form-item{
    display: none;
}
.form-hide_item__text{
    color: var(--b800);
    cursor: pointer;
    font-weight: 500;
    margin: 20px 0;
    text-transform: uppercase;
}
.form-hide_item__text:hover {
    color: var(--b500);
}
.form-hide_item.active > .form-item{
    display: block;
}
.form-hide_item.active > .form-hide_item__text{
    display: none;
}

.hide-control__text{
    color: var(--b800);
    cursor: default;
    user-select: none;
}

.hide-control__text:hover{
    color: var(--b500)
}

.hide-control .hide-control__content{
    display: none;
}

.hide-control.active .hide-control__content{
    display: block;
}
.hide-control.active .hide-control__text{
    display: none;
}

/*===================== REC CARD =====================*/
.rec_item{
    position: relative;
}
.rec_item:hover .rec_item__close {
    display: block;
}
.rec_item__close {
    display: none;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    right: 7px;
    border-radius: 4px;
    overflow: hidden;
}
.rec_item__close:before {
    content: '';
    position: absolute;
    opacity: .5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #000;
}
.rec_item__close:after {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5%203.691L8.422.27a.92.92%200%200%201%201.305.004.924.924%200%200%201%20.004%201.305L6.31%205%209.73%208.422a.92.92%200%200%201-.004%201.305.924.924%200%200%201-1.305.004L5%206.31%201.578%209.73a.92.92%200%200%201-1.305-.004.924.924%200%200%201-.004-1.305L3.69%205%20.27%201.578A.92.92%200%200%201%20.273.273.924.924%200%200%201%201.578.27L5%203.69z%22%2F%3E%3C%2Fsvg%3E") no-repeat center/10px;
    content: '';
    position: absolute;
    opacity: .8;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.rec_item__close:hover:after{
    opacity: 1;
}
/*==================================*/


/*===================== ROUTES POINTS ===================*/
.point_in_form {
    padding: 0 0 20px 20px;
    border-left: 4px solid var(--b800);
    line-height: 30px;
    position: relative;
    margin-left: 10px;
}

.point_in_form:last-child{
    border-left-color: transparent;
    padding-bottom: 0;
}

.point_in_form .icon {
    position: absolute;
    top: 0;
    left: -15px;
    color: var(--white)
}

.point_in_form .icon > img{
    width: 26px;
}

.point_in_form .container {
    padding: 8px;
    position: relative;
}

.simplePoints .point_in_form .container {
    background: none;
    padding: 8px 0 0 0;
}

.point_in_form .container > .edit_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 10px 10px 10px;
    background: rgba(0,0,0,0.3);
    opacity: 0;
    font-size: 20px;
    cursor: pointer;
}

.point_in_form .container > .edit_overlay > i{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.point_in_form .container:hover > .edit_overlay{
    opacity: 1;
}

.point_in_form .date {
    color: var(--b1000);
    user-select: none;
    line-height: 1em;
    font-size: 14px;
    font-weight: bold;
}

.point_in_form .address{
    line-height: 1em;
    font-size: 14px;
}

.point_in_form .date + .address{
    margin-top: 10px;
}


.point_in_form .descr {
    line-height: 1.4em;
}
.point_in_form .date + .descr,
.point_in_form .address + .descr
{
    margin-top: 10px;
}
.simplePoints .point_in_form .descr{
    padding: 5px 0 0 0;
}
.route-item--label {
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.routePreview {
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0 2px 10px rgba(0,0,0,0.13);
    overflow: hidden;
}

.routePreview-image{
    width: 100%;
    padding-top: 50%;
    position: relative;
    display: block;
    z-index: 1;
}

.routePreview-image picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.routePreview-image img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.routePreview-top {
    padding: 20px;
    font-size: 20px;
}

.routePreview-top > div{
    flex: 1 0 33.333%;
    gap: 0.4em;
    border-right: 1px solid var(--g200);
}

.routePreview-top > div:last-child{
    border: none;
}

.routePreview-top i{
    font-size: 1.8rem;
}

.routePreview-bottom {
    padding: 20px;
    font-size: 16px;
}

.routePreview-bottom i{
    margin: 0 8px;
    font-size: 22px;
    font-style: normal;
}

.routePreview-bottom .routeSteps {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid var(--g100);
}

.routePreview_infoList > span{
    display: inline-flex;
    align-items: center;
}

.routePreview-content {
    position: relative;
}

.openRouteOnPageBtn_wrapper{
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.openRouteOnPageBtn{
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.openRouteOnPageBtn_wrapper:hover .openRouteOnPageBtn{
    background: rgba(0, 0, 0, 0.5);
}

.routePreview-content a.routePreviewLink{
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
}

.routePreview-content .openMapBtn{
    position: absolute;
    top: 1em;
    right: 1em;
    padding: 0.75em;
    line-height: 1em;
    background: rgba(0, 0, 0, 0.73);
    border: none;
    border-radius: 0.4em;
    z-index: 2;
}

.mapWidgetMap{
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: 3;
}

.mapWidgetOverlay-changeSize{
    display: block;
    font-size: 22px;
}


/*===================== Горизонтальные маршруты ================*/

.routePreviewHorizontal{
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    border-radius: 16px;
    background: var(--d800);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.13);
    overflow: hidden;
    grid-gap: 16px;
    padding-right: 10px;
    grid-template-areas:
            "rph-preview rph-header"
            "rph-preview rph-profile"
            "rph-preview rph-description"
            "rph-preview rph-bottom";
}

.routePreviewHorizontal-preview{
    grid-area: rph-preview;
    width: auto;
    padding-top: 0;
    position: relative;
    display: block;
    z-index: 1;
    flex: 0 0 auto;
}

.routePreviewHorizontal-preview picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.routePreviewHorizontal-preview img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.routePreviewHorizontal-preview .routePhotosCarousel,
.routePreviewHorizontal-preview .owl-carousel .owl-stage-outer,
.routePreviewHorizontal-preview .owl-carousel .owl-stage,
.routePreviewHorizontal-preview .owl-carousel .owl-item,
.garage_item__previewContainer .routePhotosCarousel,
.garage_item__previewContainer .owl-carousel .owl-stage-outer,
.garage_item__previewContainer .owl-carousel .owl-stage,
.garage_item__previewContainer .owl-carousel .owl-item{
    height: 100%;
}

.routePreviewHorizontal-preview .owl-carousel .owl-dots,
.garage_item__previewContainer .owl-carousel .owl-dots{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.routePreviewHorizontal-header{
    grid-area: rph-header;
    padding-top: 16px;
}

.routePreviewHorizontal-profile{
    grid-area: rph-profile;
}

.routePreviewHorizontal-description{
    grid-area: rph-description;
}

.routePreviewHorizontal-bottom{
    grid-area: rph-bottom;
    padding: 0 16px 16px 0;
}

.routePreview-top {
    padding: 20px;
    font-size: 20px;
}

.routePreview-top > div{
    flex: 1 0 33.333%;
    gap: 0.4em;
    border-right: 1px solid var(--g200);
}

.routePreview-top > div:last-child{
    border: none;
}

.routePreview-top i{
    font-size: 1.8rem;
}

.routePreview-bottom {
    padding: 20px;
    font-size: 16px;
}

.routePreview-bottom i{
    margin: 0 8px;
    font-size: 22px;
    font-style: normal;
}

.routePreview-bottom .routeSteps {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid var(--g100);
}

.routePreview_infoList > span{
    display: inline-flex;
    align-items: center;
}

.routePreview-content {
    position: relative;
}

.routePreview-content .openMapBtn{
    position: absolute;
    top: 1em;
    right: 1em;
    padding: 0.75em;
    line-height: 1em;
    background: rgba(0, 0, 0, 0.73);
    border: none;
    border-radius: 0.4em;
    z-index: 2;
}
/*=======================================*/


/*===================== ПОСТЫ ===========================*/
.posts { width: 100% }
.posts > .box_wht:last-child { margin-bottom: 0 }
.post_content { padding: 15px 15px 0 15px }
.post__title { position: relative; padding: 0 40px 0 60px; min-height: 60px; margin-bottom: 15px; border-bottom: 1px solid #eaeaea }
.post__ava { width: 50px; height: 50px; position: absolute; top: 0; left: 0}
	.post__ava img {border-radius: 50%}

.post__info { font-size: 13px; display: block; padding: 2px 0 5px 0 }
.post__info li { display: inline-block; padding: 0 15px 5px 0}
.post__info i { font-size: 0.8em }
.post__title > a { font-weight: bold; font-size: 17px; margin: 3px 0 5px 0; display: inline-block; }
.post__title a { color: #236a9a }
.post__image_full { overflow: hidden; position: relative; padding-top: 15px }
.post__image_full > a > img { max-width: 100%; margin: auto }
.post__text { color: #464646; word-wrap: break-word; font-size: 16px; line-height: 1.6em;}
.post__image_list > li { display: inline-block; width: 155px; height: 135px; position: relative; margin: 10px 10px 0 0; overflow: hidden }
.post__image_list > li > img,
.post__ava > img { height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); }
.post__bottom { display: block; line-height: 40px; padding: 0 40px 0 0; margin-top: 15px; clear: both; position: relative; font-size: 13px; border-top: 1px solid #ecf0f2}
.post__bottom > span { margin-right: 10px }
.post__bottom span i { font-size: 0.9em }
.post__bottom .cnt_m {color: #797979;font-size: 0.9em}

.posts__full {padding: 15px 15px 0 15px; line-height:1.6em}
.posts__full img{ max-width: 100%; margin: 10px auto;}
.posts__func {display: block;line-height: 40px;padding: 10px 10px 0 10px}
.posts__func span { margin-right: 15px}
.pagebox__info i[class^=icon-] { font-size: 0.8em}

.posts__rotate { display:block; height: 20px; line-height: 20px; margin-bottom: 20px; padding: 0 10px;}
.posts__rotate a { text-decoration: underline; color: #636363}
.posts__rotate a:hover { color: #3a3a3a}

.service_bike{margin-top:10px}
/*========================================================*/

.full_page_spacer_title {
    font-size: 28px;
    color: #000;
    padding: 30px 20px 20px 20px;
    font-weight: 500;
}

.full_page_spacer_title > a{
    color: var(--b800);
}

/*===================== Relative =========================*/
.relative_posts {
    display: flex;
    flex-wrap: wrap;
}
.relative_posts .box_wht {
    width: 50%;
    margin-bottom: 10px;
}

/*.relative_posts{
    display: flex;
    flex-wrap: wrap;
}
.relative_posts > div {
    width: 50%
}
.relative_posts > div:nth-child(odd){
    width: calc(50% - 20px);
    margin-right: 20px;
}*/
.rel_post_content {
    padding: 0;
}
.rel_post__header {
    line-height: 30px;
    padding: 15px;
}
.rel_post__owner {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.rel_post__owner-name {
    padding-left: 10px;
    line-height: 18px;
}
.rel_post__owner-ava {
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
}
.rel_post__owner-ava > img{
    max-width: 100%;
    border-radius: 50px;
}
.rel_post__title{
    font-size: 18px;
    padding: 0 15px;
}
.rel_post__text{
    padding: 0 15px;
}
.rel_post__image > img {
    max-width: 100%;
}

.rel_post__bottom {
    padding: 12px;
}

.rel_post__bottom [class*=icon-] {
    font-size: 22px;
    vertical-align: text-bottom;
}

.rel_post__main>a>div+div, .rel_post__main>div+div {
    padding-top: 15px;
}

.rel_post_content .rel_post__image {
    padding-top: 70%;
    margin-top: 14px;
    position: relative;
    overflow: hidden;
    background: var(--d950);
}

.rel_post_content .rel_post__image > img {
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
/*========================================================*/

/*===================== Настройки ========================*/

/*========================================================*/

/*===================== Уведомления ======================*/
.full_notifications-content {
    padding: 10px 0;
}
.notify {
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 20px;
    border-bottom: 1px solid var(--g200);
}
.notify .avatar{
    position: relative;
    margin-right: 1.2rem;
    width: 50px;
    height: 50px;
    flex: 0 0 auto;
    align-self: flex-start;
}
.notify .avatar img{
    display: block;
    border-radius: 50%;
    max-width: 50px;
}

.notify .notifyContent{
    flex: 1 1 auto;
    line-height: 1.4em;
    font-size: 14px;
}

.notify .notifyContent i{
    color: var(--g500);
}

.notify .notifyContent b{
    color: var(--b600)
}

.notify .text{
    display: block;
    width: 100%;
}

.notify .image{
    max-width: 70px;
    flex: 0 0 auto;
    align-self: flex-start;
}

.notify .image img {
    max-width: 100%;
    border-radius: 4px;
}

.notify .actionButtons{
    margin-top: 10px;
}

.notifyIcon {
    width: 24px;
    height: 24px;
    padding: 4px;
    font-size: 12px;
    position: absolute;
    border-radius: 50%;
    bottom: -4px;
    right: -4px;
    color: #FFF;
    border: 2px solid var(--d800)
}

.full_notifications-content .notify:last-child{
    border-bottom: none;
}

.addCommentsNotify {background: #545b64}
.likeNotify {background: #0092ee}
.replyCommentsNotify {background: #06b00a}
.privateMessageNotify {background: #daaf04}
.requestFriendsNotify {background: #00d975}
.approvedFriendsNotify {background: #ff0c5c}
.followUsersNotify {background: #1b72a4}
.followBikesNotify {background: #9700ae}
.addEventsInAreaNotify {background: #72a41b}
.mentionNotify {background: #FF8000}


.addCommentsNotifySettings {color: #545b64}
.likeNotifySettings {color: #0092ee}
.replyCommentsNotifySettings {color: #06b00a}
.privateMessageNotifySettings {color: #daaf04}
.requestFriendsNotifySettings {color: #00d975}
.approvedFriendsNotifySettings {color: #ff0c5c}
.followUsersNotifySettings {color: #1b72a4}
.followBikesNotifySettings {color: #9700ae}
.addEventsInAreaNotifySettings {color: #72a41b}
.mentionNotifySettings {color: #FF8000}
/*========================================================*/

/*===================== Альбомы ==========================*/
.gall_prev { float: left; cursor: pointer }
.gall_prev:hover { opacity: 0.8 }
.album_info h1 { text-align: center; margin-bottom: 10px; color: #444 }
.album_descr { padding: 0 0 20px 0; text-align: center }
.album_opt { margin-bottom: 20px; text-align: center; color: #9a9a9a }

.album_last_min {width: 100%;position: relative;display: inline-block; margin-bottom: 15px; border-radius: 7px; overflow: hidden}
.album_last_min:last-child { margin-bottom: 0}
.album_prev, .album_prev__min {
    background-size: 25%;
    background: #efefef url(/template/svg/photo-camera.svg) no-repeat center;
}
.album_short__image {width: 100%; height: 150px; background-size: cover;background-position: center;}
.hiden_album_icon {position: absolute;padding: 5px;color: rgba(0, 0, 0, 0.7)}
.album_short__name {height: 30px;line-height: 30px;color: #FFF;font-weight: bold;position: absolute;bottom: 0;left: 0;width: 100%;padding: 0 50px 0 5px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;font-size: 0.9em; background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 90%)}
.album_count_photos { position:absolute; top: 0; right:10px}
/*=========================================================*/

.upload_image { display: none }
.attach_button { text-align: left; line-height: 22px; color: #696969; position: relative; display: block; border: 1px dashed #CCC; font-size: 12px; padding: 7px; border-radius: 5px }
.attach_button:hover { color: #131313; border-color: #757575 }
.attach_button span { cursor: pointer; font-size: 14px }
.attach_button [type=file] { width: 100%; height: 100%; opacity: 0; position: absolute; z-index: 9999; left: 0; top: 0 }
.progress {display: block;border-radius: 2px;width: 90%;color: #FFF;height: 4px;position: absolute;bottom: 13px;left: 5%}
.progress .percent,
.progress .load_line {position: absolute; height: 100%; text-align: center; left: 0; top: 0}
.progress .percent { width: 100%; z-index: 2 }
.progress .load_line {width: 0; background: #07aeff; z-index: 1; border-radius: var(--base-radius); height: 10px}
.progress-m { position:absolute; top:0; left: 0; width: 100%; height: 5px; margin:0; padding:0}

.attach_box {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-4);
}
#sortable > div { cursor: move }
.image_box {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    width: calc(20% - var(--gap-4));
    aspect-ratio: 1 / 1;
    background: var(--d800);
    box-sizing: border-box;
    border-radius: var(--base-radius-16);
    vertical-align: top;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.image_box > .loaderbox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
}
.image_box > .loaderbox > .loader{
    width: 40px;
    height: 40px;
    border-width: 3px;
}
.image_box > .please_wait{
    display: none;
    position: absolute;
    bottom: 13px;
    left: 0;
    width:100%;
    text-align: center;
}
.image_box img {width: 100%;height: 100%;object-fit: cover}
.image_box__photo { width: 100%; height: 130px; display: block; background: no-repeat center 35%;background-size: cover}
.image_box__pics { position: relative; width: 240px}
.image_box__pics img {width: 100%}
.image_box__overlay { background: rgba(0,0,0,0.5); position: absolute; width: 100%; height: 100%; top: 0; left: 0; }
.image_box__crop { border-radius: 5px; background: #ff9c9c; position: absolute; opacity: 0.7; top: 0; left: 0; z-index: 2; }
.image_box__func {cursor: default;text-align: center;position: absolute;bottom: 0;text-shadow: 1px 1px 0 rgba(191, 191, 191, 0.87);width: 100%;left: 0;line-height: 20px;font-size: 13px}
.image_box__func > input[type=text] {max-width: 95% !important;font-size: 11px;padding: 0 5px;border: 0;height: 20px; opacity: 0.5} /*для альбомов*/
.image_box__func > input[type=text]:focus {opacity: 0.9;border: 1px solid #37b8eb}
.image_box__func span:hover { cursor: pointer; text-decoration: underline; }
.image_box .icon-cross { position: absolute !important; top: 5px; right: 5px; padding: 5px !important; margin-right: 0 !important; background: rgba(0,0,0,0.4); color: #FFF; border-radius: var(--base-radius); z-index: 3; transition: all .1s ease-in-out; font-size: 9px;}
.image_box .icon-cross:hover { transform: rotate(-90deg); -webkit-transform: rotate(-90deg); cursor: pointer }
/*=====================Комментарии========================*/
.comments > li > ul { margin-left: 30px }
.comment_title_info { line-height: 70px; font-size: 20px; color: #4E4E4E }
.comments_full { margin: 0 50px; border-top: 1px solid #fef4e4 }
.i_comm { background: url("/template/img/logo.png") -4px -76px no-repeat; width: 16px; height: 16px; display: inline-block; margin-right: 5px; }
.comment { line-height: 20px;
    border-bottom: 1px solid #f3f3f3;
    padding: 16px 10px;
    position: relative;}
.comm_images { line-height: 16px; padding: 5px 5px 5px 10px; position: relative; width:100%}

.comment__left { position: absolute; top: 16px; left: 10px; height: 50px }
.comment_foto { width: 40px; border-radius: 30px}
.comm_images .comment__left { top: 5px }
.comm_images .comment_foto { margin-top: 0 }
.comect { font-size: 10px; color: #d8d8d8; display: inline-block; padding-left: 10px; opacity: 0; }
.comect:hover { color: #6d6d6d }
.comect:active { color: #64b9e8 }

.comment:hover .comect,
.comm_images:hover .comect { opacity: 1 }
.comment_content {
    padding-left: 54px;
    position: relative;
    word-break: break-all;
    padding-right: 60px;
}
.comment_title {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.comment_title i {
    vertical-align: middle
}

.author_entry {
    margin-left: 10px;
    font-size: 12px;
    line-height: 18px;
    background: #1ba1ff;
    color: #FFF;
    padding: 0 7px;
    border-radius: 3px;
}

.llogin {
    text-decoration: none;
    margin-right: 5px;
    font-weight: 500;
}
.llogin a {
    text-decoration: none;
    color: var(--g700);
}
.llogin a:hover { text-decoration: underline }
.comtext {
    margin-bottom: 5px;
    line-height: 1.6em;
    word-break: break-all;
}
.comtext > .r_name{
    color: #00a1ff;
}
.comtext_images img {
    max-height: 120px;
    display: inline-block;
    margin-right: 5px;
	border-radius: 5px;
}
.comm_images__comtext { line-height: 20px }
.comdate {
    color: #676767;
    font-size: 14px;
}

.comment_bottom .likes > .lk, .comment_bottom .likes > .dlk{
    font-size: 16px;
    padding: 2px;
}
.author_recept { margin-left: 10px; padding: 0 5px; background: #73c0ea; color: #FFF; border-radius: 3px; font-size: 0.8em }

.otv { cursor: pointer; color: #1a242b; display: inline-block; font-size: 0.8em; padding: 0 10px 0 0; }
.otv > i { font-size: 0.8em }
.otv:hover { color: #828282 }
.otv:active { color: #000 }
#resp { background: #F2F2F2; color: #A0A0A0; padding: 0 5px; min-width: 25px; cursor: default; font-weight: bold; text-align: center; border-radius: 2px }
.comment.plus #resp { background: #72b1e8; color: #FFF; }
.comment.min { opacity: 0.8 }
.comment.min.fl { opacity: 0.5 }
.comment.min:hover { opacity: 1 }
.add_comments { text-align: left; padding: 10px; position: relative }
ul.comments > li > .add_comments{
    margin-left: 30px;
}
.add_comments form { padding-left: 55px; margin-bottom: 10px }
.add_comments input { margin: 2px 0; background: #FFF; border: 1px solid #abadb3 }
.add_comments__area {
    box-sizing: border-box;
    overflow: visible;
    width: 100%;
    min-height: 100px;
    display: block;
    font-size: 15px;
    border-radius: 5px;
    resize: none;
    border: 1px solid var(--g100);
    padding: 15px 40px 15px 15px
}
.add_comments.q_comm { padding: 0 }
.q_comm form { padding: 0 }
.q_comm__text { padding: 10px 10px 10px 60px; position: relative; border-style: solid; border-width: 1px 0; border-color: #e6e6e6; margin-bottom: 10px }
.q_comm__area { background: transparent; line-height: 15px; width: 100%; border: 0; padding: 0; resize: none; font-size: 13px; color: #5a5a5a; }
.q_comm__area:focus {border: 0}
.q_comm__foto { width: 30px; position: absolute; left: 20px; top: 10px }


.latest_comment{
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid var(--g800);
}

div .latest_comment:last-child{
    border-bottom: none;
}

.latest_comment-author{
    align-items: center;
}

.latest_comment-author .author{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
}

.latest_comment-author .date{
    font-size: 0.8em;
    opacity: 0.7;
    padding-left: 8px;
}

.latest_comment-author img{
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
}

.latest_comment-text{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-height: 20px;
    overflow: hidden;
    margin: 8px 0;
}

.latest_comment-author, .latest_comment-post {
    max-height: 1.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.latest_comment-author:hover .author, .latest_comment-text:hover, .latest_comment-post:hover{
    color: var(--b800);
}


/*=== Комментарии для листалки (нужны мелкие) ===*/
.comments_images { }
.veryfi { display: block; }
.add_comments.ava_min .comment_foto { width: 40px }
.add_comments.ava_min form { padding-left: 50px }
.btn_c { border-radius: 30px; background-color: #37b8eb; padding: 0 20px; }
.comm_textarea { border-radius: 3px; margin-bottom: 10px; position:relative}
/*=============================*/


/*============= Автозаполнение в поиске ======================================*/
.ui-autocomplete { width: 140px; max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    background: var(--d700);
    border: none;
    border-radius: var(--base-radius);
    margin-top: -1px;
    z-index: 9999;
    padding: 10px 0;
}
.ui-menu-item { margin: 0; font-size: 14px}
.ui-menu-item-wrapper{
    padding: 10px 20px;
    color: var(--white);
    border-bottom: 1px solid var(--g100);
}
.ui-menu-item:last-child .ui-menu-item-wrapper{
    border-bottom: none;
}
.ui-menu-item a { display: block; line-height: 24px; margin: 0; padding: 0 5px; white-space: nowrap; overflow: hidden; text-decoration: none; border-bottom: 1px solid #e4e4e4; }
.ui-state-active,
.ui-widget-content .ui-state-active { background: var(--d800); cursor: pointer }
.list_city {
    line-height: 24px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e4e4e4;
}
.list_city-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 1em;
    padding-bottom: 5px;
}
.list_city-formated {
    font-size: 12px;
    line-height: 14px;
}
/*=====================================================================*/


/*============= Профиль ===============*/
.prof_content { display: inline-block; width: 100% }
.prof_speedbar {background: #FFF; line-height: 30px; padding: 0 10px}
.ava_set { position:relative; display:inline-block}
.ava_set i { position:absolute; top: 50%; left: 50%; transform:translateX(-50%) translateY(-50%); -webkit-transform: translate(-50%, -50%); color: #FFF; font-size: 19px; opacity: 0.7; background:#000; padding: 8px}
.ava_set:active { opacity: 0.7}
.settings { display: flex; width: 100%; justify-content: space-between }
.settings .c1,
.settings .c2 { background: #FFF; width: 100%; display: block; padding: 10px; box-sizing: border-box; line-height: 36px; }
.settings .c1 td,
.settings .c2 td {padding:0 5px}

.profile_full_cover{display: flex;flex-direction: column-reverse}
.profile_full_cover-info{display: flex}
.profile_foto-overlay{width: 110px;flex: 0 0 auto;padding: 0 20px;box-sizing: content-box}

.profile_info { text-align: center }
.profile_foto .foto_edit_box {width: 100%; height: 100%; background: rgba(0,0,0,0.5); position: absolute; bottom: 0; left: 0; opacity: 0}
.profile_foto:hover .foto_edit_box { bottom: 0; opacity: 1}
.profile_foto .foto_edit_box li { color: #ababab; font-size: 13px; cursor: pointer}
.profile_foto .foto_edit_box li:hover { color: #FFF}
.profile_foto img {max-width: 100%; display: block; border-radius: 50%}

.prof__top{display: flex; flex-direction: row}
.p_username{
    padding: 16px 0 0 0;
    width: 100%;
    flex: 1 1 auto;
    color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    align-content: flex-end;
}
.prof__top-photo {width: 150px; flex: 0 0 auto}
.prof__top-onlinestatus{flex: 1 0 auto}

.profile_header{
    margin-bottom: 15px;
}
.profile_full_cover{
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: var(--base-radius) 10px 0 0;
}
.profile_full_cover:before{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    border-radius: var(--base-radius) 10px 0 0;
}

.profile_content{
    display: flex;
    gap: 20px;
}
.profile_blog{
    flex: 1 1 auto;
}
.profile_sidebar{
    width: 260px;
    flex: 0 0 auto;
}


/*====== profile settings ======*/
.checkboxLine{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountSettings-coverBlock{
    margin: -1em -2em 4em -2em;
    width: calc(100% + 4em);
    position: relative;
    padding-top: 40%;
    height: 0;
}

.accountSettings-cover{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 0;
}

.accountSettings-avatar{
    width: 112px;
    height: 112px;
    border-radius: 50%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 6px;
    background: var(--d700);
    z-index: 9;
}

.accountSettings-avatar > img {
    border-radius: 50%;
}

.accountSettings-cover > img{
    left: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
}

.accountSettings,
.emailSettings,
.changePassword {
    padding: 1em 2em;
}

.notificationSettings,
.privacySettings{
    padding: 1em;
}

.notificationSettings ul li,
.privacySettings ul li{
    padding: 6px 12px;
    border-radius: var(--base-radius);
    overflow: hidden;
}

.notificationSettings ul li:hover,
.privacySettings ul li:hover {
    background: var(--d700);
}

.events_notifications_settings{
    padding: 30px;
}

.events_notifications_settings .categoryItem{
    padding: 6px 12px;
    border-radius: var(--base-radius);
    line-height: 32px;
}
.events_notifications_settings .categoryItem:hover{
    background: var(--d700);
}

.showMapForSelectArea{
    background: var(--d800);
    border-radius: 10px;
    padding: 20px;
    user-select: none;
}

.showMapForSelectArea:hover{
    background: var(--d700);
}

.accountSettings .icon-photo {
    z-index: 1;
    top: 14px;
    right: 14px;
    position: absolute;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountSettings-avatar .icon-photo{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.settingTab{
    display: none;
}
.settingTab.active{
    display: block;
}

.settingsContainer-tabsLink{
    margin-top: 1em;
}

.settingsContainer-tabsLink > li{
    line-height: 3em;
    padding: 0 1em;
    display: flex;
    align-items: center;
    user-select: none;
}

.settingsContainer-tabsLink > li i{
    margin-right: 0.725em;
    font-size: 1.4em;
}

.settingsContainer-tabsLink > li.active,
.settingsContainer-tabsLink > li:hover{
    background: var(--d950);
    color: #FFF;
}

.settingsContainer-tabs{
    flex: 1 1 auto;
}
.settingsContainer-sidebar{
    flex: 0 0 260px;
}

.settingsShowSidebarBtn{
    display: none;
}

.emailConfirmBlock{
    position: relative;
}
.emailConfirmBlock > i.icon-approve,
.emailConfirmBlock > i.icon-notification{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #FFF;
    font-size: 24px;
}
.emailConfirmBlock > i.icon-approve{
    display: none;
    color: var(--b800)
}
.emailConfirmBlock > i.icon-notification{
    display: block;
    color: var(--r1100);
}

.emailConfirmBlock.confirmed > i.icon-approve{
    display: block;
}
.emailConfirmBlock.confirmed > i.icon-notification{
    display: none;
}



/*============ Подписки ==========*/
.shortSubscription{
    background: var(--d800);
    border-radius: var(--base-radius);
    padding: 14px 20px;
}

.shortSubscription-content{
    display: flex;
    flex-direction: row;
}
.shortSubscription-content__card{
    flex: 0 0 33.333%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.billingList{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.billingList-item{
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 1em;
    padding: 10px;
    border-radius: 8px;
    user-select: none;
}

.billingList-item.hover{
    color: var(--g500);
    font-size: 14px;
}
.billingList-item.hover:hover{
    background: var(--d700);
    color: var(--white);
}
.billingList-item > div{
    flex: 1;
}

.billingList-item > div.top,
.billingList-item > div.bottom{
    flex: 2;
    display: flex;
    gap: 1em;
}

.billingList-item > div.top > div,
.billingList-item > div.bottom > div{
    flex: 1;
}

.billingList-item > div.bottom > div.view{
    flex: 0 0 30px;
    font-size: 18px;
}

.billingList-item .status > span{
    background: var(--d600);
    border-radius: 2px;
    color: white;
    padding: 2px 8px;
    border: 1px solid var(--d540)
}
.billingList-item .status span.pending{
    background: var(--y700);
    border-color: #ffb22d;
}
.billingList-item .status span.failed{
    background: var(--r1000);
    border-color: #ff4040;
}
.billingList-item .status span.paid{
    background: var(--green800);
    border-color: #6bdd6b;
}


/*===добавлено к старому, новое===*/
.profile_full_info {background: var(--white);box-shadow: 0 5px 23px rgba(0,0,0,.11);border-radius: 0 0 10px 10px; padding: 55px 0 20px 0}

.p_actbuttons, .profile_about, .p_follow {
    padding: 0 30px 20px 30px;
}
.p_actbuttons{
    display: flex;
    align-items: end;
}
.p_actbuttons > .btn {
    font-size: 14px;
    line-height: 34px;
    padding: 0 28px;
    margin-right: 20px;
}
.p_actbuttons > .btn.editProfile{
    cursor: pointer;
    color: var(--g100);
}
.p_actbuttons > .btn.editProfile:hover{
    background: var(--d600);
    color: var(--g100)
}
.profile_foto {
    width: 110px;
    height: 110px;
    position: absolute;
    bottom: -40px;
    border-radius: 50%;
    border: 4px solid #FFF;
    background: #3a3a3a;
}
.prof_username {
    position: relative;
    font-size: 22px;
}
.prof_address {font-size: 13px;padding: 7px 0; z-index: 1;}
.prof_last_visited {
    position: absolute;
    bottom: -30px;
    line-height: 30px;
    color: #4e4e4e;
    font-size: 14px;
}
.prof_rides_on {color: #545454; font-size: 13px}
.prof_rides_on a {color: #161616}
.listmenu {background: #fcfcfc;padding: 0 10px;text-align: center;border-radius: 0 0 3px 3px;border-bottom: 1px solid #dfe7e9;margin: 0;box-shadow: 0 2px 3px #f3f3f3 inset;}
.listmenu__item {display: inline-block;padding: 0 7px;line-height: 24px;color: #66777f;position: relative}
.listmenu__item-inner {padding: 5px 5px 5px 0}
.listmenu__item:hover, .listmenu__item.active {color: #607681}
.listmenu__item:hover > .listmenu__item-inner, .listmenu__item.active > .listmenu__item-inner {color: #40bbeb}
.profile_func {
    padding: 8px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-end;
}
.profile_func > div {
    margin-left: 5px;
    margin-top: 5px;
    width: 100%;
}

.online_status {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 12px;
    background: #02d038;
    border: 3px solid #FFF;
    top: 80px;
    left: 80px;
}

.online_status.user_online {background: #35d800}

.user_social_links {float:left; list-style:none; margin: 8px 0 0 0}

/*========================================*/
.radio_site {display: flex; user-select: none;}
.radio_site.break{
    gap: 1em
}
.radio_site > .radio_site__item > input[type=radio] { display: none }
.radio_site > .radio_site__item > input[type=radio]+label {color: #212121; padding: 0 20px; line-height: 40px; text-align: center; border: 1px solid var(--g700); margin-left: -1px; display: block}

.radio_site > .radio_site__item > input[type=radio]+label:hover { background: #838486; color: #FFF }
.radio_site > .radio_site__item:first-child > input[type=radio] + label { border-radius: 8px 0 0 8px; margin-left: 0}
.radio_site > .radio_site__item:last-child > input[type=radio] + label {border-radius: 0 8px 8px 0}
.radio_site > .radio_site__item > input[type=radio]:checked + label { background: var(--g700); color: #FFF; box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset}

.radio_site.min > .radio_site__item > input[type=radio]+label {padding: 0 14px; line-height: 26px; font-size: 14px}
.radio_site.min > .radio_site__item:first-child > input[type=radio] + label { border-radius: 6px 0 0 6px}
.radio_site.min > .radio_site__item:last-child > input[type=radio] + label {border-radius: 0 6px 6px 0}

.se_event__bottom .radio_site__item{
    flex: 1 1 auto;
}

.se_event__bottom input[type=radio]+label{
    border-radius: 8px !important;
}
.full_radio_site{
    width: 100%;
}
.full_radio_site .radio_site > .radio_site__item{
    flex: 1 0 auto;
}

.full_event__radio_site .radio_site > .radio_site__item:first-child > input[type=radio] + label{
    border-radius: 20px 0 0 20px;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    border-color: var(--b800);
}
.full_event__radio_site .radio_site > .radio_site__item:last-child > input[type=radio] + label{
    border-radius: 0 20px 20px 0;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-color: var(--b800);
}

.se_event__bottom .radio_site{
    gap: 1em
}

/*======= радио в виде кнопок вроде репортов =======*/
.btns_select > label{
    display: inline-block;
    margin: 3px 2px;
    border-radius: 20px;
    padding: 3px 10px;
    background: var(--g100);
    user-select: none;
}
.btns_select > label:hover {
    background: var(--b200);
}
.btns_select > input:checked+label{
    background: var(--b800);
    color: var(--white);
}
.btns_select > input{
    display: none;
}


/*Загрузка файлов*/
.file_upload_box { width: 100%; min-height: 150px; position: relative; border-radius: 5px; border: 1px dashed #CCC; color: #d9d9d9; }
.file_upload_box .upload_info { position: absolute; top: 0; left: 0; width: 100%; height: 80px; padding: 35px 0; display: inline-block; }
.file_upload_box #filesUpload { z-index: 9999 }
.upload_info div { line-height: 40px; font-weight: bold; font-size: 18px; z-index: 1; }
.file_upload_box:hover { background: #f3f3f3; cursor: pointer; box-shadow: 0 0 5px #e4e4e4 INSET; border: 1px solid #FFF; color: #688da2; text-shadow: 1px 1px 0 #FFF }
#filesUpload, .filesUpload, .videoUpload { position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 992; opacity: 0 }
.file_upload_btn {
    display: inline-block;
    position: relative;
    border-radius: var(--base-radius-16);
    background: var(--d800) url(/template/svg/add_photo.svg) no-repeat center;
    border: 1px dashed var(--d540);
    text-indent: -9999px;
    overflow: hidden;
    width: 20%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.file_upload_btn:hover {border-color: var(--b500)}

/*======== BIKES =================================================*/
.bikes_menu {
    line-height: 30px;
    text-align: center;
}
.bikes_menu > .hide {
    display: none;
}
.bikes_menu a {
    display: inline-block;
    background: var(--g100);
    margin: 3px 5px;
    border-radius: 20px;
    padding: 0 15px;
}

.bikes_menu a:hover,
.bikes_menu a.active,
.bikes_menu a.actlink {color: #FFF; background: var(--b800)}

.show_bikes_menu {
    line-height: 50px;
    text-align: center;
    border-top: 1px solid #ececec;
    cursor: default;
}
.show_bikes_menu:hover{
    background: #f7f7f7;
}

.bikes_menu .hide_menu { display: none }
.bike_menu_show { line-height: 30px; text-align: center; cursor: pointer; color: #758998 }
.bike_menu_show:hover { background: #ecf0f3 }
.bike_prev { margin-bottom: 10px }
.bike_prev__image { overflow: hidden; position: relative }
.bike_prev__img { max-width: 100% }
.box_favorite { position: absolute; top: 0; right: 0; color: #FFF; opacity: 0.55; z-index: 1; padding: 7px; font-size: 1.2em }
.bike_prev__name { line-height: 30px; text-align: center; color: #3c3c3c; white-space: nowrap; overflow: hidden; text-overflow: ellipsis}
.bike_prev:hover .bike_prev__img { transform: scale(1.1); -webkit-transform: scale(1.1) }
.short_bikes { display: flex; flex-wrap: wrap; justify-content: flex-start}
.short_bikes li { width: 33.3%; padding: 5px; display: inline-block; box-sizing: border-box }
.short_for_main li {width: 20%}
.short_bike { box-sizing: border-box; margin: 0 0 10px 0; border-radius: 7px; overflow: hidden; position: relative; box-shadow: 0 5px 23px rgba(0,0,0,.11); background: var(--white)}
.short_bike .short_image { width: 100%; position: relative; overflow: hidden }
.short_bike .short_image img { width: 100% }
.short_bike_title {text-align: left; line-height: 20px; padding: 10px; box-sizing: border-box }
.short_bike_title span { overflow: hidden; display: block; font-size: 14px}
.short_bike_title .icon-location {font-size: 12px}
.short_bike__name { font-size: 18px !important; margin-bottom: 5px; font-weight: bold }

.short_bike:hover .short_image img {opacity: 0.8; transform: scale(1.1); -webkit-transform: scale(1.1) }


.short_bike__bottom{
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    position: relative;
    flex: 1 1 auto;
}
.short_bike__bottom > *{
    flex: 1 1 auto;
}
.short_bike__user{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 10px 10px;
    align-items: center;
    flex: 0 0 auto;
}
.short_bike__user > .photo{
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: hidden;
}
.short_bike__user > .photo > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.short_bike__user > .name{
    flex: 1 1 auto;
    padding-left: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #283f4f;
}
.short_bike__user:hover .name {
    color: #0086d4;
}


.favorite.active{
    color: var(--b800);
}



.bikes-items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -20px;
}
.bikes-items .short_bike{
    width: calc(33% - 20px);
    flex: 0 1 auto;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.bikelogs_search-content {
    flex: 1 1 auto;
}

.bikes_recbikelogs{
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
}
.bikes_recbikelogs > .head{
    font-size: 22px;
    font-weight: 500;
    color: #111;
    text-align: center;
}
.bikes_recbikelogs > .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 1rem;
}

.rect_bl {
    border-radius: 0.75rem;
    padding: 10px;
    background: #FFF;
    box-shadow: 0 0 6px 1px rgba(137,158,165,0.17);
    display: block;
    position: relative;
}
.rect_bl-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    border-radius: 3px;
}
.rect_bl-img>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.rect_bl-wrap{
    margin-left: 75px;
    min-height: 60px;
    margin-bottom: 10px;
}
.rect_bl-wrap, .rect_bl-btm{
    color: #111;
}
.rect_bl-title{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;
}

.rect_bl-bike {
    font-size: 12px;
    padding: 5px 0;
    color: #777;
}

.rect_bl-btm {
    font-size: 15px;
    display: flex;
    flex-direction: row;
}

.rect_bl-btm > .lks{
    width: 60px;
    text-align: center;
    color: var(--green800);
}
.rect_bl-btm > .cmts{
    padding-left: 15px;
}
.rect_bl-btm i{
    margin-right: 5px;
}

.bikes_recbikelogs > .items > .rect_ovrl{
    width: calc(33.3% - 1rem);
}


.bike_followers-head { display: flex; flex-direction: row; align-items: center}
.bf-head-title-block { display: flex; flex-direction: column}
.bf-head-title-block { flex: 1 1 auto}
.bike_followers-head .bike_title{ font-weight: normal; font-size: 0.9em}
.bf-head-title-block {
    line-height: 22px;
    padding: 10px;
}
.bf-head-title-bollowbtn{
    padding: 0 20px;
}
.bf-head-arrow {
    padding: 0 10px;
}
.bf-head-arrow > .icon{
    width: 20px; height: 20px; fill: #305164; vertical-align: middle;
}

/*.owner { display: table }
.owner__foto,
.owner__details { display: table-cell; vertical-align: top }
.owner__foto { width: 50px; height: 50px; overflow: hidden; position: relative }
.owner__foto>img { height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%) }
.owner__details { padding-left: 10px; color: #000 }
.owner__details span { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; margin-bottom: 7px }
.owner_link:hover .listmenu__arrow { right: 5px }*/
/*=================================================================*/


/*==== Просмотр мотоцикла =====*/
.fbike_owner { display: flex; flex-direction: row; padding: 25px}
.fbike_owner > a{ display: flex; color: #545454}
.fbike_owner-link { flex-direction: row; flex: 1 0 auto}
.fbike_owner-photo {width: 50px; height: 50px; border-radius: 25px; overflow: hidden}
.fbike_owner-photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fbike_owner-link:hover .fbike_owner-photo > img{
    transform: scale(1.1);
}
.fbike_owner-info {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fbike_owner-name { font-weight: 500; font-size: 16px}
.fbike_owner-address { font-size: 0.8em; padding-top: 5px}
.fbike_owner-bikes{
    align-items: center;
    align-content: center;
    padding-left: 20px;
}
.fbike_counters {
    border-style: solid;
    border-width: 1px 0;
    border-color: #ececec;
}
.fbike_descr {
    line-height: 1.6em;
    padding: 30px 20px;
}
.fbike_bottom{
    padding: 15px; line-height: 26px; display: flex;
}

.fbike_bottom > .likes{
    flex: 1 0 auto
}
.follow{color: #FFF}
.follow.active{background: #545454}
/*=============================*/

/*======== COUNTERS ======*/
.counter_items{ display: flex; flex-direction: row; padding: 10px; justify-content: center; background: var(--g100)}
.counter_item{ flex: 0 0 auto; color: #505050; text-align: center; padding: 10px 20px; border-radius: 50px}
.counter_item:hover { color: #216f9c}
.counter_item-count{ font-size: 30px}
.counter_item-text{ font-size: 13px}
.counter_item.actlink {color: var(--b800)}
/*=========================*/

/*======== FOLLOWING TABS =====*/
.boundContentTabs{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    line-height: 50px;
    height: 50px;
    border-bottom: 1px solid #f1f1f1;
}
.boundContentTabs > a{
    color: var(--g700);
    flex: 1 1 auto;
    text-align: center;
    padding-top: 3px;
    position: relative;
}
.boundContentTabs > a.active, .boundContentTabs > a:hover{
    color: #009dcc;

}
.boundContentTabs > a.active::after, .boundContentTabs > a:hover::after {
    content: '';
    display: block;
    position: absolute;
    width: 90%;
    height: 2px;
    background: var(--b800);
    left: 50%;
    transform: translateX(-50%);
    bottom: 1px;
}
/*=============================*/


/*======== HEADER ================================================*/
.header { width: 100%; height: 60px;
    padding: 10px;
    position: relative;
    background: #2b495a;
}
.header_transparent{
    background: transparent;
}
.header_container {
    padding-right: 160px;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1100px;
    position: relative;
}

.header_full_page_container {
    padding-right: 160px;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 1100px;
    position: relative;
}

.prof { display: inline-block; position: relative; color: #FFF}
.header__menu{
    width: 100%;
    text-align: center;
}
.header__menu a {
    color: #FFF;
    padding: 9px 0;
    margin: 0 10px;
    border-bottom: 2px solid transparent;
    display: inline-block
}
.header__menu a:hover,
.header__menu a.actlink {border-bottom-color: #FFF}
.header__menu sup, .slidemenu sup{color: #16c8e6;font-size: 11px}
.full { width: 1180px; max-width: 100%; margin: auto; padding: 0 10px}

.navbar_header {float: left}
.logo img{height: 40px}
.logo{display: block; width: 50px}

.header_btns{
    position: absolute;
    top: 1px;
    right: 0;
    display: flex;
    align-items: center;
}

.prof+.header_btns{display: none}

.login_btn, .use_app{
    display: inline-block;
    line-height: 24px;
    background: #ffffff61;
    padding: 7px 35px;
    color: #FFF;
    border-radius: 50px;
    letter-spacing: 1.5px;
}
.use_app {margin-right: 10px}
.login_btn:hover, .use_app:hover{
    background: #FFF; color: #2b485a
}

.notify_profile{
    padding: 10px 6px;
    float: right;
    height: 50px;
}

.header--scroll{
    -webkit-transition: background .35s ease;
    -o-transition: background .35s ease;
    transition: background .35s ease;
}

/*.header--scroll[data-visible="0"]{
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.2)),color-stop(60%,rgba(0,0,0,.1)),to(transparent));
    background: -webkit-linear-gradient(top,rgba(0,0,0,.2) 0,rgba(0,0,0,.1) 60%,transparent 100%);
    background: -o-linear-gradient(top,rgba(0,0,0,.2) 0,rgba(0,0,0,.1) 60%,transparent 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,.2) 0,rgba(0,0,0,.1) 60%,transparent 100%);
}*/

/*========= Уведомления в профиле ===========*/
.notify_block {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
}

.notify_bell,
.notify_pm,
.notify_friends {
    width: 40px;
    height: 40px;
    color: var(--g100);
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count_notify {
    border-radius: 12px;
    font-size: 9px;
    width: 16px;
    line-height: 16px;
    top: 0;
    right: 3px;
    background-color: var(--r1000);
    text-align: center;
    position: absolute;
    cursor: default;
    word-break: normal;
}

.bottom_menu__notifications{
    position:relative;
}
.bottom_menu__notifications .count_notify {
    right: 50%;
    transform: translate(100%, 4px);
}


.notify_block:hover .notify_exit { background-position-x: -24px }
.notify_block.active { background-color: #3e4f5e; }

.notify_wrap {position: absolute; background: #fff; border: 1px solid #c5d0db; border-radius: 3px; overflow: hidden; display: block; top: 150%; line-height: 30px; right: 0; opacity: 0; visibility: hidden; min-width: 500px; box-shadow: 0 1px 3px rgba(0,0,0,.1); z-index: 8888 }
.notify_wrap:before { content: ''; width: 0; height: 0;
    top: -10px; right: 14px;
    position: absolute;
    border: 5px solid transparent;
    border-bottom-color: #ffffff;
}
.notify_block.active .notify_wrap { display: block; top: 100%; opacity: 1; visibility: visible }
.notify_wrap_head { height: 40px; background: #f5f5f5; border-bottom: 1px solid #e7e8ec; padding: 5px 50px 5px 20px; color: #000; font-weight: normal; font-size: 15px; position: relative }
.notify_wrap_head__btn_icon { position: absolute; top: 5px; right: 5px; font-size: 20px; width: 30px; height: 30px; text-align: center }
.notify_wrap_head__btn_icon a { color: #C7C7C7 !important }
.notify_wrap_head__btn_icon a:hover { color: #444 !important }
.notify_wrap_middle { max-height: 240px; overflow-y: scroll; background: #FFF; color: #273e4e; }
.feed_events li { padding: 5px 10px 5px 50px; font-size: 13px; line-height: 20px; border-bottom: 1px solid #e7e8ec; word-break: normal; position: relative; font-weight: normal; }
.feed_events li a { color: #2578c1; font-weight: bold }
.feed_events li a:hover { color: #0AAFEB }
.feed_events li:last-child { border: 0 }
.feed_events li .feed_date { color: #737373; font-weight: normal; font-size: 11px; display: block; line-height: 11px; padding-top: 3px; }
.feed_events li i[class^="icon-"] { font-size: 0.8em; color: #647d8a }
.notice__foto { width: 30px; position: absolute; left: 10px; top: 8px; border-radius: 15px}
.notify_wrap_bottom { height: 30px; background: #fafbfc; border-top: 1px solid #e7e8ec; text-align: center; padding: 0 10px; color: #000; font-weight: normal; font-size: 13px }
.notify_wrap_bottom:hover { background: #edf1f5; cursor: pointer }
.not_notify { border: 1px dashed #CCC; text-align: center; border-radius: 4px; line-height: 60px; font-weight: normal; background: var(--white); color: var(--g400)}
.box_wht__content-min .not_notify{
    margin: 10px 14px;
}
.notify_wrap_middle .not_notify {margin: 10px}
.notify_wrap .request_friend {min-height: 60px; padding: 10px 10px 10px 70px; font-weight: normal; line-height: 16px; color: #000; font-size: 13px; position: relative; border-bottom: 1px solid #ececec}
.notify_wrap .request_friend img {position: absolute; width: 50px; border-radius: 30px; left: 10px; top: 10px}
.notify_wrap .request_friend:last-child {border: 0}
.notify_wrap .request_friend .notice_btns {padding: 5px 0}
.notify_wrap .request_friend .notice_btns .btn {margin-right: 5px}
.notify_wrap_bottom a {color: #273e4e !important}
/*===========================================*/

/*================ МОТОЦИКЛЫ ==============*/
.prev_list {display: flex; text-align: center; width: 100%; position: absolute; bottom: 0; padding: 15px; background: rgba(0, 0, 0, 0.28); opacity: 0}
.prev_list li {display: inline-block; max-width: 80px; padding: 0 5px}
.prev_list li .prev_list__preview {width: 100%; border-radius: 3px}
.prev_list li:hover > .prev_list__preview {transform: scale(1.3); -webkit-transform: scale(1.3)}
.show_prev_list:hover .prev_list {opacity: 1}
.title_descr { margin-bottom: 10px}
.title_descr h3 { color: #505050}
.pagebox__descr i[class^=icon-] {font-size: 0.8em}
/*=========================================*/

.prof { padding-right: 20px; top: 5px; right: 0; position: absolute; z-index: 1; }
.prof__title { font-weight: bold; padding: 0 10px; display: block; box-sizing: border-box; border-radius: 4px 4px 0 0; height: 50px; word-wrap: normal; word-break: break-all; line-height: 50px; float: left }
.prof__title i.icon-notification { color: #000; font-size: 1.2em }
.prof > a { text-decoration: none; color: #247ece; line-height: 15px; display: inline-block; }
.prof__login { float: right; font-weight: bold; position: relative; cursor: pointer; height: 50px; line-height: 50px}
.prof__login .dropmenu__btn:before { content: ''; display: block; width: 0; height: 0;
    border: 5px solid transparent;
    border-top-color: rgba(255, 255, 255, 0.44);
    position: absolute; top: 50%; margin-top: -2px; right: 7px }
.prof__login:hover { background: rgba(16, 16, 16, 0.07); }
.prof__login.active_menu { background: rgba(16, 16, 16, 0.17) }
.prof__login.active_menu:before { border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent; }

.prof__login .icons_menu {font-size: 15px;  vertical-align: text-bottom; margin-right: 7px}

.prof__ava { float: left; width: 50px; padding: 7px; display: inline-block; position:relative}
.prof__ava img { max-width: 100%; border-radius: 25px }
.prof__rat {position: absolute;top: 7px;left: 7px;line-height: 36px;width: 36px;font-size: 9px;text-align: center;background: rgba(0, 0, 0, 0.4);border-radius: 18px}
/*=====================================================================*/


/*======= SIDEBAR ==============*/

.sidebar__usermenu, .sidebar__fullmenu, .sidebar__submenu{
    padding: 10px;
    margin-bottom: 20px;
}
.sidebar__usermenu svg.icon, .sidebar__fullmenu svg.icon, .sidebar__submenu svg.icon {
    width: 28px;
    height: 22px;
    vertical-align: text-bottom;
    fill: var(--g700);
    margin-right: 10px;
}
.sidebar__usermenu-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.sidebar__usermenu-user.sign-in{
    padding-bottom: 0;
}
.sidebar__usermenu-user.sign-in > i{
    margin: 5px;
    font-size: 1.2em;
}
.sidebar__usermenu-avatar{
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
}
.sidebar__usermenu-avatar>img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.sidebar__usermenu-username{
    flex: 1 0 auto;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    max-width: 100%;
}

.sidebar__usermenu-user > .dropdown_button{
    padding: 10px;
    border-radius: var(--base-radius);
    margin-bottom: 10px;
}

.sidebar__usermenu-listmenu, .sidebar__fullmenu-listmenu{
    line-height: 34px;
    font-weight: 500;
}
.sidebar__usermenu-listmenu > li,
.sidebar__fullmenu-listmenu > li{
    border-radius: 5px;
    display: flex;
    width: 100%;
}
.sidebar__usermenu-listmenu > li > a:first-child,
.sidebar__fullmenu-listmenu > li > a:first-child{
    flex: 1 1 auto;
}

.sidebar__usermenu-listmenu > li > a.action,
.sidebar__fullmenu-listmenu > li > a.action{
    flex: 0 0 40px;
    padding: 0;
}

.sidebar__usermenu-listmenu i[class^=icon-], .sidebar__fullmenu-listmenu i[class^=icon-], .sidebar__submenu i[class^=icon-]{
    font-size: 1.4em;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
}
.sidebar__submenu i[class^=icon-]{
    left: unset;
    right: 0;
    font-size: 1.2em;
}

.sidebar__usermenu-listmenu a, .sidebar__fullmenu-listmenu a{
    color: var(--g700);
    display: block;
    padding: 10px 14px 10px 50px;
    border-radius: 4px;
    position: relative;
    line-height: 20px;
    font-weight: normal;
}

.sidebar__usermenu-listmenu a:hover, .sidebar__fullmenu-listmenu a:hover, .sidebar__fullmenu-listmenu .actlink, .sidebar__usermenu-listmenu .actlink{
    color: var(--b800);
}

.sidebar__fullmenu-info {
    padding: 20px 14px 20px 14px;
    font-size: 0.8em;
    text-align: center;
    color: var(--g700);
}
.sidebar__fullmenu-info>a{
    color: var(--g700)
}
.sidebar__fullmenu-info>a:hover{
    color: #0AAFEB;
}

.dark_mode {
    float: right;
    line-height: 40px;
    position: relative;
    padding-right: 30px;
}
.dark_mode:hover{
    cursor: pointer;
    color: var(--b800);
}

/*======= Мои мотоциклы ========*/
.bike {display: table; width: 100%; color: #393939}
.bike__preview {width: 100px; margin-right: 10px; display: table-cell; vertical-align: top; position: relative; border-radius: var(--base-radius); overflow: hidden}
.bike_count_photos { position: absolute; top: 0; left: 0; font-weight: bold; color: #FFF; padding: 5px; width: 100%; text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.19); background-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.5)) }
.bike__descr { display: table-cell; vertical-align: top; padding-left: 10px; position: relative }
.bike__descr a { color: #444 }
.bike__descr .listmenu__arrow { transform: scale(1.4); -webkit-transform: scale(1.4)}
.bike__func { display: table-cell; width: 160px; vertical-align: middle; text-align: center; border-left: 1px solid #f3f3f3 }
.bike_func { margin-top: 10px; }
.bike_func i { font-size: 0.8em }
.bikelink:hover .bike__preview {opacity: 0.8}
.bikelink:hover .listmenu__arrow {right: 5px}
.bike_edit,
.bike_delete {color: #4c4c4c; padding: 2px 10px; margin-right: 5px; cursor: pointer}
.bike_edit:hover,
.bike_delete:hover {opacity: 1}
.bike_delete:hover {color: #E04143}
.bike__descr-model {font-weight: 500; margin-bottom: 10px; font-size: 1.1em}
.bike__descr-year1, .bike__descr-year2 {
    font-size:14px;
}
.model .min {font-size: 0.8em; color: #7b7b7b; font-weight: normal}
.switch_box {float: right}
.switch_box > span,
.switch_box > .switch { display: inline-block; vertical-align: middle; margin-left: 5px }
.switch input[id^=switch] { display: none; }
.switch label { display: inline-block; width: 38px; height: 18px; border-radius: 19px; position: relative; background: #c5c5c5; cursor: pointer }
.switch.color label { background: #72b1e8 }
.switch label:before { content: ''; display: block; width: 15px; height: 15px; border-radius: 50%; left: 2px; top: 2px; background: #FFF !important; position: absolute }
.switch input[id^=switch]:checked + label { background: #37b8eb }
.switch input[id^=switch]:checked + label:before { left: 100%; margin-left: -17px }
.switch label:hover > :before { background: #FFF !important}

/*======= Профиль ========*/

.full_friends{
    box-shadow: 0 5px 23px rgba(0,0,0,.11);
    border-radius: 15px;
}
.full_friends-content{
    padding: 10px 0;
}

.userlist{
    display: flex;
    flex-direction: column;
}
.userlist__item{
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    font-size: 16px;
}
.modeObjects_popover-section-list .userlist__item:hover {
    background: #f9f9f9;
}
.modeObjects:hover{
    background: #f9f9f9;
}
.userlist__item-actions {
    display: flex;
    align-items: center;
    margin-left: 1em;
}
.userlist__item-actions .action{
    padding: 1em;
}
.userlist__item-actions .action:hover{
    color: var(--b800);
}
.userlist__item-name{
    font-weight: normal;
}
.userlist__item-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
}

.userlist__item.min {
    font-size: 14px;
    padding: 10px;
}

.userlist__item.min .userlist__item-photo {
    width: 34px;
    height: 34px;
    flex: 0 0 auto;
}

.whatNew .userlist__item.min {
    font-size: 14px;
    padding: 10px;
}
.whatNew .userlist__item.min .userlist__item-photo {
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
}

.userlist__item-photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.userlist__item-info{
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--g700);
}

.userlist__item-address {
    font-size: 0.8em;
    padding-top: 5px;
    opacity: 0.7;
}

.userBaseShort-photo img{
    border-radius: 50%;
    width: 3em;
}

.userBaseShort-data .name{
    font-size: 1em;
}
.userBaseShort-data .rides{
    font-size: 0.86em;
    cursor: pointer;
}

.rides > span[data-hint_load]:hover{
    color: var(--b800)
}


.fullRouteContainer, .fullNewsContainer {
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.fullRouteContainer-head{
    position: relative;
}

.fullRouteImagesContainer{
    width: 100%;
    border-radius: 1em;
    overflow: hidden;
}
.fullRouteImagesContainer .item{
    width: 100%;
    padding-top: 50%;
    position: relative;
    display: block;
    z-index: 1;
    cursor: pointer;
}
.fullRouteImagesContainer .item img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.fullRouteImagesContainer .item i{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0 0 6px rgba(0,0,0,0.45);
    z-index: 101;
    color: white;
    font-size: 10px;
    opacity: 0;
}

.fullRouteImagesContainer .item:hover i{
    opacity: 0.6;
    font-size: 40px;
}

.fullRouteImagesContainer .owl-dots{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.fullRouteContainer .owl-nav{
    margin-top: 0 !important;
    line-height: 40px;
}

.fullRouteContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
}

.fullRouteContent .legend{
    font-size: 20px;
    font-weight: 500;
}

.routeContent{
    flex: 1 1 70%;
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.routeSidebar{
    flex: 0 0 280px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.routeBlocksStats{
    display: flex;
}
.routeBlocksStats .item{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    padding: 6px 18px;
    border-right: 1px solid #6c6c6c;
}

.routePreviewHorizontal-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.routePreviewHorizontal-bottom_stats .routeBlocksStats .item{
    padding-bottom: 0;
    padding-top: 0;
}
.routePreviewHorizontal-bottom_stats .routeBlocksStats .item:first-child{
    padding-left: 0;
}
.routeBlocksStats .item:last-child{
    border-right: none;
}


.modeObjects{
    position: relative;
    max-width: 300px;
}


.modeObjects_seleted {
    border-radius: var(--base-radius);
    overflow: hidden;
    position: relative;
    padding-right: 50px;
}

.modeObjects_seleted:hover{
    background: var(--d800);
}

.modeObjects_seleted-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 999;
    transform-origin: center;
}

.modeObjects_popover{
    padding: 8px;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--d800);
    border-radius: var(--base-radius);
    z-index: 999;
    width: 100%;
}
.modeObjects.opened .modeObjects_popover{
    display: block;
}

.modeObjects.opened .modeObjects_seleted-icon{
    transform: translateY(-50%) rotateZ(180deg);
}

.modeObjects_popover-section-title {
    padding: 10px;
}

.modeObjects_popover .userlist__item {
    border-radius: 8px;
}



.prof_box { background: #FFF; border-radius: 2px; padding: 0 }

.prof_box .mesage img,
.link_dialog img,
.message_form img {width: 70px; border-radius: 50px; position: absolute; left: 10px}
.fr_head { background: #f7f7f7; border-bottom: 1px solid #e7e8ec; padding: 5px 12px; color: #273e4e; font-weight: normal; line-height: 40px }
.fr_head img {width: 30px; float:left; border-radius: 20px; margin: 5px 7px 0 0}

.prof_box .mesage,
.link_dialog { padding: 7px 0 7px 60px; position: relative; font-weight: normal; font-size: 13px; line-height: 18px; word-break: break-all}
.link_dialog { padding: 10px 0 10px 60px; border-bottom: 1px solid #efefef; }
.prof_box .mesage span a { font-size: 13px }
.prof_box .mesage img,
.link_dialog img,
.message_form img { width: 40px }
.prof_box .mesage.add { padding: 0 0 0 60px; min-height: 20px}
.mess_date { font-size: 0.9em; color: #CCC }
form.message_form { padding: 10px 10px 10px 60px; border-top: 1px solid #e7e8ec; position: relative }
form.message_form textarea { width: 100%; min-height: 70px; margin-bottom: 5px; border: 1px solid #e7e8ec; border-radius: 5px; resize: initial; padding: 5px 7px; box-sizing: border-box; font-family: inherit; }
form.message_form textarea:focus { border-color: #9dc6ea }

.link_dialog:hover { background: #f9f9f9 }
.link_dialog p { color: #595959 }
.link_dialog span { color: #2c4556 }
.count_no_read { position: absolute; top: 50%; right: 10px; height: 16px; min-width: 30px; text-align: center; line-height: 16px; margin-top: -8px; font-size: 0.8em; background: #28d046; color: #FFF; border-radius: 2px }
.messages_list { max-height: 350px; overflow: auto; padding: 10px 0 }
.mesage.not_read:before { width: 10px; height: 10px; content: ''; background: #28b6e0; position: absolute; right: 10px; top: 40%; border-radius: 50% }
/*=========================*/

/*===== ПОСТЫ ===========*/

.moz { display: flex; display: -webkit-flex; width: 100%}
.moz_two .moz__item { width: 50%; height: 220px}
.moz_two .moz__item:first-child { margin-right: 5px}
.moz_thr > .moz__item { width: 67%; height: 300px; margin-right: 5px}
.moz_thr > .moz-v { width: 33%; height: 300px; flex-direction: column; -webkit-flex-direction: column; flex-shrink: 0; -webkit-flex-shrink: 0}
.moz-v > .moz__item { height: 50%}
.moz-v > .moz__item:first-child { padding-bottom: 5px}
.moz_mny > .moz__item { width: 75%; height: 400px; margin-right: 5px}
.moz_mny > .moz-v { width: 25%; height: 400px; flex-direction: column; flex-shrink: 0; -webkit-flex-direction: column; -webkit-flex-shrink: 0; }
.moz_mny .moz-v > .moz__item { margin-bottom: 5px; position: relative}
.moz_mny .moz-v > .moz__item:last-child { margin-bottom: 0}
.moz__more {
    position: absolute;
    background: rgba(0,0,0,0.4);
    height: 100%;
    width: 100%;
    color: #FFF;
    backdrop-filter: blur(4px)
}
.moz__more > span { font-size: 35px; height: 40px; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%)}
.moz__item {background-size: cover; background-repeat: no-repeat;background-position: center}
.moz__item > img{ width: 100%; height: 100%; object-fit: cover}
.moz__item:hover {opacity: 0.9}

.video_full_preview{text-align: center; position: relative}
.video_preview_container{position: relative; overflow: hidden; background: #000}
.video_full_preview img{display:block; opacity: 0.8; max-width: 100%; margin: auto}

/*===== Ивенты на карте =====*/
.m_event{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px 20px;
    gap: 1rem;
    color: var(--g100);
    overflow-y: auto;
    max-height: 100%;
}
.m_event-routePoints{
    width: 100%;
}
.m_event-routePoints h4{
    margin-bottom: 1rem;
    text-align: center;
}
.m_event-top{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.m_event-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.m_event-head_date {
    font-size: 12px;
    flex: 0 0 auto;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}
.m_event-head_date > span:first-child {
    font-weight: bold;
    font-size: 18px;
    color: var(--r200);
    margin-right: 0.5em;
}

.m_event-head .icon-cross{
    font-size: 16px;
    padding: 5px;
}

.m_event-preview {
    position: relative;
}
.m_event-preview > .route_distance {
    position: absolute;
    background: var(--green800);
    border-radius: 5px;
    padding: 4px;
    font-size: 0.8rem;
    bottom: 6px;
    right: 6px;
    color: var(--white);
}
.m_event-preview .prev_img {
    background: #383838;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
}
.m_event-preview .prev_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 0 0 auto;
}
.m_event-preview .prev_img-full{
    display: none;
}
.m_event-mid {
    flex: 0 0 auto;
    width: 80px;
    text-align: center;
    padding: 10px 0;
}
.m_event-info{
    flex: 1 1 auto;
    line-height: 1.6em;
}
.m_event-info > .event_cat{
    opacity: 0.7;
}
.m_event-info > .event_name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 7px;
    word-break: break-word;
    color: var(--r200)
}
.m_event-info > .event_date{
    padding-bottom: 5px;
    font-size: 14px;
    color: var(--b800);
}
.m_event-info > .event_stat {
    font-size: 12px;
    padding-top: 7px;
    opacity: 0.7;
}

.sb_head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 60px;
    line-height: 60px;
    padding: 0 1.2rem;
}

.sb_content{
    overflow-y: auto;
    height: calc(100% - 60px);
    padding: 1.2rem;
}

.sb_title{
    font-weight: bold;
    font-size: 1.2em;
}

.sheet .draggable-line {
    position: relative;
}

.sheet .draggable-thumb {
    width: 70px;
    height: 6px;
    background: var(--d700);
    border-radius: 3px;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.sheet[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.m_route{
    padding: 1rem;
    max-height: 100%;
    overflow: auto;
}

.m_route-images{
    width: 100%;
}
.m_route-images .item{
    width: 100%;
    padding-top: 50%;
    position: relative;
    display: block;
    z-index: 1;
}
.m_route-images .item img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 0.25em;
    overflow: hidden;
}

.m_roadTypes,
.roadTypes-sqr{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.roadType_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    width: 60px;
}

.roadType_item-icon{
    width: 100%;
    aspect-ratio: 1/1;
    padding: 0.5rem;
    border-radius: 50%;
    background: var(--b800);
    display: flex;
    align-items: center;
    justify-content: center;

}
.roadType_item-icon img{
    width: 100%;
    height: 100%;
}

.roadType_item-name{
    font-weight: 500;
}

.roadTypes-sqr .roadType_item{
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    background: var(--d600);
    padding: 0 1rem;
    border-radius: 1rem;
    justify-content: center;
    gap: 0.25rem
}

.roadTypes-sqr .roadType_item-icon{
    background: none;
    aspect-ratio: none;
    height: 50%;
    padding: 0;
}
.roadTypes-sqr .roadType_item-name{
    font-weight: normal;
    color: var(--g400);
    font-size: 12px;
}

.roadTypes-sqr.min .roadType_item{
    width: 30px;
    height: 30px;
    gap: 0.1rem;
    padding: 0;
    background: none;
}
.roadTypes-sqr.min .roadType_item-icon{
    height: 100%;
}
.roadTypes-sqr.min .roadType_item-name{
    font-size: 10px;
    display: none;
}

.routeActions-item{
    padding: 10px;
    border-radius: var(--base-radius);
    user-select: none;
    width: 80px;
    cursor: pointer;
}
.routeActions-item > span:first-child{
    height: 1em;
}
.routeActions-item:hover{
    background: var(--d600);
}

.m_route-short_roadTypes{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    flex: 0 0 auto;
}
.m_route-short_roadTypes img{
    width: 26px;
    height: 26px;
}


.m_routeRate{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.m_routeRate_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rateStar{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}

.m_routeRate.min .rateStar{
    font-size: 16px;
}

.rateStar .star{
    color: var(--d600);
    padding: 0.125em 0.25em 0.125em 0;
}

.rateStar .star.active{
    color: var(--b800);
}

.editableRateStars .star:active{
    transform: scale(0.8);
}


.headerRiddingStats{
    overflow: visible;
    padding: 10px;
}

.changeRiddingStatsGroup .dropdown_button{
    color: var(--b600);
    cursor: default;
}

.changeRiddingStatsGroup .dropdown_button:hover{
    text-decoration: underline;
}

.changeRiddingStatsGroup.dropdown.active > .dropdown_menu{
    top: 0;
}

.statsContainer{
    position: relative;
}

.statsContainer .statActivity{
    padding: 10px
}

.activityRow, .usermapRow{
    display: flex;
    flex-direction: row;
}

.usermapRow{
    gap: 1em;
}

.activityCol{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    font-size: 14px;
    flex: 1 0 33.3333%;
    text-align: center;
    border-right: 1px solid var(--d700);
}

.activityCol:last-child{
    border: none
}

.statsContainer .statActivity h4{
    margin-bottom: 1em;
}

.statsContainer .statActivity .num{
    font-size: 1.8em;
}

.statsContainer.short .activityRow,
.statsContainer.short .usermapRow{
    flex-direction: column;
    gap: 0.5em;
}

.statsContainer.short .activityCol{
    flex-direction: row;
    border: none;
    justify-content: space-between;
}

.statsContainer.short .statActivity .num{
    font-size: 1em;
}

.usermap-link{
    cursor: default;
}
.usermap-link:hover{
    color: var(--b800)
}



.statChart{
    width: 100%;
    height: 140px;
}

.statChart canvas{
    height: 140px;
}


@keyframes slideFromRightAnimation {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}

@keyframes slideToRightAnimation {
    from {
        right: 0;
    }
    to {
        right: -100%;
    }
}

@keyframes slideFromBottomAnimation {
    from {
        bottom: -100%;
    }
    to {
        bottom: 0;
    }
}

@keyframes slideToBottomAnimation {
    from {
        bottom: 0;
    }
    to {
        bottom: -100%;
    }
}

/*===== ИВЕНТЫ НОВЫЕ =====*/
.closeSidebar {
    position: absolute;
    left: -20px;
    top: 50%;
    width: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--d900);
    border-radius: 5px 0 0 5px;
    border-style: solid;
    border-width: 1px 0 1px 1px;
    border-color: var(--d700);
    transform: translateY(-50%);
}
.events_sidebar{
    flex: 0 0 auto;
    width: 260px;
}
.mapSidebarWraper{
    position: absolute;
    width: 100%;
    height: 100%;
    user-select: none;
}
.mapSidebar{
    width: 340px;
    max-width: 100%;
    z-index: 8;
    right: -100%;
    animation: slideToRightAnimation 0.3s forwards;
    border-left: 1px solid var(--d700);
}
.mapSidebar.active{
    right: 0;
    animation: slideFromRightAnimation 0.3s forwards;
}
.mapSidebar_eventView{
    position: absolute;
    top: 0;
    height: 100%;
    background: var(--d900);
}

.m_route_charts{
    user-select: none;
    text-align: center;
}

.chartLablesBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8em;
    color: var(--d400);
    border-top: 1px solid var(--d700);
    padding-top: 0.5em;
}

.chartTabButtons {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5em;
    margin-top: 1em;
    padding: 6px;
    background: var(--d950);
    border-radius: 0.7em;
}

.chartTabButtons .item {
    background: transparent;
    padding: 6px 24px;
    cursor: default;
    border-radius: .5em;
}

.chartTabButtons .item:hover{
    background: var(--d700);
}

.chartTabButtons .item.active{
    background: var(--d700);
}

.s_event{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px 20px;
    color: var(--g700);
}
.s_event__article{
    position: relative;
}
.s_event:hover{
    color: unset;
}
.s_event-preview {
    width: 100%;
    position: relative;
}
.s_event-preview > .route_distance {
    position: absolute;
    background: var(--green800);
    border-radius: 5px;
    padding: 4px;
    font-size: 0.8rem;
    bottom: 10px;
    right: 10px;
    color: var(--white);
    z-index: 2;
}
.s_event-preview .prev_img {
    background: var(--g200);
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    padding-top: 50%;
}
.s_event-preview .prev_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex: 0 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.s_event-preview .prev_img-full{
    display: none;
}
.s_event-preview .dots_menu{
    z-index: 3;
}
.s_event-mid {
    flex: 0 0 auto;
    text-align: center;
}
.event-mid-cat > div{
    padding: 9px;
    width: 44px;
    height: 44px;
    margin: 12px auto 0 auto;
}
.s_event-info{
    padding: 0 20px 0 0;
    flex: 1 1 auto;
    line-height: 1.6em;
}
.s_event-info > .event_name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 7px;
    word-break: break-word;
}
.s_event-info > .event_date{
    padding-bottom: 5px;
    font-size: 14px;
}
.s_event-info > .event_loc {
    color: var(--b800);
}
.s_event-info > .event_stat {
    font-size: 12px;
    padding-top: 7px;
    opacity: 0.7;
}
.event-mid-date {
    text-align: center;
}
.event-mid-date > .day {
    font-size: 26px;
    line-height: 30px;
    padding-bottom: 5px;
    display: block;
}
.event-mid-date > .month {
    text-transform: uppercase;
    opacity: 0.7;
    font-size: 14px;
}
.event-mid-cat {
    padding-top: 14px;
}
.cat_icon {
    width: 30px;
    height: 30px;
    display: inline-block;
}
.cat_icon img{
    object-fit: contain;
}


.events_fullpage-content {
    flex: 1 1 auto;
    min-width: 0;
}
.events_fullpage-relative{
    margin-bottom: 40px
}
.r_event{
    padding: 15px;
    border-bottom: 1px solid var(--g100);
    display: block;
}
.r_event:last-child{
    border-bottom: none;
}
.r_event-preview{
    width: 60px;
    padding-right: 10px;
}
.r_event-preview.r_news-preview{
    padding-right: 0;
    padding-left: 10px;
}
.r_event-preview .prev_img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
}
.r_event-preview .prev_img > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.r_event-info {
    font-size: 14px;
    line-height: 1.4em;
    flex: 1 1 auto;
}
.r_event-info > .event_loc {
    font-weight: normal;
    font-size: 12px;
    color: var(--d400);
}

.r_event .event_date, .m_event-short .event_date,
.relatedEvent-info > .date{
    font-size: 12px;
    flex: 0 0 auto;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 10px 0 0;
}
.r_event .event_date > span, .m_event-short .event_date > span,
.relatedEvent-info > .date > span{
    display: block;
}
.r_event .event_date > span:first-child,  .m_event-short .event_date > span:first-child,
.relatedEvent-info > .date > span:first-child{
    font-weight: bold;
    font-size: 14px;
    color: var(--r200);
}

/*=========== RELEVANT ROUTE ==========*/
.recomendedRoutes-header{
    font-size: 22px;
    padding: 14px;
}
.container-relatedRoute{
    flex: 0 0 260px;
    width: 260px;
    padding-left: 24px;
    font-family: Bahnschrift;
}
.relatedRoute{
    width: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,0.18);
    display: block;
    border-radius: 8px;
    background-color: var(--d800);
}
.relatedRoute-preview{
    padding-top: 80%;
    position: relative;
}
.relatedRoute-preview img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}
.relatedRoute .author{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 1em;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,0.6) 100%);
    z-index: 1;
    font-size: 14px;
}
.relatedRoute .author .photo{
    width: 30px;
    border-radius: 50%;
}
.relatedRoute-info {
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.relatedRoute-descr{
    padding: 0 10px;
}

.relatedRoute-info__head .rate{
    display: flex;
    gap: 0.5em;
}
.relatedRoute-info__head .rate i{
    font-size: 1rem;
}

.relatedRoute-stat{
    padding: 4px 14px;
}

.relatedRoute-stat .routeBlocksStats{
    font-size: 14px;
}

.relatedRoute-stat .routeBlocksStats .item{
    padding: 4px 8px;
    border-color: #373737;
}
/*==============================*/




/*=========== RELEVANT =========*/
.events_fullpage-relative--header {
    font-size: 22px;
    padding: 20px;
}
.container-relatedEvent{
    flex: 0 0 320px;
    width: 320px;
    padding-left: 20px;
}
.relatedEvent{
    width: 100%;
    box-shadow: 0 0 8px rgba(0,0,0,0.18);
    display: block;
    border-radius: 8px;
}
.relatedEvent-preview img{
    width: 100%;
    border-radius: 8px 8px 0 0;
}
.relatedEvent-info {
    background: var(--white);
    padding: 10px 20px;
}
.relatedEvent-info .titleContent {
    overflow: hidden;
}
.relatedEvent-info .titleContent > .event_name,
.relatedEvent-info .titleContent > .event_loc{
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.relatedEvent-info .titleContent > .event_loc{
    font-size: 14px;
    opacity: 0.8;
    margin-top: 4px;
}

.merchContainer{
    margin-bottom: 2em;
}

.relatedEvents.sly > ul,
.merchContainer.sly > ul,
.relatedRoutes.sly > ul{
    display: flex;
}

.relatedEvent-info > .date{
    padding: 0 20px 0 0;
}

.relatedEvent-meta {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: var(--white);
    border-radius: 0 0 8px 8px;
}
.relatedEvent-meta .price{
    color: var(--r200)
}
/*================================*/


.merchItem{
    margin-right: 20px;
    text-align: center;
    display: inline-block;
    flex: 1 0 200px;
}

.merchItem > a{
    text-decoration: none;
    display: block;
    overflow: hidden;
}

.merchItem-image{
    width: inherit;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: var(--base-radius);
}

.merchItem-image > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.merchItem-descr{
    line-height: 20px;
    overflow: hidden;
    margin-top: 8px;
    white-space: normal;
    font-size: 14px;
    align-items: flex-start;
    align-content: flex-start;
}
.merchItem-title{
    text-align: center;
}


/*.full_event-cover {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}*/

.fe_title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    color: #3e3e3e;
}
.fe-head-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto
}
.fe_helper-created {
    font-size: 12px;
    opacity: 0.7;
}
.fe_helper{
    align-items: stretch;
    padding-top: 10px;
    line-height: 24px;
}
.fe_helper-category{
    flex: 1 1 auto;
}
.fe_helper-category > span{
    color: var(--g700);
    background: #efefef;
    padding: 3px 12px;
    border-radius: 20px;
    font-size: 14px;
}
.full_event-cover > img {
    max-width: 100%;
}
.full_event-content > div[class^=fe]{
    padding: 20px 20px 0 20px;
}
.full_event-content > .fe-head{
    position: relative;
}
.full_event-content h4 {
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 20px;
}
.fe-steps {
    margin: 30px 0;
}
.full_event-content .fe-steps{
    margin: 30px 0;
    background: var(--d950);
    padding: 20px 20px 30px 20px;
    border-radius: 14px;
}
.full_event-content #routePoints{
    margin-bottom: 0;
}
.fe-btnsfunc {
    display: flex;
    flex-direction: row;
    padding: 30px 30px 10px 30px !important;
    line-height: 30px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
.fe-desc p{
    padding: 0 20px;
    text-align: justify;
    line-height: 1.6em;
}
.e-info_item {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    font-size: 18px;
}
.e-info_item-icon{
    width: 1.6em;
    flex: 0 0 auto;
    font-size: 1.5em;
    text-align: center;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.e-info-item_dt {
    font-weight: 500;
    padding-left: 12px;
}
.e-info-item_dt > span:nth-child(2) {
    display: block;
    font-size: 0.9em;
    font-weight: normal;
}

.fe-info .e-info_item{
    padding: 0 0 20px 20px;
}
.m_event-info .e-info_item .e-info-item_dt{
    font-size: 14px;
    line-height: 1.2em;
}
.m_event-info .e-info_item{
    padding: 0 0 0.75em 0;
    align-items: center;
}

.share_btn > button {
    background: #fff;
    border: 1px solid var(--g700);
    border-radius: 5px;
    font-size: 16px;
    line-height: 40px;
    padding: 0 20px;
}
.members-h{
    width: 100%;
}
.members-h h4{
    margin-bottom: 0;
    flex: 1 1 auto;
}
.members-h .count{
    flex: 0 0 auto;
    margin-left: 10px;
}

#fe-members{
    overflow-x: auto;
    white-space: nowrap;
}

.e-members {
    position: relative;
}

.e-members--bg {
    height: 20vh;
    overflow: hidden;
    position: absolute;
    border-radius: var(--base-radius);
    z-index: -1;
    padding: 14px;
    width: 100%;
}

.e-members--bg > img {
    width: 110%;
    height: 110%;
    filter: blur(10px);
    position: absolute;
    z-index: -1;
    top: -5%;
    left: -5%;
}

.e-members--nav {
    position: relative;
    height: 60px;
    padding: 10px;
}

.e-members--event {
    margin: 0 60px 20px 60px;
}

.e-members--event h1{
    font-size: 26px;
    font-weight: normal;
    text-shadow: 1px 1px black;
    margin-bottom: 6px;
}
.e-members-head-arrow{
    display: block;
    padding: 10px;
    width: 40px;
    border-radius: 50%;
}
.e-members-head-arrow > .icon {
    width: 24px;
    height: 24px;
    fill: #FFF;
    vertical-align: middle;
}

.e-members--body {
    margin: 0 40px;
    z-index: 1;
}

.e-members--content {
    padding: 10px 0;
}

.event_last-member {
    flex: 0 0 110px;
    width: 110px;
    margin: 20px 20px 10px 0;
}
.event_last-member img{
    margin:auto;
    border-radius: 50px;
    width: 60px;
    height: 60px;
}
.event_last-member > div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    text-align: center;
}
.event_last-member > .date{
    font-size: 0.8em;
    color: var(--g500);
}

.see-all{
    margin-left: 20px;
    flex: 0 0 auto;
    color: var(--b800);
}

.fe-author {
    display: flex;
    align-items: center;
}
.fe-author > .userlist__item{
    flex: 0 1 auto;
    margin-right: 20px;
    border-radius: var(--base-radius);
}
.fe-author > .follow{
    flex: 0 0 auto;
    line-height: 36px;
    font-size: 14px;
}

.fe-organizer {
    padding: 10px;
}
.fe-organizer > div{
    display: flex; flex-direction: row; line-height: 2em;
}
.fe-organizer-l{
    flex: 0 0 auto;
    padding: 0 10px;
}
.fe-organizer-r{
    font-weight: 500;
    word-break: break-all;
}

.fe-map_image {
    width: 100%;
    overflow: hidden;
    display: block;
}
.fe-map_image > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.fe-location {
    padding-bottom: 20px !important;
}
.fe-map_caption {
    text-align: center;
    line-height: 1.6em;
    padding: 10px 10px 0 10px;
    font-size: 14px;
}
.fe-map_caption > span {
    display: block;
}
.fe-map_caption > .capt_location {
    font-weight: 500;
    font-size: 1.2em;
}
.fe-map_caption > .capt_address {
    color: var(--g500);
}

.se_event__bottom {
    padding: 10px 20px 20px 20px;
}

.se_event_profile{
    flex: 1 1 auto;
    display: flex;
    gap: 1em;
    align-items: center;
}
.se_event_profile .ava{
    width: 30px;
    height: 30px;
    border-radius: 15px;
}
.se_event_profile_container{
    padding: 0 20px 10px 20px;
}
/*========================*/

.location_form--item {
    margin-bottom: 12px;
}

.share-btns_container{
    display: flex;
}
.share-btns_container button{
    background: none;
    border: none;
    padding: 10px;
}
.share-btns_container img {
    width: 34px;
    height: 34px;
    cursor: pointer;
}
.share-btns_container button:hover img{
    opacity: 0.8;
}

.shareDialogLinkContainer{
    line-height: 40px;
    background: var(--d900);
    border: 1px solid var(--d900);
    border-radius: var(--base-radius);
    white-space: nowrap;
    padding: 0 40px 0 14px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    user-select: none;
}
.shareDialogLinkContainer.active{
    border-color: var(--green800);
}
.shareDialogLinkContainer:hover{
    background: var(--d800);
}
.shareDialogLinkContainer .link{
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.shareDialogLinkContainer .icon{
    font-size: 22px;
    position: absolute;
    top: 50%;
    right: 6px;
    width: 30px;
    transform: translateY(-50%);
    text-align: center;
}


/*===== Dialog download app =====*/

.baseDialog__container, .downloadAppDialog__container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.baseDialog-title, .downloadAppDialog-title{
    position: relative;
}

.baseDialog .cross, .downloadAppDialog .cross{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.baseDialog__overlay, .downloadAppDialog__overlay{
    position: absolute;top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,0.3);
}

.baseDialog, .downloadAppDialog{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 90%;
    max-width: 500px;
    background: var(--d800);
    border-radius: var(--base-radius-32);
    padding: 20px;
    border: 1px solid var(--d700);
    box-shadow: 0 0 20px rgba(0,0,0,0.8);
    text-align: center;
}

.baseDialog{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.baseDialog .baseDialog-content{
    flex: 1 1 auto;
}

.downloadAppDialog-marketBtns{
    display: flex;
    justify-content: space-around;
    gap: 20px;
    padding: 10px 20px 20px 20px;
}

.downloadAppDialog-marketBtns > a{
    border: 1px solid var(--d600);
    padding: 10px;
    border-radius: var(--base-radius);
}

/*===============================*/


/*===== DIALOGS ==========*/

#dialogsBody .userlist__item.active{
    background: var(--j800);
}

.dialogMessageContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.dialogMessageContainer.output{
    justify-content: flex-end;
}

.dialogMessage{
    max-width: 80%;
    display: flex;
    align-items: flex-start;
}

.dialogMessage .photo {
    border-radius: 50%;
    overflow: hidden;
    width: 36px;
    height: 36px;
    margin: 0.5em 0 0 1em;
    flex: 0 0 auto;
}

.dialogMessage .photo img{
    width: 100%;
}

.dialogMessage .body{
    margin: 0.5em 1em;
    padding: 1em 1em 0.5em 1em;
    background: var(--d600);
    border-radius: 0.5em;
    position: relative;
}

.dialogMessage .messageDate {
    font-size: 0.8em;
    text-align: right;
    white-space: nowrap;
    opacity: 0.7;
    margin-top: 0.4em;
    display: block;
}

.dialogMessageContainer.output .dialogMessage .body{
    background: var(--b800);
}

.dialogMessage .body::before{
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    display: block;
    border-width: 6px 8px 6px 8px;
    border-style: solid;
    border-color: transparent var(--d600) transparent transparent;
    top: 10px;
    left: -16px;
}

.dialogMessageContainer.output .dialogMessage .body::before{
    border-color: transparent transparent transparent var(--b800);
    left: auto;
    right: -16px;
}

/*========================*/


/*===== EVENTS ===========*/
.fullstory_image { text-align: center; overflow: hidden; margin: auto; position: relative }
.fullstory_image img { width: 100% }
span.zoom { display: block; width: 50px; height: 50px; font-size: 30px; position: absolute; left: 50%; top: 70%; z-index: 1; margin: -25px 0 0 -25px; color: #FFF; opacity: 0; background-color: rgba(0,0,0,0.6); padding: 10px; box-sizing: border-box; border-radius: 4px; cursor: pointer }
.fullstory_image:hover span.zoom,
.flexslider .slides > li:hover span.zoom { top: 50%; opacity: 0.7 }
span.zoom:hover { opacity: 0.9 }
span.zoom a { display: block; position: absolute; width: 100%; height: 100%; left: 0; top: 0; }
.share { text-align: center; font-weight: bold }
/*===============*/

/*закругленная кнопка внутри картинки на оверлее с фоном*/
.btn_b_r,
.btn_b_rt { line-height: 40px; font-size: 18px; border-radius: 20px; color: #FFF; padding: 0 20px; display: inline-block}
.btn_b_r span,
.btn_b_rt span { padding-left: 10px}
.btn_b_r { background: #2aaae0}
/*без фона*/
.btn_b_rt { border: 2px solid #2aaae0 }
#Gmap { height: 320px; width: 100% }
.events_categories { line-height: 34px; font-weight: 500; padding: 10px 0 }
.events_categories li { padding: 0 10px }
.events_categories li a { display: block; color: #515151;}
.events_categories li.active,
.events_categories li:hover {background: #f6f6f7; border-left: 3px solid #37b8eb;}
.events_categories__icon { float: left; opacity: 0.7; margin: 7px 10px 2px 2px; width: 20px; }
.events_categories li a:hover > .events_categories__icon { opacity: 0.9 }
/*==================================*/

.dialog_btns {padding: 10px 10px 0 0; text-align: left}
.dialog_btns button {margin-right: 10px; display: inline-block}
.img-container {width: 100%}
.img-container img {max-width: 100%; display: block}
/*.err_field {border: 1px solid #ff3030 !important}*/
.err_field_content {display: block;line-height: 1.4em;padding: 5px}
.err_field_mess {background: #ff3030;
    padding: 5px 12px;
    color: #FFF;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    line-height: 1.4em;
    margin-bottom: 5px;
    font-size: 14px;}
.err_field_mess:after {content: ''; width: 0; height: 0;
    border: 5px solid transparent;
    border-top-color: #ff3030;
    position: absolute; bottom: -10px; left: 5px}

/* Oh Snap */
#notice { position: fixed; bottom: 10px; right: 10px; z-index: 99999999; max-width: 100%}
.alert { text-align: left; margin: 10px auto auto 10px; padding: 15px; border-radius: 3px; background-color: white; color: white }
.alert-red { background-color: rgba(218, 68, 83, 0.7) }
.alert-green { background-color: rgba(22, 226, 120, 0.7) }
.alert-blue { background-color: rgba(74,137,220, 0.7) }
.alert-yellow { background-color: rgba(246,187,66, 0.7) }
.alert-orange { background-color: rgba(233,87,63, 0.7) }
.alert-black { background-color: rgba(0,0,0, 0.7) }

/*===== правила rules ==========*/
.rule_group { border-bottom: 1px solid #eaeaea; padding: 15px 10px 5px 15px}
.rule_group:last-child { border-bottom: 0; margin-bottom: 0 }
.rule_group > p { margin-bottom: 10px; text-indent: 1em; text-align: justify; line-height: 1.6em }
.rules_title { border-bottom: 1px solid #eaeaea; margin-bottom: 15px; }
.rules_title h1 { text-align: center; padding: 20px 10px 10px 10px; font-size: 26px }
.rule_title { margin-bottom: 10px; }
.pos_title { margin-bottom: 5px; padding: 0 12px; font-size: 15px; color: #da0000}
.good_rule li,
.bad_rule li,
.rec_rule li { list-style: disc }
.good_rule li { color: #009e00 }
.bad_rule li { color: var(--r1000) }
.rule { padding-bottom: 10px; line-height: 1.4em }
.rule li { margin-left: 30px; margin-bottom: 10px}
.rule li span { color: #444 }
.rules_summary { line-height: 30px; position: -webkit-sticky; position: sticky; top: 70px}
.rules_summary li { padding: 10px 20px; cursor: pointer; line-height: 20px }
.rules_summary li:hover { background: #0ca4ff; color: #FFF }
/*===============================*/

/*================= lastbikelogs =====*/
.bikelog_last{
    border-width: 1px 0;
    border-style: solid;
    border-color: #efefef;
}
.bikelog_last__item {
    display: flex;
    padding: 10px 0;
    align-items: center;
}
.bikelog_last:last-child{
    border-bottom: none;
}
.bikelog_last__item:hover {
    background: #f7fafb;
}
.bikelog_last__item > div{ padding-left: 20px}
.bikelog_last__item > div:last-child { padding-right: 20px}
.bikelog_last__img {
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    box-sizing: content-box;
    align-self: flex-start;
}
.bikelog_last__title { flex: 1 1 auto}
.bikelog_last__img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 5px;
}
.bikelog_last__title{
    padding-left: 10px;
    vertical-align: top;
    color:#505050
}
.bikelog_last__title > .min{
    font-size: 12px;
}

.bikelog_last .not_notify { margin: 10px }

.bikelogs_category_check li{padding: 5px 0; position:relative; display:block;line-height: 28px;height: 28px}
.bikelogs_category_check li label{position: absolute;padding-left: 24px;cursor: pointer;top: 0;left: 0;width: 100%; font-size:0.8em}

/*================= ВСПЛЫВАЮЩИЕ ПОДСКАЗКИ ===============*/

/*======= меню ======*/
.slidemenu__btn {
    width: 40px;
    height: 30px;
    color: #FFF;
    font-size: 28px;
    display: none;
    text-align: center;
    float: left;
    position: absolute;
    top: 5px;
    left: 5px;
}
.slidemenu__btn.active { background: #253642 }
.slidemenu { position: fixed; right: -130%; background: #253642; line-height: 40px; height: 100%; width: 290px; padding: 60px 0 0 0; overflow-y: auto; overflow-x: hidden; z-index: 996; font-size: 16px; display:block}
.slidemenu.active { right: 0 }
/*====================*/

/*====== пользователи =====*/
.p_user{width:33.333%; position:relative; height: 100px; padding: 10px 10px 20px 100px;}
		.p_user__foto {width: 80px; height:80px; position:absolute; top: 10px; left: 10px; border-radius: 50%;overflow: hidden;}
		.p_user__foto:hover { opacity: 0.8}
			.p_user__foto img {max-width: 100%; border-radius: 40px}
			.p_user__rating {position: absolute;left:0;bottom: 0; line-height:24px;width: 100%;text-align: center;font-size: 13px;color: #FFF;opacity: 0.8;background: rgba(0, 0, 0, 0.56)}
	.p_user__name a{ font-weight: bold;line-height: 30px;font-size: 15px;width: 100%;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap}
	.p_user__address { font-size: 13px; line-height: 16px; max-height: 32px; overflow: hidden}
		.p_user__address i { font-size: 0.8em}
/*=========================*/

/*====== смайлики =========*/
.emoji_wrap, .attach_comments {position: absolute;top: 7px;right: 7px;font-size: 16px;color: #273e4e;height: 22px; padding: 3px;z-index:3}
.attach_comments {top:auto; bottom:7px; overflow: hidden}

.emoji_list__head {line-height: 11px;padding: 2px 5px 5px 5px;font-size: 11px;font-weight: bold;text-align: center;clear: both}

.emoji_cont {max-width: 240px; -moz-user-select: none; -webkit-user-select: none; user-select: none;}

.emoji {display: inline-block; height:24px; width: 24px}
.emoji_sprite {
    background: url("/template/img/emoji_sprite.png") no-repeat;
}

.emoji_list__smiles > .emoji {margin: 0 3px}
.emoji_list__smiles > i:hover { opacity: 0.8}

.emoji_smile {background-position: 0 0}
.emoji_sorry {background-position: 0 -24px}
.emoji_hmm {background-position: 0 -48px}
.emoji_shock {background-position: 0 -72px}
.emoji_surprise {background-position: 0 -96px}
.emoji_laugh {background-position: 0 -120px}
.emoji_hey {background-position: 0 -144px}
.emoji_ok {background-position: 0 -168px}
.emoji_puke {background-position: 0 -192px}
.emoji_evil {background-position: 0 -216px}
.emoji_snooze {background-position: 0 -240px}
.emoji_cry {background-position: 0 -264px}
.emoji_sad {background-position: 0 -288px}
.emoji_inlove {background-position: 0 -312px}

.emoji_smileg {background-position: -24px 0}
.emoji_sadg {background-position: -24px -24px}
.emoji_sorryg {background-position: -24px -48px}
.emoji_laughg {background-position: -24px -72px}
.emoji_cryg {background-position: -24px -96px}
.emoji_nog {background-position: -24px -120px}
.emoji_wowg {background-position: -24px -144px}
.emoji_winkg {background-position: -24px -168px}
.emoji_waiting {background-position: -24px -192px}
.emoji_tauntg {background-position: -24px -216px}
.emoji_talkg {background-position: -24px -240px}
.emoji_sadg {background-position: -24px -264px}
.emoji_okg {background-position: -24px -288px}
.emoji_hmmg {background-position: -24px -312px}
/*============================*/

/*====== альбомы =========*/
.fs_0 { font-size:0}
.album_short {width: 33.333%;position: relative;display: inline-block; margin-bottom: 15px; }
	.album_prev {max-width: 95%;position: relative;margin: auto;display: block;font-size: 15px}
	.album_short__image {width: 100%; text-align:center; min-height: 150px; background-size: cover;background-position: center;}
		.album_short__image img {width: 100%;}
		.album_short__image .no_image_album {width: 20%; margin:auto}
		.hiden_album_icon {position: absolute;padding: 5px;color: rgba(0, 0, 0, 0.7)}
		.edit_block {position: absolute;right: 5px;top: 5px;display: block;background: rgba(0,0,0,0.8);border-radius: 3px;opacity: 0; font-size: 12px}
		.edit_block a{ display:block; padding: 7px; color: #FFF}
		.album_short:hover .edit_block { opacity: 0.6}
		.album_short .edit_block:hover { opacity: 0.8}
		.album_count_photos { position:absolute; top: 0; right:10px}
/*========================*/


/*===== загрузка фото =====*/
#select_1, #select_0 {display:none}
#select_album, #new_album { display:none; padding: 10px 0; margin:auto; max-width: 400px}
#select_1+label, #select_0+label { display: inline-block; background: #F2F2F2; color: #828282; padding: 0 10px; height: 30px; line-height: 30px}
#select_1+label:hover, #select_0+label:hover {background: #dedede;color:#FFF}
#select_1+label { border-radius: 20px 0 0 20px}
#select_0+label { border-radius: 0 20px 20px 0}
#select_1:checked+label, #select_0:checked+label {background: #75b0e4; color: #FFF; box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset}
#select_1:checked ~ #select_album {display:block}
#select_0:checked ~ #new_album {display:block}
/*=============================*/

/*===== feedback =====*/
.attach_screen { margin-left: 10px; color:#606060; cursor:default}
.attach_screen:hover { text-decoration:underline}
/*====================*/

/*===== footer ======*/
footer {background: #1a242b;}

.footer__top {line-height: 50px;border-bottom: 1px solid #242f38;padding: 0 10px}

.footer__copyrite {
    line-height: 40px;
    font-size: 13px;
    color: #909090;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}

.foo__item { margin-right: 20px; display:inline-block}
.foo__link { color: #909090}
.foo__link:hover { color: #eee}

.footer__top .foo__link { color: #FFF}

.foo__social { font-size: 20px}
.foo__social li { padding-left: 10px; display:inline-block}
.foo__social .foo__link{ color: #a7a7a7;}
.foo__social .foo__link:hover { color: #dadada}

.footer__row{margin-bottom: 10px;padding-bottom: 10px;border-bottom: 1px solid #242f38}

/*======= МОБИЛЬНОЕ МЕНЮ ======*/
.slidemenu li{
    padding: 0 30px;
    line-height: 60px;
}
.slidemenu li::after{
    content: '';
    display: block;
    height: 1px;
    background: #f2f2f2;
    width: 100%;
    bottom: 0;
    left: 0;
}
.slidemenu li:last-child ::after{
    display: none;
}
.slidemenu li.fullmenu-spacer{
    background: #f5f5f5;
    height: 7px;
}
.slidemenu li a{
    color: var(--g700);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.slidemenu li i{
    margin-right: 15px;
    font-size: 24px;
}
.slidemenu .submenu-text{
    flex: 1 0 auto;
}
.slidemenu__decorator{
    display: flex;
}
.fullmenu__usermenu-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 10px;
    border-bottom: 1px solid #f1f1f1;
}
.fullmenu__usermenu-avatar{
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
}
.fullmenu__usermenu-avatar > img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.fullmenu__usermenu-username{
    flex: 1 0 auto;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
}

.slidemenu{
    width: 100%;
    z-index: 99;
    padding: 0 0 50px 0;
    overflow-x: hidden;
}

.bottom_menu {
    position: fixed;
    flex-direction: row;
    align-items: center;
    align-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    background: #FFF;
    height: 60px;
    text-align: center;
    font-size: 20px;
    border-top: 1px solid var(--g100);
    z-index: 999;
}
.bottom_menu span{
    font-size: 12px;
    line-height: 1;
    margin-top: 8px;
    text-transform: uppercase;
}
.bottom_menu > a, .bottom_menu > div{
    color: var(--g700);
    line-height: 50px;
    flex: 1 1 auto;
    justify-content: center;
}
.bottom_menu a.actlink, .bottom_menu > *:hover{
    color: var(--b800);
}

/*=============================*/

/*====== НОВЫЕ ПОСТЫ =======*/
.n_post_content{
    padding:0
}

.n_post__header-bottom_line{
    padding: 15px;
    border-bottom: 1px solid #ecf0f2;
}
.n_post__ava{
    display: block; position: relative; float: left; width: 34px; height: 34px; margin-top: 3px; border-radius: 50%;
}
.n_post__ava.se_event{
    width: 24px;
    height: 24px;
    margin-top: 0;
}
.n_post__header > a {
    display:flex;
    flex-direction: row;
    padding: 20px 20px 10px 20px;
}
.n_post__header > a {
    color: var(--g700);
}
.n_post__header-info {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    min-height: 40px;
    justify-content: space-around;
}
.n_post__header-info.se_event{
    min-height: auto;
}
.n_post__header-ownername{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
}
.n_post__header-date{
    font-size: 12px;
}
.n_post__main{
    padding-top: 5px;
}

.n_post__main>a>div+div, .n_post__main>div+div{
    padding-top: 15px;
}
.n_post__main > .n_post__image_full{
    padding-top: 15px;
}
.bikelogs_tags{
    padding: 15px 70px 0 20px;
    font-size: 14px;
}
.bikelogs_tags > a {
    display: inline-block;
    background: var(--g200);
    color: var(--g700);
    padding: 4px 12px;
    border-radius: 20px;
    margin: 3px 3px 3px 0;
}
.bikelogs_tags > a:hover {
    background: var(--b800);
    color: var(--white);
}
.n_post__title{
    font-size: 20px;
    min-height: auto;
    border-bottom: none;
    margin: 0;
    padding: 0 20px;
    word-break: break-word;
}
.n_post__title h1{
    font-weight: normal;
    color: var(--g700);
}

.n_post__text{padding: 0 20px; word-break: break-word;}

.n_post__content {

}
.n_post__content figure{
    width: 100%;
}
.n_post__content figure > img{
    max-width: 100%;
    margin:auto;
}

.n_post__content .routePreview{
    margin: 0 20px;
}

a.mention{
    color: var(--b800);
}
a.mention:hover{
    color: var(--b200);
}


.n_story__block{
    margin: 0;
}
p.n_story__block{
    padding-left: 20px;
    padding-right: 20px;
    word-break: break-all;
}
.n_story__block + .n_story__block,
.routePreview + .n_story__block{
    margin-top: 20px;
}

.n_post__main {
    line-height: 1.6em;
    position: relative;
}
.show_full_text {
    position: absolute;
    right: 20px;
    text-align: center;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.show_full_text-btn {
    border: 0;
    background: #FFF;
    line-height: 40px;
    padding: 12px;
    border-radius: 10px;
    font-size: 16px;
    color: var(--g700);
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px #d8d8d8;
    cursor: default;
    user-select: none;
}
.show_full_text-btn:hover{
    background: var(--b800);
}
.show_full_text-btn:active {
    background: #CCC;
    animation: forwards;
}

.show_full_text-btn .loader{
    width: 18px !important;
    height: 18px !important;
}

.show_full_text-btn .contentTypes{
    display: inline-flex;
    gap: 0.75em;
    font-size: 14px;
    align-items: center;
    margin-right: 1em;
    position: absolute;
    right: 100%;
}

.show_full_text-btn .contentTypes .item{
    display: inline-flex;
    align-items: center;
    gap: 0.5em
}

.show_full_text-btn .contentTypes img{
    height: 22px;
}


.n_post__bottom {
    padding: 20px 15px;
    line-height: 26px;

}

.n_post__bottom [class*=icon-]{
    font-size: 22px;
    vertical-align: text-bottom;
}

.n_post__bottom a {
    color: var(--g700);
}

.follow_bottom_post {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 10px;
    user-select: none;
    color: var(--b800)
}

.follow_bottom_post:hover{
    color: var(--green800);
}


.follow_bottom_post > .img {
    width: 24px;
    height: 24px;
    overflow: hidden;
    display: block;
    border-radius: 50%;
}

.follow_bottom_post img {
    width: 100%;
}

.follow_bottom_post > .follow_text{
    padding-left: 10px;
}

.follow_bottom_post img.img_ok{
    display: none;
}

.follow_bottom_post.active img.img_av,
.follow_bottom_post.active .follow_text
{
    display: none;
}
.follow_bottom_post.active img.img_ok{
    animation: spin-zoom .3s ease-in-out;
    display: block;
}

.n_post__image_full > img{
    margin: auto;
    max-width: 100%;
}

.count_comments, .count_views{
    padding: 0 7px;
    vertical-align: middle;
    display: inline-block;
}

.short_post_url{
    color: var(--g700);
}
.short_post_url:hover{
    color: var(--g700);
}

.short_post_route{
    padding-left: 20px;
    padding-right: 20px;
}



.n_post__comment{
    padding: 0 20px 20px 20px;
}
.addShortCommentFrom{
    position: relative;
}
.addShortCommentFrom .text::placeholder{
    color: var(--g700);
}
.addShortCommentFrom .text{
    background: var(--g100);
    border: 1px solid var(--g200);
    resize: none;
    border-radius: 20px;
    padding: 10px 40px 10px 50px;
    font-size: 14px;
    line-height: 22px;
    width: 100%;
    color: var(--g700);
    transition: box-shadow ease-in-out .2s;
}

.addShortCommentFrom .text:focus {
    box-shadow: 0 0 5px var(--b800);
}

#editorjs *[contenteditable]{
    padding:12px;
}
#editorjs *[data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: #888;
}
#editorjs *[data-placeholder]:empty:focus:before{
    content: '';
}

.addShortCommentFrom .ava{
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 6px;
    left: 6px;
    transition: opacity ease-in-out .2s;
}

.addShortCommentFrom .sendBtn{
    position: absolute;
    font-size: 22px;
    top: 9px;
    right: 14px;
    transition: opacity ease-in-out .2s;
    opacity: 0;
}

.addShortCommentFrom.active .ava,
.addShortCommentFrom.active .sendBtn,
.addShortCommentFrom .text:focus+.sendBtn{
    opacity: 1;
}


.shortComment {
    display: flex;
    font-size: 14px;
    padding: 0 20px 15px 5px;
    align-items: center;
}

.shortComment .ava {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
}





/*======== ADD POST =========*/
.codex-editor .cdx-content > img{
    margin: auto;
    border-radius: 5px;
    max-width: 100%;
}
.ce-toolbar__content:hover .ce-toolbar__actions{
    opacity: 1;
}

.attachmentContainer input{
    display: none;
    padding: 10px;
}

.circleProgress svg {
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
}

.circleProgress {
    width: 112px;
    height: 112px;
    margin: auto;
    position: relative;
}

.circleProgress .preview {
    width: 112px;
    height: 112px;
    position: absolute;
    top: 0;
    left: 0;
    border: 4px solid #e9e9e9;
    border-radius: 50%;
    padding: 4px;
}

.circleProgress .preview img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.circleProgressAbsoluteCenter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cdx-content .video_preview_container{
    border-radius: 5px;
    overflow: hidden;
}

.RouteAttachment .cdx-button,
.RouteAttachment .cdx-content{
    display: none;
}

.RouteAttachment.showButton .cdx-button{
    display: block;
}

.RouteAttachment.showContent .cdx-content{
    display: block;
}

.attachmentsBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.attachmentsBtns > span{
    display: inline-block;
    line-height: 1em;
    padding: 5px;
    color: var(--g700);
    margin-right: 20px;
}

.attachmentsBtns > span.sendPost{
    margin-right: 0;
}

.attachmentsBtns > span:hover{
    transform: scale(1.2);
}

.attachmentsBtns > span.sendPost:hover{
    transform: scale(1);
}


.attachmentsBtns > span i{
    display: block;
}

.attachmentContainer{
    padding: 10px;
}

.attachmentContainer .youtubeLink{
    border: 1px solid var(--d600);
    border-radius: 3px;
    height: 36px;
    position: relative;
}
.attachmentContainer .youtubeLink svg{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
}
.attachmentContainer .youtubeLink input{
    width: 100%;
    display: block;
    background: transparent;
    border: none;
    line-height: 34px;
    margin: 0 0 0 34px;
    padding: 0 5px;
}

.addPostFormHeader{
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--d700);
}
.addPostFormHeader > div+div {
    margin-top: 10px;
}
/*=============================*/







/*=========== WhatNew ==============*/

.whatNew{
    padding: 1em;
}

.whatNew .prephoto{
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: opacity ease-in-out 0.2s;
    overflow: hidden;
}

.whatNew .prephoto img{
    max-width: 100%;
}

.whatNew .post {
    border: 1px solid var(--g300);
    margin: 0 0 1em 1em;
    border-radius: 0.25em;
    padding: 1em;
    position: relative;
    flex: 1 1 auto;
    background: var(--g100);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.whatNew textarea{
    line-height: 1.6;
    color: var(--white);
    font-size: 16px;
    resize: none;
    width: 100%;
    border: none;
    background: transparent;
}

.whatNew .types{
    display: flex;
    flex-direction: row;
}
.whatNew .types button{
    flex: 1 1 auto;
    padding: 4px 0;
    margin-right: 10px;
    background: var(--d600) !important;
}

.whatNew .types button:last-child{
    margin-right: 0;
}


.whatNew .attachmentsBtns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
    font-size: 22px;
    display: none;
    justify-content: space-between;
}

.whatNew .attachmentsBtns span{
    display: inline-flex;
    padding: 10px;
    border-radius: 6px;
    position: relative;
}
.whatNew .attachmentsBtns span:hover{
    background: var(--d700);
}

.whatNew .goToFull {
    padding: 0.75em;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.whatNew .attachmentsContainer {
    padding-top: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-3);
}

.whatNew .addPostHeader{
    display: none;
}

.whatNew.focused textarea{
    min-height: calc(2em * 1.6);
}

.whatNew.focused .post{
    padding: 1em 1em 3em 1em;
    margin: 1em 0;
}

.whatNew.focused .types{
    display: none;
}

.whatNew.focused .addPostHeader
{
    display: block;
}

.whatNew.focused .prephoto{
    display: none;
}

.whatNew.focused .attachmentsBtns,
.whatNew.focused .goToFull {
    display: flex;
}

.whatNew .image_box{
    height: 70px;
    width: 70px;
    border-radius: 6px;
}

.whatNew .addPostFormItem{
    padding: 5px 0;
}

.whatNew .userlist__item.min{
    padding: 5px;
}

/*==================================*/






/*======== EVENTS =======*/
.events_search{
    padding-bottom: 20px;
    align-items: start;
}
.events_search-content{
    flex: 1 1 auto;
}
.events_search-filters {
    width: 250px;
    max-width: 250px;
    margin-left: 20px;
    flex: 1 0 auto;
    border-radius: 5px;
}
/*=======================*/

/*======== NEWS =========*/
.news_search-content{
    flex: 1 1 auto;
}
.news_search-filters {
    width: 250px;
    max-width: 250px;
    margin-left: 20px;
    flex: 1 0 auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 2em
}

a.n_news-htitle {
    text-transform: uppercase;
    color: #3e3e3e;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.n_news-title{
    flex: 1 1 auto;
    padding-left: 15px;
}

a.n_news_url {
    display: flex;
    flex-direction: row;
    color: var(--g700);
}
.n_news-text{
    padding: 0 20px;
}
.n_news-image{
    padding: 0 20px 0 0 !important;
    width: 250px;
}

.n_news-preview{
    width: 220px;
    height: 130px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 7px;
}
.n_news-preview > img{ width: 100%; height: 100%; object-fit: cover}

.n_news-ava{
    flex: 0 0 auto;
}
.n_news-ava-image{
    width: 36px;
    height: 36px;
    background: #2a4556;
    border-radius: 50%;
    overflow: hidden;
}
.n_news-ava-image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.n_news_info {
    font-size: 14px;
    color: #6f6f6f;
    padding: 0 20px 10px 20px;
}
.n_news_info > span {padding-left: 10px}
.n_news_info > span:first-child {padding-left: 0}

.n_news_cat > a {color: #616161;
    background: #efefef;
    padding: 3px 12px;
    border-radius: 20px;}
.n_news_cat > a:hover{
    background: #19a2d6;
    color:#FFF;
}

.news_cats{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
}

.news_cats > *{
    background: var(--d800);
    border: 1px solid var(--d800);
    padding: 6px 16px;
    border-radius: var(--base-radius-16);
    box-shadow: var(--base-shadow);
}

.news_cats > *.actlink,
.news_cats > *:hover{
    border: 1px solid var(--b800);
    color: var(--b800)
}


.sidebar_cats {display: block;line-height: 24px; text-align: center; padding: 15px 0;}


.sidebar_cats a {
    display: block;
    background: none;
    padding: 5px 15px;
    color: #404040;
    border-radius: 25px;
    text-align: left;
}

.sidebar_cats img {
    width: 22px;
    float: left;
    margin-right: 10px;
}

.news_search-filters > .box_wht{
    /*position: -webkit-sticky;
    position: sticky;
    top: 70px;*/
}

.news__content {font-size: 16px; line-height: 1.6em; padding-top: 0 !important}
.news__content p {margin: 0 0 10px 0; text-align:justify; padding: 0 15px}
.news__content p:last-child { margin: 0}
.news__content p img{max-width: 100%; height: auto !important; margin:auto}
.news__content h2 {
    margin: 50px 15px 30px 15px;
}
.news__content ol, .news__content ul{
    margin: 0 40px 20px 40px;
    clear: both;
    counter-reset: li;
}
.news__content ol li, .news__content ul li{
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}
.news__content ol {counter-reset: li;}
.news__content ol li:before {counter-increment: li; content: counters(li,".") ". "; display: inline-block; margin-right: 5px;}
.news__content ul li:before {content: counter(li);
    counter-increment: li;
    position: absolute;
    top: 9px;
    right: 100%;
    background: #f80;
    border-radius: 3px;
    height: 6px;
    width: 6px;
    text-indent: -10000px;}

.news__content iframe {
    display: block;
    width: 70vh;
    height: 38vh;
    border: 0;
    margin: 15px auto;
}
.news__content.fr-view .fr-img-caption .fr-img-wrap > span {
    margin: auto;
    display: block;
    padding: 5px;
    font-size: 14px;
    opacity: 0.9;
    width: 100%;
    text-align: center;
}

.metchSponsoredTitle {
    padding: 10px 20px;
    font-size: 1.2em;
    line-height: 2em;
}

.merchContainer ul li{
    margin-bottom: 0;
}

.merchContainer ul li:before{
    display: none;
}

.filterDisplayVal{
    font-size: 0.9em;
}

.box_wht.eventsSearchForm{
    overflow: visible;
}

.eventsSearchForm-filter {
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.eventsSearchForm-filter .resetFilters{
    flex: 0 0 auto;
}
.eventsSearchForm-content{
    padding: 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.eventsSearch-loc {
    position: relative;
    width: 100%;
    margin: 0 30px;
}

.eventsSearch-locBtn {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    border-bottom: 2px solid #2d333a;
    font-size: 1.3rem;
}

.events_header{
    padding: 0 20px 20px 20px;
}

.eventsSearchForm-filter .dropdown_menu {
    right: auto;
    left: 0;
    padding: 10px;
}
.eventsSearchForm-filter .dropdown_button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: none;
    border: none;
    font-size: 16px;
    line-height: 1.4em;
    margin-right: 20px;
    padding: 10px;
}
.eventsSearchForm-filter .dropdown_button.active {
    color: var(--b800);
}
.eventsSearchForm-filter .dropdown_button > span{
    margin-left: 10px;
}

.routesSearchForm{
    user-select: none;
}


.routes_search-content{
    width: 100%;
}

.routesSearchForm-header,
.routesSearchForm-filter{
    padding:0 20px
}
.routesSearchForm-header{
    font-size: 18px;
    font-weight: 500;
    padding-left: 30px;
    line-height:60px;
}
.routesSearchForm-filter{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
}

.routesSearchForm-filter .resetFilters{
    flex: 0 0 auto;
}

.searchLocationFull{
    padding: 0;
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.searchLocationFull-loc {
    position: relative;
    width: 100%;
    font-size: 24px;
}

.searchLocationFull-locBtn {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    border-bottom: 2px solid var(--g700);
}

.searchLocationFull-loc.active .searchLocationFull-locBtn{
    border-bottom: 2px solid var(--g700);
    background: var(--d800);
    border-radius: 10px 10px 0 0;
}

.routes_header{
    padding: 0 20px 20px 20px;
}

.routesSearchForm-filter .dropdown_menu {
    right: auto;
    left: 0;
    padding: 10px;
}
.routesSearchForm-filter .dropdown_button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background: none;
    border: none;
    font-size: 16px;
    line-height: 1.4em;
    margin-right: 20px;
    padding: 10px;
}
.routesSearchForm-filter .dropdown_button.active {
    color: var(--b800);
}
.routesSearchForm-filter .dropdown_button > span{
    margin-left: 10px;
}


.searchLocationFull-locBtn > .icon-location-pin,
.searchLocationFull-locBtn > .icon-cross{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.searchLocationFull-locBtn > .icon-location-pin,
.searchLocationFull-loc .findLocationInput,
.searchLocationFull-loc .findLocationInput::placeholder{
    color: #00a1ff !important;
    transition: color 0.2s ease-in-out;
}

.searchLocationFull-loc.active input.findLocationInput::placeholder,
.searchLocationFull-loc.active .searchLocationFull-locBtn > .icon-location-pin{
    color: var(--g700) !important;
}

.searchLocationFull-locBtn > .icon-cross{
    left: auto;
    right: 10px;
    font-size: 12px;
    display: none;
}

.searchLocationFull-locBtn > .icon-cross.active{
    display: block;
    padding: 10px;
}
.searchLocationFull-locBtn > .icon-cross.active:hover{
    color: var(--b600);
}

.searchLocationFull-locBtn > input,
.searchLocationFull-locBtn > input:focus{
    border: none;
    background: none;
    line-height: 48px;
    padding: 0 10px;
    border-radius: 0;
}


.searchLocationFull-locBtn input.findLocationInput {
    background: none;
    font-size: 24px;
}

.searchLocationFull-loc.active .dropdownFilterLocation{
    display: block;
    background: var(--d800);
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 100%;
    width: 100%;
    z-index: 99;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}



.chooseLocationGallery{

}

.sliderLocations{
    display: flex;
    transition: transform 0.5s ease;
    width: 300%;
}

.slideLocations{
    width: 33.333%;
}

.slideLocations .slide-header{
    height: 56px;
    display: flex;
    padding: 16px;
    font-size: 18px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--d700);
    background: var(--d750);
}

.slideLocations .slide-header .back{
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 5px 14px;
    background: var(--d600);
    border-radius: 6px;
    cursor: pointer;
}

.slideLocations .slide-header .back:hover{
    background: var(--b800);
}

.slideLocations .slide-content{
    overflow-y: auto;
    max-height: 400px;
}


.dropdown_menu.dropdownFilterDate {
    min-width: 320px;
    max-width: 100%;
    padding: 20px 15px;
}
.dropdown_menu.dropdownFilterTypeBikes,
.dropdown_menu.dropdownRadioItems{
    max-height: 400px;
    overflow-y: auto;
}

.dropdown_menu.dropdownFilterDate > .item,
.dropdown_menu.dropdownFilterCategory > .item,
.dropdown_menu.dropdownFilterTypeBikes > .item,
.dropdown_menu.dropdownRadioItems > .item
{
    padding: 5px 40px 5px 10px;
    user-select: none;
    border-bottom: 1px solid var(--g200);
    border-radius: 4px;
    position: relative;
    white-space: nowrap;
}
.dropdown_menu.dropdownFilterDate > .item:hover,
.dropdown_menu.dropdownFilterCategory > .item:hover,
.dropdown_menu.dropdownFilterTypeBikes > .item:hover,
.dropdown_menu.dropdownRadioItems > .item:hover
{
    background: var(--g300);
}
.dropdown_menu.dropdownFilterDate .pickDate {
    padding: 10px 0 0 0;
}
.dropdown_menu.dropdownFilterDate .pickDate > div{
    padding: 0 5px;
}

.pickDate label > span{
    padding-left: 5px;
    font-size: 0.9em;
    color: #3da4e0;
}


.listCheck {
    position: absolute;
    display: block;
    background: var(--g400);
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
}

.listCheck:before, .listCheck:after{
    content: '';
    position: absolute;
    display: block;
    background: var(--white);
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
}

.listCheck:after{
    width: 10px;
    height: 10px;
    background: var(--g700);
    display: none;
}

.dropdown_menu.dropdownFilterDate > .item.active .listCheck:after,
.dropdown_menu.dropdownFilterCategory > .item.active .listCheck:after,
.dropdown_menu.dropdownFilterTypeBikes > .item.active .listCheck:after,
.dropdown_menu.dropdownRadioItems > .item.active .listCheck:after
{
    display: block;
}


.pickLocation{
    position: relative;
}
.pickLocation .icon-search{
    position: absolute;
    left: 10px;
    top: 11px;
}
.pickLocation .icon-cross {
    position: absolute;
    top: 13px;
    right: 12px;
    font-size: 12px;
    opacity: 0.2;
}

.emptyListLocation {
    margin: 10px;
    padding: 14px 20px;
    border: 1px dashed var(--g700);
    border-radius: 8px;
    text-align: center;
}

.itemLocation {
    padding: 12px 16px;
    border-bottom: 1px solid var(--d700);
    line-height: 1em;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.itemLocation:last-child{
    border-bottom: 0;
}

.itemLocation:hover{
    background: var(--d600);
}

.itemLocation.active{
    background: var(--b600);
}

.itemLocation > .subName{
    font-size: 0.8em;
    color: var(--d400);
    margin-top: 4px;
}

.itemLocation.active > .subName{
    color: var(--g100)
}



.dropdownFilterLocation{
    display: none;
    font-size: 14px;
}
.eventsSearch-loc.active .dropdownFilterLocation {
    display: block;
    background: var(--d800);
    position: absolute;
    top: 100%;
    left: 0;
    max-width: 100%;
    width: 100%;
    z-index: 99;
    border-radius: 0 0 10px 10px;
}


.eventsSearch-locBtn > .icon-location-pin,
.eventsSearch-locBtn > .icon-cross{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.eventsSearch-locBtn > .icon-location-pin,
.eventsSearch-locBtn .findLocationInput,
.eventsSearch-locBtn .findLocationInput::placeholder{
    color: #00a1ff !important;
    transition: color 0.2s ease-in-out;
}

.eventsSearch-loc.active input.findLocationInput::placeholder,
.eventsSearch-loc.active .eventsSearch-locBtn > .icon-location-pin{
    color: var(--g700) !important;
}

.eventsSearch-locBtn > .icon-cross{
    left: auto;
    right: 10px;
    font-size: 12px;
    display: none;
}

.eventsSearch-locBtn > .icon-cross.active{
    display: block;
}
.eventsSearch-locBtn > .icon-cross.active:hover{
    color: var(--b600);
}

.eventsSearch-locBtn > input,
.eventsSearch-locBtn > input:focus{
    border: none;
    background: none;
    line-height: 48px;
    padding: 0 10px;
    border-radius: 0;
}

.eventsSearch-locBtn input.findLocationInput {
    background: none;
    font-size: 1.25rem;
}


/*======== HASHTAGS =====*/

.searchHashtagsContent {
    padding: 20px 50px;
    font-size: 30px;
}

.searchHashtagsContent > .count{
    font-size: 18px;
    opacity: 0.6;
}

a.hashtagLink{
    color: var(--b800);
}
a.hashtagLink:hover{
    text-decoration: underline;
}

.hashtagRelative {
    margin-top: 14px;
    font-size: 1rem;
    line-height: 1.6em;
}


/*======== CHOSEN =======*/
.chosen-container .chosen-results li {
    padding: 2px 6px;
    line-height: 1.6em;
    font-size: 14px;
}

.chosen-container-single .chosen-single, .chosen-container-multi .chosen-choices{
    /*border: 1px solid #D2D2D2 !important;*/
    color: #5C5D5E !important;
    padding: 1px 15px !important;
    width: 100% !important;
    line-height: 40px !important;
    min-height: 40px !important;
    border-radius: var(--base-radius) !important;
    font-size: 14px !important;
    background: none !important;
    box-shadow: none !important;
}
.chosen-container-active.chosen-with-drop .chosen-single {
    border-radius: 6px 6px 0 0 !important;
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: url(/template/img/appearance.png) no-repeat right 15px top 50%;
}

.chosen-container-single .chosen-single div {
    position: absolute !important;
    top: 7px !important;
    right: 7px !important;
    display: block !important;
    width: 18px !important;
    height: 18px !important;
}
/*==========================================*/

.static_filters { padding: 0 0 0 20px;}
.static_filters li {display: inline-block;margin-right: 10px}

.sell_btn {margin-right: 0 !important}
.sell_btn > a {background: #273e4e; height: 42px; padding: 0 20px; color: #FFF; line-height: 42px; display: block; font-weight: bold; position:relative}
.sell_btn > a > i {font-size: 0.8em;margin-right: 7px}
.sell_btn > a:before {content: '';width: 0;height: 0;
    border: 21px solid transparent;
    border-right-color: #273e4e;
    position: absolute;left: -42px;top: 0}
.sell_btn > a:hover { background: #E91D20}
.sell_btn > a:hover:before {border-color: transparent #E91D20 transparent transparent;}

.search_autocomplete__item-res {display: inline-block;background: #56a7e0;margin: 3px 7px 0 0;padding: 2px 5px;border-radius: 3px;color: #FFF}
.search_autocomplete__item-res i {font-size: 0.8em;margin-left: 5px}

.cat_load {margin-right: 5px;margin-top: 7px;border: 4px solid #f3f3f3;border-radius: 50%;border-top: 4px solid #37b8eb;border-bottom: 4px solid #37b8eb;width: 20px;height: 20px;-webkit-animation: spin 1s linear infinite;animation: spin 1s linear infinite}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin-zoom {
    0% { -webkit-transform: scale(0) rotate(0deg)}
    100% { -webkit-transform: scale(1) rotate(360deg)}
}

@keyframes spin-zoom {
    0% { transform: scale(0) rotate(0deg)}
    100% { transform: scale(1) rotate(360deg)}
}

.search_field_active {border-color: #37b8eb; z-index: 2; color: #37b8eb}





.sa__rel { padding: 5px }
.sa__rel .short_image { position: relative; overflow: hidden; }
.sa__rel .short_image > img { width: 100%; }
.sa__rel_title { width: 100%; line-height: 15px; color: #4a6577; overflow: hidden; text-overflow: ellipsis; height: 54px; font-size: 13px; padding: 3px; }
.sa__rel_title span:first-child {margin-bottom: 3px;height: 20px; line-height: 20px; font-weight: bold; font-size: 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis}
.sa__rel_title span { display: block; }
.sa__rel_price {position: absolute; top: 10px; right: 10px; color: #FFF; font-weight: bold; text-shadow: 1px 1px 0 rgba(47, 47, 47, 0.68); font-size:17px; z-index: 5}




.ss_d { width: 100%; display: block; position: relative; font-size: 13px; min-height: 160px; padding: 10px; overflow: hidden; color: #444 }
.ss_d:hover { color: #444 }
.ss_d__title { font-size: 18px; font-weight: bold; position: relative; padding-right: 40px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 450px/*костыль для вывода в профиле*/;}
.ss_d__prev { width: 240px; height: 140px; position: absolute; top: 10px; left: 10px; overflow: hidden; border-radius: 3px; background: #ececec}
.ss_d__data { padding-left: 5px; position: relative; margin-left: 245px }
.ss_d__location { padding-top: 3px }
.ss_d__price { font-size: 18px; padding-top: 10px; color: #1b91b3; }
.ss_d__text { padding-top: 10px; font-size: 14px; opacity: 0.6 }

@media screen and (max-width: 640px) {
	.ss_d__prev {width: 100%;height: 180px; position: relative; top: 0; left: 0}
	.ss_d__data {margin-left: 0; margin-top: 10px}
}


.ss_g {width: 24%;display: block;position: relative;font-size: 13px;min-height: 160px;padding: 10px;overflow: hidden;color: #444}
.ss_g:hover {color: #444}
.ss_g__title {font-size: 18px;font-weight: bold;position: relative;white-space: nowrap;overflow: hidden;text-overflow: ellipsis}

.ss_g__data {padding-left: 5px; position: relative; margin-top: 5px}
.ss_g__location {padding-top: 3px;max-height: 2.6em; overflow: hidden;}
.ss_g__price {font-size: 18px; padding-top: 10px; color: #1b91b3}

@media screen and (max-width: 1200px) {
	.ss_g {width: 33.333%}
}
@media screen and (max-width: 900px) {
	.ss_g {width: 50%}
}
@media screen and (max-width: 540px) {
	.ss_g {width: 100%; border-bottom: 1px solid #f0f0f0}
}


.ss_l { display: flex; position: relative; font-size: 13px; padding: 0 10px; overflow: hidden; color: #444; width: 100%; height: 50px; border-bottom: 1px solid #f4f4f4; }
.ss_l:hover { color: #444; opacity: 0.8 }
.ss_l__title { font-size: 15px; line-height: 30px; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; }
.ss_l__date { font-size: 13px; font-weight: normal; line-height: 15px; opacity: 0.6; }
.ss_l__prev { width: 40px; position: relative; flex-shrink: 0; }
.ss_l__data { padding-left: 5px; position: relative; display: flex; width: 100%; }
.ss_l__location { padding: 0 10px; width: 300px; line-height: 50px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex-shrink: 0; }
.ss_l__price { font-size: 18px; color: #1b91b3; line-height: 50px; flex-shrink: 0; width: 100px; text-align: right; }
.ss_l__text { padding-top: 10px; font-size: 14px; opacity: 0.6 }

@media screen and (max-width: 800px) {
	.ss_l { height:auto}
	.ss_l__prev { position:absolute; top: 0; left: 10px}
	.ss_l__data { flex-wrap:wrap; padding-left: 40px;}
	.ss_l__price {width:auto; position:absolute; right: 10px; bottom: 10px}
	.ss_l__location { padding: 0; line-height: 30px}
}

/*================================*/

/*====== новости =================*/

.art_share {
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    line-height: 30px;
    box-sizing: border-box;
    clear: both;
}
.art_share_title {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.news_short {clear: both;border-top: 1px solid #ecf0f2;padding: 10px}
a.news_short {
    color: #444;
}
a.news_short:hover{
    color: #444;
}
.news_short__prev {
    width: 240px;
    height: 150px;
    overflow: hidden;
    float: left;
    margin: 5px 10px 10px 0;
	position:relative;
}
.news_short__prev .img_absolute.img_w{border-radius: 7px}

.news_short__descr { margin-top: 0; margin-left: 250px; line-height: 22px; font-size: 14px; width: inherit; text-align: justify;}
.news_short__more {
    font-weight: 700;
    font-size: 14px;
    color: #1b89b3;
}
.news_short__title {
    font-size: 20px;
    font-weight: bold;
    color: #404040;
    display: block;
    text-transform: uppercase;
    text-align: left;
}
.news_short__title:hover { text-decoration: underline; color: #E45332}



.news__relative {padding: 15px}
.news__relative h3 {padding: 0 0 5px 0;color: #3c3c3c;font-size: 1.3em;text-align: center}
.news__rel {display: block;font-weight: bold;line-height: 24px;color: #4c4c4c}


.extranews_separator {
    clear: both;
    background: transparent url(/template/img/separator.png) no-repeat 50%;
    display: block;
    height: 14px;
    margin: 15px 0;
}




.last_news_list {line-height: 18px;font-size: 11px}
.last_news_list > li {padding-bottom: 3px;border-bottom: 1px dashed #ecf0f2;margin-bottom: 3px; clear:both}
.last_news_list > li:last-child { padding-bottom:0; border: 0; margin: 0}
.last_news_list > li > a {font-size: 14px; color:#404040}
.last_news__prev {width: 50px;height: 50px;display: block;background-size: cover;background-position: center;margin-top: 3px;float: left;margin-right: 7px; border-radius: 3px}
.ln_min-h { height: 60px; overflow:hidden}

.bikes_for_news h3{padding: 0 0 5px 0;color: #3c3c3c; font-size: 1.3em; text-align: center}
.bikes_list_for_news{display:flex}
.bikes_list_for_news .bike_prev {width: 25%; padding: 5px; font-weight:bold}
/*=====================================*/


/*============ кнопки поделиться ============*/
.share-btn__link,
.share-btn__icon {display: inline-block}
.share-btn__link {text-decoration: none; color: #fff; margin: 0.5em}
.share-btn__link:hover {color:#FFF}
.share-btn {border-radius: 5px; transition: 25ms ease-out; padding: 0.5em 0.75em;font-family: Helvetica Neue,Helvetica,Arial,sans-serif}
.share-btn__icon svg {width: 1.3em; height: 2em; margin-right: 0.4em; vertical-align: top}
.share-btn--small svg {margin: 0;vertical-align: middle}

.share-btn__icon {stroke: #fff;fill: none}

.share-btn__icon--solid,
.share-btn__icon--solidcircle {fill: #fff; stroke: none}

.share-btn--twitter {background-color: #55acee}
.share-btn--twitter:hover {background-color: #2795e9}

.share-btn--facebook {background-color: #3b5998}
.share-btn--facebook:hover {background-color: #2d4373}

.share-btn--google {background-color: #dd4b39}
.share-btn--google:hover {background-color: #c23321}

.share-btn--email {background-color: #777}
.share-btn--email:hover {background-color: #5e5e5e}

.share-btn--facebook {background-color: #3b5998; border-color: #3b5998}

.share-btn--facebook:hover,
.share-btn--facebook:active {background-color: #2d4373; border-color: #2d4373}

.share-btn--twitter {background-color: #55acee;border-color: #55acee}

.share-btn--twitter:hover,
.share-btn--twitter:active {background-color: #2795e9; border-color: #2795e9}

.share-btn--google {background-color: #dd4b39;border-color: #dd4b39}

.share-btn--google:hover,
.share-btn--google:active {background-color: #c23321; border-color: #c23321}

.share-btn--email {background-color: #777777; border-color: #777777}

.share-btn--email:hover,
.share-btn--email:active {background-color: #5e5e5e; border-color: #5e5e5e}
/*================================*/

/*====== последние комменты =====*/
.lastcomm {margin-bottom: 5px;font-size: 13px;padding-left: 40px;position: relative;line-height: 15px;min-height: 45px; overflow:hidden}
.lastcomm__ava {float: left;width: 34px;border-radius: 20px;position: absolute;left: 0;top: 3px}
/*===============================*/

/*====== Выборы ===============*/
.election_novisited { position:relative}
.election_novisited:before {content: '';position: absolute;top: 0;right: 0;width: 7px;height: 7px;background: #b31b23;border-radius: 5px}
.election_check {display: block;width: 24px;height: 24px;position: absolute;top: 5px;right: 5px;background: url("/template/img/sprites.png") -552px -216px;  z-index: 9;}
.election_check:hover { opacity: 0.6}

.curday_election__list { display:block; line-height: 350px; height: 35px; margin-bottom: 3px;}
.curday_election__list:hover { opacity: 0.8}
.curday_election__line {margin-left: 35px;padding: 7px 0 7px 0}
.curday_election__image {width: 35px; height: 35px; float:left; background-size: cover; background-position: center;}
.curday_election__chart {height: 21px;min-width: 20px;background: #6ab7e2;position: relative;margin-right: 20px;border-right: 40px solid #6ab7e2;border-radius: 0 10px 10px 0}
.curday_election__percent {font-size: 11px;font-weight: bold;color: #ffffff;position: absolute;top: 0;right: -40px;height: 21px;line-height: 21px;display: block;width: 40px;text-align: center}

.yday_election {width: 100%; height: 200px; margin-top:70px}
.yday_election > li {border-right: 1px solid #FFF;position: relative;width: 8.333%;vertical-align: bottom;display: inline-block; min-height: 1px}
.yday_election > li img {height: 45px;  margin: auto;}

.yday_election__percent { font-size: 11px; line-height: 16px; font-weight:bold; text-align:center}

.yday_election_chart { position:absolute; left: 0; bottom: 100%; width: 100%}

.yday_election > li:nth-child(1) {background-color: #005ea0}
.yday_election > li:nth-child(2) {background-color: #0f6daf}
.yday_election > li:nth-child(3) {background-color: #1e7cbd}
.yday_election > li:nth-child(4) {background-color: #2886c7}
.yday_election > li:nth-child(5) {background-color: #3391d2}
.yday_election > li:nth-child(6) {background-color: #3d97d6}
.yday_election > li:nth-child(7) {background-color: #4596d0}
.yday_election > li:nth-child(8) {background-color: #549dd0}
.yday_election > li:nth-child(9) {background-color: #5ca1d2}
.yday_election > li:nth-child(10) {background-color: #68a8d6}
.yday_election > li:nth-child(11) {background-color: #7fbae4}
.yday_election > li:nth-child(12) {background-color: #94ccef}

.bike_election {background: #37b8eb}
.bike_election__header {text-align: center;line-height: 40px;font-size: 20px;color: #FFF}
.bike_election__btns {text-align: center;padding-bottom: 10px}
.bike_election__btns > .btn {border: 1px solid #FFF;color: #fff;display: inline-block;margin-right: 10px;font-size: 15px}
.bike_election__btns > .btn:hover {background: #fff;color: #2b3e46}
/*==============================*/

/*====== кому понравилось ======*/
.liked_prev_users {display: flex}
	.liked_prev_title {font-weight: bold}
	.liked_prev_users {margin:5px 0; height: 40px}
		.liked_prev_users > a {display: block;padding-right: 5px; position:relative}
		.liked_prev_users > a > img {width: 40px;border-radius: 20px}
		.liked_prev_users > a:hover { opacity: 0.8}
		.liked_prev_users > a:last-child {padding-right: 0}
/*==============================*/

/*====== ПОИСК РАЙДЕРОВ ===============*/
.riders_search, .bikelogs_search{
    display: flex; flex-direction: row; align-items: flex-start;
}
.riders_search-result {
    padding-top: 10px;
    padding-bottom: 10px;
}
.riders_search-content {
    flex: 1 1 auto;
}
.riders_search__head {
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    font-weight: 500;
    position: relative;
}

.riders_search__head.mbil{
    display: none;
}

.riders_search_filter-icon {
    position: absolute;
    top: 0;
    right: 5px;
    line-height: 20px;
    font-size: 20px;
    padding: 8px;
    color: #585858;
    display: none;
}
.riders_search-q-form {
    padding: 0 10px 0 50px;
    border-width: 1px 0;
    border-style: solid;
    border-color: #e6e6e6;
    position: relative;
    height: 50px;
}
.riders_search-icon{
    position: absolute;
    top: 16px;
    left: 20px;
    color: #000;
}
.riders_search-q-form input {
    border: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
    height: 100%;
}
.riders_search-q-form input::placeholder{
    color: #CCC;
    font-size: 14px;
}
.riders_search-q-form input:focus{
    border: none;
}
.radio_circle input[type=radio] { display: none}
.radio_circle input[type=radio]+label { display: block; padding-right: 24px; line-height: 30px; height: 30px; position: relative}
.radio_circle label:hover { color: var(--d1000)}
.radio_circle input[type=radio]+label::before { content: ''; display: block; position: absolute; top:5px; right: 5px; background: url("/template/img/radiobox_filter_gender.png") 0 0 no-repeat; width: 20px; height: 20px}

.radio_circle input[type=radio]:hover + label::before{
    background-position: 0 -40px;
}
.radio_circle input[type=radio]:checked+label::before{
    background-position: 0 -20px;
}
/*=====================================*/


.checkbox_circle input[type=checkbox] { display: none}
.checkbox_circle input[type=checkbox]+label {display: flex;align-items: center;gap: var(--gap-2)}
.checkbox_circle label:hover { color: var(--b800)}
.checkbox_circle input[type=checkbox]+label::before { content: ''; display: block; background: url("/template/img/radiobox_filter_gender.png") 0 0 no-repeat; width: 20px; height: 20px; flex: 0 0 auto}

.checkbox_circle input[type=checkbox]:hover + label::before{
    background-position: 0 -40px;
}
.checkbox_circle input[type=checkbox]:checked+label::before{
    background-position: 0 -20px;
}



/*====== FILTERS ======================*/
.filter_search{
    width: 280px;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    background: var(--white);
    box-shadow: 0 5px 23px rgba(0,0,0,.11);
    flex: 1 0 auto;
    border-radius: var(--base-radius);
}
.filter_search-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #313131;
    padding: 15px;
    display: none;
}
.filter_search-content {
    padding: 15px;
    border-radius: var(--base-radius);
}

.sidebar_box_title{
    font-size: 24px;
    font-weight: 500;
    color: var(--white);
}

.filter_box{
    padding: 15px 0;
}
.filter_box:first-child{
    padding-top: 5px;
}

.filter_box-title{
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.filter_location > div{
    padding-top: 10px;
}

.filter_box > div {
    padding-left: 7px;
    padding-right: 7px;
}

.filter_box__categories{
    display: flex;
    flex-direction: row;
}
.bikelogs_category_check{
    flex: 1 1 auto;
}

.filter_btn {
    padding: 10px 20px;
}
.filter_btn > .btn {
    width: 100%;
    line-height: 40px;
    height: 40px;
    border-radius: 7px;
}

/*====== Поиск юзеров для отметки =======*/
.mention{color: #00a1ff}

.usersList {
    padding: 5px 0;
    background: var(--d950);
    color: #FFF;
    border-radius: 8px;
    z-index: 9;
}

.userListUser{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userListUser > .photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    flex: 0 0 auto;
}

.userListUser > .photo > img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

button.usersList-item {
    background: transparent;
    border: none;
    color: #FFF;
    display: block;
    width: 100%;
    padding: 5px 10px;
}
button.usersList-item:hover{
    background: #1b2129;
}
button.usersList-item.active{
    background: #399bff !important;
}
/*=================================*/

/*====== превью юзера, мотоцикла и т.д.*/
.mode_prev_bike {width: 200px; height: 153px; overflow:hidden}
	.mode_prev_bike__image {width:200px; height: 112px; opacity: 0.9}
	.mode_prev_bike__image:hover { opacity: 1}
	.mode_prev_bike__title {line-height: 20px;font-weight: bold;max-height: 20px; overflow: hidden;margin: 5px 0 0 0}
	.mode_prev_bike__info { line-height: 16px; font-size: 11px; max-height: 16px; overflow:hidden}

.mode_prev_user {width: 290px;height: 70px;overflow: hidden;padding-left: 55px;position: relative}
.mode_prev_user__image {display: block;position: absolute;left: 0;top: 0;width: 50px}
.mode_prev_user__rides {line-height: 16px}
.mode_prev_user__image > img {width: 50px; border-radius: 100%}
.mode_prev_user__name {font-weight: bold;max-width: 100%;text-overflow: ellipsis;overflow: hidden;line-height: 20px;height: 20px}
/*======================================*/

/*=========== украденные ======*/
.st_s {width: 48%; display:inline-block; margin-right: 2%; position:relative;font-size:13px; height: 120px; padding: 10px; overflow:hidden; color: #444}
.st_s:hover {color:#444; opacity:0.8}
.st_s:nth-child(even) { margin-right:0}
.st_s__title {font-size:17px; font-weight:bold; position:relative; padding-right: 40px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis}
.st_s__date {font-size:11px; padding-bottom: 7px; font-weight:bold}
.st_s__image {border-radius: 3px}
.st_s__prev {width: 100px; height: 100px; position:absolute; top: 10px; left:10px}
.st_s__data {padding-left: 5px; position:relative; margin-left: 105px}

.st_s__views, .st_s__comm { position:absolute; top: 15px; right: 10px; font-size:11px; font-weight:normal; color:#CCC}
.st_s__comm {top: auto; bottom: 15px; left: 120px}

.st_s__reward {position: absolute;bottom: 10px;right: 0;background: #1585d8;color: #FFF;padding: 3px 5px;border-radius: 3px 0 0 3px; display:block}
.st_s__reward.help {background:#ff8300}

.st_map {display:block; width: 200px; color:#444}
.st_map:hover { opacity: 0.9; color:#444}
.st_map__title {font-size: 15px; font-weight:bold;padding: 5px 0;}
.st_map__img {width:100%}

.stolen_nav {margin-bottom: 10px}
.stolen_nav > a {display: inline-block;padding:10px; margin-left:5px; color:#444}
.stolen_nav > a:hover {color:#0AAAE4}
.stolen_nav > a.active {background: #1585d8;color: #FFF}

.stolen_filters {border-top: 1px solid #ecf0f2}
.stolen_filters .dropmenu {position: relative;width: 33%;display: inline-block;text-align: center;line-height: 40px;border-right: 1px solid #ecf0f2;}
.stolen_filters .dropmenu .dropmenu__btn { display:block; padding:0 10px; cursor: pointer}
.stolen_filters .dropmenu .dropmenu__btn:hover { font-weight:700}
.stolen_filters .dropdown_menu { background: #FFF; box-shadow: 0 3px 3px rgba(0,0,0,0.2); width: 100%}
.stolen_filters .dropdown_menu > a {display: block; text-align:left; padding: 5px; color: #4a4a4a; border-top: 1px solid #f5f5f5}
.stolen_filters .dropdown_menu a.active {font-weight: 700}
.stolen_filters .dropdown_menu a:hover { opacity: 0.8}

.stolen_filters .dropmenu:last-child { border-right: 0}

.st__search {position: relative;padding: 3px 7px;height: 32px;margin: 3px;border: 1px solid #1585d8;border-radius: 3px;overflow: hidden}
.st__search-inp::placeholder {color: #1585d8}

input[type=text].st__search-inp {padding: 0 55px 0 0;height: 24px;border: 0;margin: 0; color: #1585d8;}
.st__search-btn {position: absolute;width: 50px;height: 32px;display: block;top: -1px;right: -1px;margin: 0;padding: 0 5px;border: 0;color: #FFF; background:#1585d8}

.st__params_list { margin-bottom: 15px; float:left}
.st__params_list li {margin-bottom: 5px}
.st__params_list .fpr{float: left;font-weight: bold;text-align: left;width: 150px}
.st__params_list .spr{line-height: 20px;margin-left: 150px}

#flexslider_stolen {float:left; width: 100%; max-width: 470px; margin: 0 10px 10px 0}

.st__contacts {margin: 15px 0 0 0;padding: 15px;background: #f1f1f1;border: 1px solid #e2e2e2;border-radius: 3px}
.st__contacts li {padding-bottom: 5px}

.new_stolen {background: #fdffa7}
/*==============================*/


/*============ АНИМАЦИЯ AJAX ПОДГРУЗКИ =======================*/

.loaderbox-container, .loaderbox-overlay{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
}

.loaderbox-overlay{
    background: rgba(0,0,0,0.3);
    z-index: 1;
}

.loaderbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.loaderbox-container .loaderbox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.loader {
    width: 50px;
    height: 50px;
    position: relative;
    border-top: 5px solid rgba(72, 72, 72, 0.2);
    border-right: 5px solid rgba(72, 72, 72, 0.2);
    border-bottom: 5px solid rgba(72, 72, 72, 0.2);
    border-left: 5px solid #ffffff;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader .6s infinite linear;
    animation: loader .6s infinite linear;
}
.loader-sm{
    width: 20px;
    height: 20px;
    border-top: 2px solid rgba(72, 72, 72, 0.2);
    border-right: 2px solid rgba(72, 72, 72, 0.2);
    border-bottom: 2px solid rgba(72, 72, 72, 0.2);
    border-left: 2px solid #ffffff;
}
button.btn_send .loader{
    width: 18px;
    height: 18px;
    border-top: 2px solid rgba(72, 72, 72, 0.2);
    border-right: 2px solid rgba(72, 72, 72, 0.2);
    border-bottom: 2px solid rgba(72, 72, 72, 0.2);
    border-left: 2px solid #ffffff;
}
@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/*===============================================*/



/*============ (jCrop)========================================*/
.jcrop-holder { direction: ltr; text-align: left; }
.jcrop-vline,
.jcrop-hline { background: #FFF url("/template/img/Jcrop.gif") top left repeat; font-size: 0; position: absolute; }
.jcrop-vline { height: 100%; width: 1px!important; }
.jcrop-hline { height: 1px!important; width: 100%; }
.jcrop-vline.right { right: 0; }
.jcrop-hline.bottom { bottom: 0; }
.jcrop-handle { background-color: #333; border: 1px #eee solid; font-size: 1px; }
.jcrop-tracker { height: 100%; width: 100%; -webkit-tap-highlight-color: transparent; -webkit-touch-callout: none; -webkit-user-select: none; }
.jcrop-handle.ord-n { left: 50%; margin-left: -4px; margin-top: -4px; top: 0; }
.jcrop-handle.ord-s { bottom: 0; left: 50%; margin-bottom: -4px; margin-left: -4px; }
.jcrop-handle.ord-e { margin-right: -4px; margin-top: -4px; right: 0; top: 50%; }
.jcrop-handle.ord-w { left: 0; margin-left: -4px; margin-top: -4px; top: 50%; }
.jcrop-handle.ord-nw { left: 0; margin-left: -4px; margin-top: -4px; top: 0; }
.jcrop-handle.ord-ne { margin-right: -4px; margin-top: -4px; right: 0; top: 0; }
.jcrop-handle.ord-se { bottom: 0; margin-bottom: -4px; margin-right: -4px; right: 0; }
.jcrop-handle.ord-sw { bottom: 0; left: 0; margin-bottom: -4px; margin-left: -4px; }
.jcrop-dragbar.ord-n,
.jcrop-dragbar.ord-s { height: 7px; width: 100%; }
.jcrop-dragbar.ord-e,
.jcrop-dragbar.ord-w { height: 100%; width: 7px; }
.jcrop-dragbar.ord-n { margin-top: -4px; }
.jcrop-dragbar.ord-s { bottom: 0; margin-bottom: -4px; }
.jcrop-dragbar.ord-e { margin-right: -4px; right: 0; }
.jcrop-dragbar.ord-w { margin-left: -4px; }
.jcrop-light .jcrop-vline,
.jcrop-light .jcrop-hline { background: #FFF; filter: Alpha(opacity=70)!important; opacity: .70!important; }
.jcrop-light .jcrop-handle { -moz-border-radius: 3px; -webkit-border-radius: 3px; background-color: #000; border-color: #FFF; border-radius: 3px; }
.jcrop-dark .jcrop-vline,
.jcrop-dark .jcrop-hline { background: #000; filter: Alpha(opacity=70)!important; opacity: .7!important; }
.jcrop-dark .jcrop-handle { -moz-border-radius: 3px; -webkit-border-radius: 3px; background-color: #FFF; border-color: #000; border-radius: 3px; }
.jcrop-holder img,
img.jcrop-preview { max-width: none; }

#popup_container {
	font-family: Arial, sans-serif;
	font-size: 12px;
	min-width: 300px; /* Dialog will be no smaller than this */
	max-width: 600px; /* Dialog will wrap after this width */
	background: #FFF;
	border: solid 5px #999;
	color: #000;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

#popup_title {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	line-height: 1.75em;
	color: #666;
	background: #CCC top repeat-x;
	border: solid 1px #FFF;
	border-bottom: solid 1px #999;
	cursor: default;
	padding: 0;
	margin: 0;
}

#popup_panel {text-align: center;margin: 1em 0 0 1em}

#popup_prompt {margin: .5em 0}

.video_object { position: relative; padding-bottom: 56.25%; height: 0; overflow:hidden; margin-top: 5px;}
.video_object iframe,
.video_object object,
.video_object .MruPlayer,
.video_object embed {position: absolute; top: 0; left: 0; width: 100%;height: 100%}

.ui-helper-hidden-accessible{
    display: none;
}

/*======== VOTING ================================================*/
.cur_title { text-align:center; font-weight:bold; font-size: 19px; padding: 20px 10px 15px 10px;text-decoration: underline;}
.cur_con { padding: 0 20px}
.cur_prize {float:left; padding-top: 5px}
.cur_prize img {width: 200px; height: 200px; border-radius: 50%}
.cur_descr {margin-left: 220px}
.cur_stats {clear: both;text-align: center;padding: 20px 0 0 0;display: flex; width: 100%}
	.cur_stats > div {border-right: 1px solid #FFF; padding: 10px 20px; text-align:center; background: #6ab7e2; color: #FFF}
	.cur_stats > div:last-child { border: 0}

	.c_tit {padding-bottom: 10px}
	.c_tit > img { height: 30px; margin:auto}
	.c_dt {font-style: italic}

	.cur_date  { width: 50%}
	.cur_memb, .prize-text { width: 25%}


.cur_yday {padding-top: 20px; text-align: center}
.cur_yday h3 {color: #444}

.cur_last_members {padding: 20px 0 0 0; background: #f7f7f7; border-bottom: 1px solid #eaeaea}

.cur_con h3 { margin-bottom: 10px}
.cur_con ul { margin-left: 30px}
.cur_con ul li { list-style:disc}

.con_cur_last_members { display:flex; width: 100%; padding-bottom: 15px;}

.con_last_member {width: 8.333%; display:block; text-align:center}
.con_last_member img { width: 70%; border-radius: 50%; display:inline-block; text-align:center}
.con_cur_last_members_bottom {text-align: center; padding-bottom: 15px}

.contest_ended_full {padding: 20px;background: #ec5b62;margin-bottom: 20px;text-align: center;color: #FFF;font-size: 20px}

.el_give_vote {position: absolute;background: rgba(0, 0, 0, 0.6);z-index: 2;color: #FFF;padding: 7px 15px;border-radius: 3px;top: 50%;left: 50%;transform: translate(-50%, -50%);cursor: default; opacity: 0; overflow: hidden; user-select: none; -webkit-user-select: none; white-space: nowrap;}
.el_give_vote:hover{font-size: 1.2em}
.short_bike:hover .el_give_vote, .voting_photo:hover .el_give_vote {opacity: 1}
.el_give_vote:before {content: '';position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);background: rgba(27, 123, 179, 0.32);width: 0;height: 0;border-radius: 50%}
.el_give_vote.click_wave:before { -webkit-animation: click_wave 0.25s linear forwards; animation: click_wave 0.25s linear forwards}

.voting_data {position: absolute;top: 0;left: 0;z-index: 1;color: #FFF;display: flex;width: 100%;line-height: 36px;padding: 0 5px;background: rgba(0,0,0,0.5);font-size: 15px}
.voting_data > div { width: 25%; text-align:center}

@-webkit-keyframes click_wave {
	from { width: 0; height: 0}
	to { width: 300px; height: 300px}
}

@keyframes click_wave {
	from { width: 0; height: 0}
	to { width: 300px; height: 300px}
}

@keyframes ghost {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}

.members_full__title {
	padding-bottom: 20px;
	text-align: center;
}


.voting_photo_members {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.voting_photo_members > li{
	width: 33.333%;
	display: block;
	position: relative;
	font-size: 13px;
	min-height: 160px;
	padding: 0 10px 10px 0;
	overflow: hidden;
	color: #444;
}

.voting_photo {
	width: 100%;
	padding-top: 65%;
	position: relative;
	overflow: hidden;
	border-radius: 3px;
	background: #FFF;
}

.voting_photo_image{
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
}

/*==== =====*/
.short_for_main .short_bike {border-bottom: 0; margin: 0}
.short_rule_block {flex-shrink: 0; width: 270px; border-right: 1px solid #eaeaea;}
.short_rule_block .hd{
    text-align: center; font-weight: 500; line-height: 50px;
}




/*================*/
.goToTop {
    position: fixed;
    bottom: 0;
    right: 34px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00adff;
    border-radius: 50%;
    color: #FFF;
    transform: rotate(-180deg);
    font-size: 2em;
    opacity: 0;
    transition: all ease-in-out .15s;
    box-shadow: 0 0 11px #00000042;
    z-index: 88;
}

.goToTop.active{
    opacity: 1;
    bottom: 34px;
}
/*=================*/

.routeStepsFull{

    display: flex;
    flex-direction: column;
    gap: 2rem;

}

.routeStepsFull .step{

    position: relative;
    background: var(--d800);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    padding: 1rem;

}

.routeStepsFull .step::before{
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 2em;
    top: 100%;
    left: 2.3rem;
    background-color: var(--b800);
}
.routeStepsFull .step:last-child::before{
    display: none;
}

.routeStepsFull .stepHeader-type{
    width: 44px;
}

.routeStepsFull .stepHeader-type .icon{
    position: relative;
}

.routeStepsFull .stepHeader{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.routeStepsFull .stepBody{

    margin-top: 10px;

}

.routeStepsFull .stepHeader-info{
    display: flex;
    flex-direction: column;
    gap: 0.5rem
}




/*=================*/





.owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    font-size: 60px;
    /*position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;*/
    width: 100%;
    height: 0;
    display: flex;
    justify-content: space-between;
}
.owl-theme .owl-nav [class*=owl-]:hover{
    color: #00b7ff !important;
    background: transparent !important;
}

.owl-prev, .owl-next{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin: 0;
    padding: 10px;
}

.owl-next{
    left: auto;
    right: 0;
}

.owlCustomButton {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    width: 60px;
    font-size: 2em;
}

.owlCustomButton i{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%
}

.owlCustomButton-next{
    right: 0;
    left: auto
}

.owlCustomButton:hover{
    color: var(--b800)
}

.gm-style {
    outline: none !important;
}


.business-container{

}

.businessHours{

}

.businessHours__item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.upload-image-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: var(--gap-1);
    text-align: center;
    text-wrap: nowrap;
}

.business-logos{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 12px 0;
}

.business-logos-item,
.business-video{
    display: block;
    background: var(--d800);
    border: 1px dashed var(--d540);
    border-radius: var(--base-radius-16);
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 20px rgba(0,0,0,0.4);
}

.upload-image-editbtn{
    display: none;
    position: absolute;
    top: var(--padding-2);
    right: var(--padding-2);
    background: rgba(100, 100, 100, 0.50);
    backdrop-filter: blur(4px);
    padding: var(--padding-2) var(--padding-3);
    border: none;
    color: var(--white);
    border-radius: var(--base-radius-16);
    z-index: 2;
}

.business-logo{
    width: 20%;
    aspect-ratio: 1 / 1;
}

.business-logo.active .upload-image-empty{
    display: none;
}

.business-logo.active .upload-image-editbtn{
    display: block;
}


.business-video{
    width: 50%;
    aspect-ratio: 16 / 9;
    position: relative;
}

.business-video.active .upload-image-empty{
    display: none;
}

.business-video.active .upload-image-editbtn{
    display: block;
}


.business-video-uploadContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}


.business-cover.active .upload-image-empty{
    display: none;
}

.business-cover.active .upload-image-editbtn{
    display: block;
}

.business-cover{
    width: 80%;
    aspect-ratio: 16 / 4;
}

.business_add_prices__list{
    display: flex;
    flex-direction: column;
    gap: 0.75em;
}
.business_add_prices-add{
    user-select: none;
}

.business_add_hours__item{
    display: flex;
    align-items: center;
    line-height: 42px;
}

.business_add_hours__item .time{
    display: none;
}

.business_add_hours__item.active .time{
    display: inherit;
}

.business_add_hours__item > div{
    padding: 0 10px;
}

.business_attach_box .file_upload_btn{
    text-indent: initial;
}

.business_attach_box .file_upload_btn{
    background-image: none;
}

.business_add_services_list_service {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-4);
}

.business_add_services-category{
    display: flex;
    flex-direction: column;
    gap: var(--gap-3);
}

.business_add_services_list_service .business_add_services_list_service-item{
    width: 20%;
    line-height: 1em;
    user-select: none;
}


.business_faq_editbtn {
    background: var(--d600);
    backdrop-filter: blur(4px);
    padding: var(--padding-2) var(--padding-4);
    border: none;
    color: var(--white);
    border-radius: var(--base-radius-16);
}


.business_social_media-list > div:last-child{
    margin-bottom: 20px;
}

.business_social_media-add{
    user-select: none;
    color: var(--b800);
}

.business_social_media-add:hover{
    color: var(--b500)
}

.business_social_media__item i.icon-cross:hover{
    color: var(--r1000)
}

.businessSaveBtn{
    background-color: var(--b800);
    font-size: 18px;
    padding: 0.75em 2em;
    border-radius: 100px;
    color: white;
    border: none
}



.addReviewModal{
    display: block;
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 333;
}

.addReviewModal__container{
    display: block;
    position: absolute;
    overflow-y: scroll;
    max-height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 90%;
    max-width: 500px;
    background: var(--d900);
    padding: 20px;
    border: 1px solid var(--d750);
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.addReviewModal__container-header{
    text-align: center;
    font-size: 18px;
}

.addReviewModal-title{
    position: relative;
}

.addReviewModal__container .crossModal{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    background: none;
    border: none;
    color: var(--white);
    padding: 20px;
}

.addReviewModal__container .crossModal:hover{
    color: var(--d540);
}

.addReviewModal__container-body {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.addReviewModal__overlay{
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 0;
}

.saveReviewBtn{
    line-height: 40px;
    width: 100%;
    border-radius: 50px;
}



.reviewBusinessInfo {
    width: calc(100% + 40px);
    margin-left: -20px;
    height: 100px;
    position: relative;
}

.reviewBusinessInfo-cover{
    opacity: 0.5;
}

.businessReviewForm .editableRateContainer{
    margin: 20px;
}

.businessReviewForm .rateStar.editableRateStars{
    font-size: clamp(1.8rem, 6vw, 3rem);
}

.businessReviewForm .file_upload_btn {
    text-indent: initial;
    width: 25%;
}


.reviewUserInfo-avatar img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}



.inputWithIcon{
    position: relative;
}
.inputWithIcon input{
    padding-left: 46px;
}
.inputWithIcon i {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
    color: var(--g300);
    font-size: 18px;
}

.reviewImagesBox{
    gap: var(--gap-2)
}
.reviewImagesBox .image_box{
    width: calc(25% - var(--gap-2));
}


@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 2) {
    .radio_circle input[type=radio]+label::before {
        background: url("/template/img/radiobox_filter_gender@2xx.png") 0 0 no-repeat;
        width: 20px;
        height: 20px;
        background-size: 100%;
    }
}

.position_absolute_center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.position_absolute_full{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}



.flex-row{display: flex;width: 100%; flex-direction: row !important;}
.flex-col {display: flex; flex-direction: column !important;}
.inline-flex{display: inline-flex}
.flex-between{justify-content: space-between}
.flex-around{justify-content: space-around}
.flex-center{align-items: center}
.flex-start{align-items: flex-start !important}
.flex-wrap{flex-wrap: wrap !important}

.inline-flex{
    display: inline-flex !important;
    width: auto;
}

.align-items-center{align-items: center}

.justify-content-center{justify-content: center !important;}
.justify-content-end{justify-content: flex-end !important;}



.tooltipster-sidetip .tooltipster-box{
    background: rgba(20, 27, 34, 0.77) !important;
}

.premium-avatar{
    position: relative;
}
.premium-avatar::before{
    content: '\e900';
    font-family: "icomoon";
    display: block;
    bottom: -2px;
    right: -2px;
    background: var(--b800);
    color: white;
    width: 14px;
    height: 14px;
    position: absolute;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    border-radius: 50%;
    z-index: 9;
}

