body.dark{
    background: var(--d700);
    color: var(--g100);
}

body.dark a{
    color: var(--g100);
}
body.dark a.hashtagLink{
    color: var(--b800);
}

body.dark #editorjs a{
    color: var(--b500);
}

body.dark .news__content a{
    color: var(--b800);
}

body.dark a.mention,
body.dark .n_story__block a{
    color: var(--b800);
}

body.dark a.mention:hover,
body.dark .n_story__block a:hover{
    color: var(--b200);
}

body.dark .box_wht, body.dark .box_wht-mb-20{
    background: var(--d900);
}

body.dark .box_wht__header-min a{
    color: var(--b400);
}
body.dark .not_notify{
    border: 1px dashed var(--d600);
    color: var(--d600);
    background: none;
}

body.dark .bikelogs_tags > a {
    background: var(--d700);
    color: var(--g100);
}
body.dark .bikelogs_tags > a:hover {
    background: var(--d600);
    color: var(--white);
}

body.dark .header {
    background: var(--d900);
    /*background: -webkit-linear-gradient(45deg, rgb(34, 53, 67) 0%, rgb(53, 92, 113) 100%);
    background: -o-linear-gradient(45deg, rgb(34, 53, 67) 0%, rgb(53, 92, 113) 100%);
    background: linear-gradient(45deg, rgb(34, 53, 67) 0%, rgb(53, 92, 113) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#482cbf', endColorstr='#6ac6f0', GradientType=1);*/
}

body.dark .bottom_menu {
    background: var(--d950);
    border-color: var(--d800);
}

body.dark .bottom_menu > a, body.dark .bottom_menu > div{
    color: var(--g100);
}

body.dark .speedbar{
    background: var(--d900);
    border: none;
}

body.dark .fe_helper-category > span {
    color: var(--g300);
    background: var(--d700);
}

body.dark .fe_title{
    color: var(--white);
}

body.dark .userlist__item:hover{
    background: var(--d950);
}

body.dark .riders_search-icon {
    color: var(--g100);
}


body.dark .riders_search-q-form{
    border-color: var(--d700);
    background: var(--d950);
}

body.dark .riders_search-q-form input{
    background: var(--d950);
}

body.dark .chosen-container-single .chosen-single, body.dark .chosen-container-multi .chosen-choices{
    background: var(--d950) !important;
    border-color: var(--d700) !important;
}

body.dark .chosen-container-multi .chosen-drop .result-selected{
    background: var(--d800);
}

body.dark .speedbar_profile{
    border-bottom: 1px solid var(--d800);
}

body.dark .speedbar_profile__user{
    color: var(--g300);
}

body.dark .empty_block__content {
    color: var(--g100);
}

body.dark .empty_block__image > svg{
    fill: var(--g100)
}

body.dark .counter_items {
    background: var(--d950);
}

body.dark .counter_item{
    color: var(--g100);
}

body.dark .prof_last_visited{
    color: var(--d600);
}

body.dark footer {
    background: var(--d950);
}

body.dark .boundContentTabs{
    border-bottom-color: var(--d800);
}

body.dark .profile_full_cover{
    box-shadow: 0 -80px 100px inset var(--d800);
}

body.dark .profile_foto{
    border-color: var(--d700);
    background: var(--d950);
}

body.dark .p_actbuttons > .btn.editProfile{
    color: var(--g100);
    background: transparent;
    border: 1px solid var(--g100);
}

body.dark .friends_list_min__friend-inner:hover {
    background: var(--d950);
}

body.dark .garage_item {
    border-bottom-color: var(--d800);
}

body.dark a.garage_item:hover {
    color: var(--g100);
    background: var(--d950);
}

body.dark input[type=text], body.dark input[type=password], body.dark input[type=number], body.dark select {
    border-color: var(--d700);
    color: var(--g100);
}

body.dark input[type=text]::placeholder, body.dark input[type=password]::placeholder, body.dark input[type=number]::placeholder {
    color: var(--d600)
}

body.dark input, body.dark textarea, body.dark select {
    background-color: var(--d950);
}

body.dark .btn{
    background: var(--b800);
}
body.dark .btn:hover{
    background: var(--b400);
}

body.dark .fbike_counters {
    border-color: var(--d800);
}

body.dark h1, body.dark h2, body.dark h3{
    color: var(--white);
}

body.dark .fbike_owner > a {
    color: var(--g300);
}

body.dark .add_comments__area{
    border-color: var(--d950);
    color: var(--g300);
    background: var(--d950);
}

body.dark .add_comments__area:focus{
    border-color: var(--b400);
}

body.dark .addShortCommentFrom  .text{
    background: var(--d800);
    color: var(--g100);
}

body.dark .filter_box-title{
    color: var(--g100);
}

body.dark .filter_search, body.dark .filter_search-content {
    background: var(--d900);
}

.filter_search-close {
    color: var(--g100);
}

body.dark .bikelog_last {
    border-color: var(--d800);
}
body.dark .bikelog_last__item:hover {
    background: var(--d950);
}

body.dark .bikelog_last__title {
    color: var(--g300);
}

body.dark .show_all:hover {
    background: var(--d950);
}

body.dark .show_all a {color: var(--g300)}

body.dark .follow.active, body.dark .friend.active {
    background: var(--d600);
}

/*====== Подсветить временно элемент ================*/
body.dark .hlight { -webkit-animation: hlight_dark 2s; animation: hlight_dark 2s }
/*===================================================*/

body.dark .comment {
    border-bottom: 1px solid var(--d800);
}

body.dark .latest_comment:hover {
    background: var(--d950);
}

body.dark a.latest_comment-post{
    color: var(--d400);
}

body.dark a.latest_comment-post:hover{
    color: var(--b800);
}

body.dark .otv{
    color: var(--d540);
}

body.dark .n_post__header > a{
    color: var(--g300);
}

body.dark .s_event{
    color: var(--g100);
}

body.dark .waves-effect .waves-ripple {
    background: rgba(0, 0, 0, 0.2);
    background: -webkit-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 255, 0) 70%);
    background: -o-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 255, 0) 70%);
    background: -moz-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 255, 0) 70%);
    background: radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(255, 255, 255, 0) 70%);
}

body.dark .n_news_cat > a {
    color: var(--white);
    background: var(--b800);
}

body.dark .share_btn > button {
    background: var(--d700);
    color: var(--white);
    border: 0;
}

body.dark .radio_site > .radio_site__item > input[type=radio]+label{
    background: var(--d700);
    color: var(--white);
    border: 0;
}

body.dark .radio_site > .radio_site__item > input[type=radio]+label:hover{
    background: var(--d600);
}

body.dark .radio_site > .radio_site__item > input[type=radio]:checked + label{
    background: var(--b800);
}

body.dark .show_full_text-btn{
    background: var(--d950);
    box-shadow: none;
    color: var(--g100);
}

body.dark .show_full_text{
    background: none;
}

body.dark #clsLink3 a {
    border: 1px solid var(--d950);
    background-color: var(--d900);
    color: var(--white);
}

body.dark #clsLink3 span {
    background-color: var(--b800);
}

body.dark .sidebar_cats a{
    color: var(--g300);
    background: none;
}

body.dark .sidebar_cats a:hover, body.dark .sidebar_cats a.active, body.dark .sidebar_cats a.actlink {
    background: var(--d950);
    color: var(--white);
}


body.dark .full_page_spacer_title{
    color: var(--white);
}

body.dark .rules_title,
body.dark .short_rule_block,
body.dark .rule_group
{
    border-color: var(--d700);
}

body.dark .rule li span {
    color: var(--g300);
}

body.dark .rules_summary li:hover {
    background: var(--d700);
}

body.dark .spoiler_text {
    background: var(--d700);
    border-radius: 5px;
}

body.dark .bikes_menu a{
    background: var(--d700);
}
body.dark .bikes_menu a{
    color: var(--g100);
}

body.dark .bikes_menu a:hover, body.dark .bikes_menu a.active{
    background: var(--b800);
}

body.dark .show_bikes_menu {
    background: var(--d950);
    border-top-color: var(--d800);
}

body.dark .show_bikes_menu:hover {
    background: var(--d800);
}

body.dark .short_count_bikelogs {
    line-height: 28px;
    color: var(--g300);
    background: var(--d700);
    border: 0;
}

body.dark .short_bike{
    background: var(--d900);
}

body.dark .short_bike_title {
    color: var(--g100);
}

body.dark .short_bike__user > .name{
    color: var(--b600);
}

body.dark .rect_bl-wrap, body.dark .rect_bl-btm {
    color: var(--g100);

}

body.dark .rect_bl{
    background: var(--d950);
    box-shadow: none;
}

body.dark .bikes_recbikelogs > .head {
    color: var(--white);
}

body.dark .short_bike__loc {
    opacity: 0.7;
}



/*===== chosen =====*/
body.dark .chosen-container .chosen-drop {
    border-color: var(--d700);
    background: var(--d950);
}

body.dark .chosen-container-single .chosen-search input[type=text]{
    border-color: var(--d700);
}

body.dark .chosen-container .chosen-results li {
    color: var(--g100);
}

body.dark .chosen-container .chosen-results li.highlighted {
    background-color: var(--d700);
    background: var(--d700);
}

body.dark .chosen-container .chosen-results li.no-results {
    color: var(--d540);
    background: var(--d800);
    padding: 20px;
    text-align: center;
    border-radius: 3px;
    border: 1px dashed var(--d540);
}

body.dark .chosen-container-single .chosen-single span {
    color: var(--g100);
}
body.dark .ui-autocomplete {
    background: var(--d950);
    border-color: var(--d700);
}

body.dark .ui-menu-item-wrapper {
    border-bottom: 1px solid var(--d800);
}

body.dark .list_city {
    border-bottom: 1px solid var(--d700);
}

body.dark .ui-widget-content .ui-state-active {
    background: var(--d800);
}


body.dark .form-item__label, .form-item__label-f{
    color: var(--g100);
}

body.dark .form-item textarea {
    border-color: var(--d700);
    color: var(--g100);
}


body.dark .eventsSearch-loc {
    border-color: var(--d700);
}

body.dark .r_event{
    color: var(--g100);
    border-color: var(--d950);
}
body.dark .r_event:hover{
    background: var(--d950);
    color: var(--b600);
}

body.dark .relatedEvent-info{
    background: var(--d800);
}
body.dark .titleContent > .event_loc {
    opacity: 1;
    color: var(--d400);
}

body.dark .relatedEvent-meta{
    background: var(--d900);
}

body.dark .full_event-content h4{
    color: var(--white);
}

body.dark .slidemenu{
    background: var(--d900);
}

body.dark .slidemenu li::after{
    background: var(--d800);
}

body.dark .slidemenu__search > a{
    border: 1px solid var(--d700);
    background: var(--d950);
}

body.dark .feed_switcher__inner{
    background: var(--d800);
}

body.dark .short_post_url{
    color: var(--g100);
}

body.dark .n_post__bottom a{
    color: var(--g100);
}

body.dark .sidebar__usermenu-listmenu a, body.dark .sidebar__fullmenu-listmenu a{
    color: var(--g100)
}

body.dark .sidebar__usermenu-listmenu a:hover,
body.dark .sidebar__fullmenu-listmenu a:hover,
body.dark .sidebar__fullmenu-listmenu .actlink,
body.dark .sidebar__usermenu-listmenu .actlink{
    color: var(--b800);
}


body.dark .profile_full_info{
    background: var(--d900);
}

body.dark .userlist__item-info{
    color: var(--g100);
}

body.dark .radio_circle label:hover{
    color: var(--b800);
}

body.dark a.n_news_url{
    color: var(--g100);
}

body.dark .rel_post__owner:hover{
    color: var(--b800);
}

body.dark .online_status {
    border-color: var(--d800);
}

body.dark .min_info {
    color: var(--d400);
}

body.dark .dots_menu > button{
    color: var(--d540);
}

body.dark .profile_full_cover > .dots_menu > button {
    color: var(--g100);
}

body.dark .dots_menu > button:hover{
    color: var(--d600)
}

body.dark .dropdown_menu{
    background: var(--d700);
    box-shadow: 0 0 20px var(--d900);
}

body.dark .dropdown_menu--spacer{
    background: var(--d800);
}

body.dark .dropdown_menu--item:hover{
    background: var(--d400);
}

body.dark .use_app{
    background: var(--d600);
}

body.dark .form-item__spacer{
    background: var(--d700);
}
body.dark .file_upload_btn {
    background: var(--d700);
    background-image: url(/template/svg/add_photo.svg);
    background-repeat: no-repeat;
    background-position: center;
}

body.dark .btns_select > label{
    background: var(--d800);
}
body.dark .btns_select > input:checked+label{
    background: var(--b800);
    color: var(--white);
}

body.dark .btns_select > label:hover {
    background: var(--d950);
}

body.dark .see-all{
    color: var(--b800);
}

body.dark .findLocationInput{
    background: none;
}

body.dark .full_page_spacer_title > a {
    color: var(--b800);
}

body.dark .merchContainer ul{
    margin: 0;
}
body.dark .merchContainer a{
    color: var(--g50);
}

body.dark .ce-popover,
body.dark .ce-inline-toolbar{
    color: var(--d540);
}

body.dark .ce-inline-toolbar input{
    background: white;
}

body.dark .cdx-button{
    border:none;
    background: var(--d600);
    color:#FFF;
}

body.dark .ce-toolbar__plus svg,
body.dark .ce-toolbar__settings-btn svg{
    color: #FFF;
}

body.dark .routePreview{
    background: var(--d700);
}

body.dark .routePreview-bottom .routeSteps {
    border-top: 1px solid var(--d600);
}

@media screen and (max-width: 1100px){
    body.dark .sidebar_cats a{
        color: var(--g300);
        background: var(--d700);
    }

    body.dark .sidebar_cats a:hover, body.dark .sidebar_cats a.active, body.dark .sidebar_cats a.actlink {
        background: var(--b800);
        color: var(--white);
    }
}