:root{
    --white: #FFF;

    --g900: #212529;
    --g800: #343a40;
    --g700: #495057;
    --g600: #6c757d;
    --g500: #adb5bd;
    --g400: #ced4da;
    --g300: #dee2e6;
    --g200: #e9ecef;
    --g100: #f8f9fa;

    --gb: #ecf0f2;

    --j900: #152734;
    --j800: #273e4e;

    --d1000: #131313;
    --d950: #101419;
    --d900: #151a20;
    --d800: #1b2129;
    --d750: #20262b;
    --d700: #27303a;
    --d640: #393c3f;
    --d600: #475463;
    --d540: #545b64;
    --d400: #6a727b;

    --b1100: #00abff;
    --b1000: #238ae1;
    --b800: #1aa7ec;
    --b600: #1d9ad8; /* только  на сайте */
    --b500: #75b0e4;
    --b400: #59c1e8;
    --b200: #c0cfdf; /* Тусклый синий для текстов иногда */

    --green800: #0dc90d;
    --green400: #81b91e;

    --y700: #ffa200;
    --y300: #fffba8;

    --r1100: #b31b23;
    --r1000: #ff0000;
    --r200: #ff5400;
}