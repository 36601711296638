@media screen and (max-width: 900px) {

    .not_found-cc{
        flex-direction: column-reverse;
        padding: 30px 0;
        text-align: center;
    }
    .not_found-cc_img{
        padding-bottom: 30px;
    }
    .not_found ul > li {
        display: inline-block;
    }


    /*=============================*/
    .video_inner { border:0}
    .video_relative_block { width: 100%; margin-right: 0; position:relative}
    .video_comments {clear:both}
    .pagebox__main {padding: 0 10px}
    .profile_header{border-radius: var(--base-radius-16); overflow: hidden}
    .video_inner .pagebox__main { float:none}
    /*=============================*/

    .relative_posts {
        padding: 0 10px;
    }

    .bottom_menu{
        display: flex;
        align-items: stretch;

    }

    .goToTop.active{
        bottom: 80px;
    }

    .notify_block{
        display: none;
    }

    .sly .arrow{
        display: none;
    }

    .rec_item__close{
        display: block;
    }

    .prof__name,
    .content__l,
    .content__r,
    .conteiner_main:before,
    .conteiner_main:after,
    .header__menu { display: none}

    .logo img{
        height: 30px;
    }

    #container_full{
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .header{
        height: 50px;
    }
    .mapContainer.fullMap{
        top: 53px;
        height: calc(100% - 53px); /* htader + footer */
    }

    .use_app {
        margin-right: 0;
        padding: 3px 20px;
    }

    .speedbar_profile{
        padding: 10px;
    }

    a.n_news_url{
        flex-direction: column;
    }
    .n_news-image{
        width: 100%;
        padding: 10px 15px 0 15px !important;
    }
    .n_news-preview{
        width: 100%;
        height: calc(100vw * 0.52)
    }
    .n_news-text{
        padding: 0 15px;
    }

    .news__content p img, .news__content p iframe{
        max-width: none;
        width: calc(100% + 30px) !important;
        margin-left: calc(-15px) !important;
        margin-right: calc(-15px) !important;
    }

    .pagebox__inner {display:block; }
    .block__l {width: 100%; margin-right: 0}

    .slidemenu__btn { display: block }
    .pagebox__bike { max-width: none }
    .prof { padding-right: 0 }
    .short_for_main li { width: 25% }
    .short_for_main li:last-child { display: none }

    .form-item__label-f, .form-item__control { display:block; width: 100%; line-height: 20px;}

    .short_bikes li {width:33.333%}

    .spoiler_click { border-bottom:0}
    .spoiler_click:before { display:inline-block}
    .spoiler_bikes_menu, .events_cat_spoiler, .spoiler_bikes_filters, .spoiler_bikeslogs_filters, .spoiler_users_filters {display:none}

    /*=== ивенты ===*/
    .pagebox__right { display:none !important}
    .events_search.flex-row{
        flex-direction: column-reverse;
    }
    .s_event{
        padding: 15px;
        margin: 10px 0;
        box-shadow: none;
    }
    .s_event-mid{
        display: none;
    }
    .s_event-info > .event_name{
        font-size: 18px;
    }
    /*==============*/


    /*=== ивенты на карте ===*/
    .m_event-top{
        flex-direction: row;
    }
    .m_event-preview .prev_img{
        width: 140px;
        height: 140px;
    }
    /*==============*/

    .riders_search_filter-icon{
        display: block;
    }

    .riders_search-q{
        padding: 20px 20px 10px 20px;
        position: -webkit-sticky;
        position: sticky;
        top: -10px;
    }
    .riders_search-q-form {
        border: 1px solid #eaeaea;
        border-radius: 25px;
        background: #f2f3f5;
        height: 40px;
    }
    .riders_search-q-form input{
        background: none;
    }
    .riders_search-icon{
        top: 12px;
        left: 12px;
    }

    /*====== FILTERS =====*/
    .filter_search-head{
        display: none;
    }

    .riders_search__head{
        display: none;
    }
    .riders_search__head .filter_icon {
        position: absolute;
        top: 0;
        right: 20px;
    }
    .riders_search__head.mbil{
        display: block;
    }

    .riders_search, .bikelogs_search{
        flex-direction: column-reverse;
        align-items: stretch;
    }
    .filter_search{
        margin-left: 0;
        width: 100%;
        max-width: none;
        box-shadow: none;
        background: var(--white);
    }
    .filter_search-close {
        display: block;
        padding: 30px;
    }
    .filter_search-content{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
        background: var(--white);
    }
    /*======================*/

    .events_sidebar {
        display: none;
    }

    .short_rule_block {display: none}

    .comment__left {
        top: 15px;
        left: 10px;
        height: 20px;
    }
    .add_comments form{
        padding-left: 30px;
    }
    .add_comments__area{
        min-height: 50px;
    }
    .comment_foto {
        width: 20px;
    }
    .comment_content {
        padding-left: 0;
    }
    .comment_title{
        padding-left: 30px;
    }
    .comments > li > ul { margin-left: 20px}

    input[type=text], input[type=password], input[type=number], select{
        line-height: 40px;
        border-radius: 5px;
    }

    .filter_box{
        padding: 0 30px;
    }
    .filter_box-title{
        font-size: 24px;
        line-height: 80px;
        padding: 0;
    }
    .filter_box > div{
        padding: 5px 10px;
    }

    .filter_search-content{
        padding: 0;
    }


    .filter_btn {
        padding: 20px 40px;
    }

    .hideMobile{
        display: none !important;
    }

    .eventsCategoriesWidgetBlock,
    .eventsSearchForm{
        margin: 0 10px 10px 10px;
    }

    .events_header{
        flex-direction: column;
        gap: 10px;
    }

    .full_business__reviewsContainer{
        flex-direction: column;
    }

    .full_business__spacer{
        margin: 40px auto;
    }

}