@media screen and (max-width: 640px) {

    /*=============================*/
    .rel_video_image, .rel_video_title { float:none}
    .rel_video, .rel_video:first-child, .rel_video:last-child {height:auto}
    .rel_video_image { width: 100%}
    .rel_video_title { width: 100%; margin: 5px 0 0 0}
    /*=============================*/



    .banner_app_hor {display: none}
    .banner_app_sqa {display: block}
    .app_add-image {display: none}

    .listmenu { display: flex; justify-content: space-between; font-size: 1.1em; padding: 5px 0}
    .listmenu span { display: none}

    .moz_mny > .moz__item,
    .moz_mny > .moz-v { height: 300px }
    .post_content { padding: 7px 7px 0 7px }
    .short_for_main li { width: 50% }
    .footer { flex-direction: column }
    .album_short__image { height: 120px; min-height: 120px }
    .pagebox__descr { padding: 10px }

    .notify_wrap, .notify_block.active .notify_wrap{ max-width: 100%; width:100%; position:fixed; top:50px;min-width: 0}

    .short_bikes li {width:50%}
    .album_short { width: 50%}

    .p_user { width: 100%}

    /*=== новости ===*/
    .news_short__prev { width: 100%; float:none; margin: 0 0 10px 0}

    .news_short__descr { clear:both; margin-left: 0}
    .last_news {display:none}
    /*===============*/

    .stolen_filters .dropmenu {width:100%}
    .st__search { position:relative; width: 98%; float:none !important}

    .subs { width: 100%}

    .file_upload_btn { display: block; max-width: 200px}

    .flast_block {width: 100%}

    /*столен*/
    .st_s { width: 100% !important; margin-right:0 !important}

    .hide_sm {display: none !important}

    .bikes_recbikelogs .rect_bl-btm > .cmts > span{
        display: none;
    }






    /*====================*/
    .cur_con { padding: 0 10px}
    .cur_prize { float:none;}
    .cur_prize img {
        margin: auto auto 10px;
        border-radius: 3px}
    .cur_descr { margin: 0;}

    .cur_stats { display:block}
    .cur_stats > div { border-right:0; border-bottom: 1px solid #FFF}
    .prize-text, .cur_date, .cur_memb { width: 100%}

    .con_cur_last_members { flex-wrap:wrap}
    .con_last_member { width: 16.666%; margin-bottom: 5px}

    .yday_election > li img { max-width: 100%; height:auto; max-height: 45px}

    .el_give_vote { opacity: 1}

    .voting_photo_members > li { width: 100%; padding: 0 0 10px 0}
    /*====================*/

}