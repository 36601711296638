@media screen and (max-width: 800px){

    /*===========================*/
    .profile_full_info { padding: 50px 0 0 0; margin-top: 0}
    .user_social_links { float:none; margin: 10px 0 0 0}
    .profile_func {max-width: 500px; width:auto; float: none; display: flex}
    .profile_func li { margin: 0; width: 50%; padding: 7px}

    .prof__top {  display: flex;  flex-wrap: nowrap;  width: 100%;  float: none;  padding: 10px}

    .profile_blog {
        padding-right: 0;
        width: 100%;
    }
    .profile_sidebar{
        display: none;
    }
    /*===========================*/



    /*===========================*/
    .bikes-items .short_bike{
        width: 100%;
        margin-right: 0 !important;
    }
    .bikes-items{
        margin-right: 0;
    }
    .bikes_recbikelogs {
        margin-right: 0;
    }
    .bikes_recbikelogs > .items > .rect_ovrl {
        width: 100%;
        padding-right: 0;
    }
    /*===========================*/


    .pagebox {
        padding: 10px 0;
    }
    .pagebox.auth_form_block{
        padding-top: 40px;
    }

    .footer__app{
        display: block !important;
    }

    .profile_foto-overlay {width: 80px;}
    .profile_foto {width: 80px; height: 80px;  bottom: -20px}

    .footer__copyrite{
        justify-content: center;
        max-width: 90%;
        margin:auto;
        padding: 10px 0;
        line-height: 30px;
    }

    .empty_block {
        width: 100%;
    }

    /*.n_post__header > a{padding: 10px}
    .n_news__header{padding: 15px 15px 0 15px}
    .n_post__main{padding: 10px 0 0 0}
    .n_post__bottom{padding: 10px}
    .n_news__bottom{padding: 15px 10px 15px 10px}
    .n_story__block+.n_story__block{margin-top: 10px}
    .n_post_content > .bikelogs_tags{
        padding-top: 10px;
    }
    .n_post__main>a>div+div, .n_post__main>div+div{
        padding-top: 10px;
        word-break: break-word;
    }
    .n_post__title, .n_post__text{
        padding-left: 10px;
        padding-right: 10px;
    }
    p.n_story__block{
        padding-left: 10px;
        padding-right: 10px;
    }*/

    .relative_post-overlay{
        width: 50%;
    }
    .relative_post-preview {
        height: 150px;
    }

    .login_btn {display: none}

    .speedbar { border-bottom: 1px solid #ebebeb; border-radius: 0 0 10px 10px}

    .header .full { padding: 0 }
    .full{padding: 0}

    .post__bottom > span { margin-right: 10px}

    .last_func,
    .cnt_m { display: none; }

    .bikelog__full {padding: 10px 10px 0 10px}


    .prof__top-photo{width: 120px}
    .profile_full_cover, .profile_full_cover:before {border-radius: 0}

    span.zoom {display:none}

    .p_user { width: 50%}
    .bike__descr { display:block}
    .bike__func {width: 100%;border: 0;clear: both;display: block}

    .bikes_list_for_news { flex-wrap:wrap}
    .bikes_list_for_news .bike_prev {width: 50%}

    .bikes_recbikelogs > .items > .rect_ovrl{
        width: 50%;
    }

    .bikes_recbikelogs > .items > .rect_ovrl:nth-child(3n+3) {
        padding-right: 20px;
    }

    .bikes_recbikelogs > .items > .rect_ovrl:nth-child(2n+2) {
        padding-right: 0;
    }

    .online_status {
        border-width: 2px;
        top: 54px;
        left: 54px;
    }

    .form_item-container {
        max-width: 90%;
    }
    .form-item__spacer {
        margin: 50px -4%;
    }

    .s_event__header{
        padding: 15px;
    }

    .s_event{
        padding: 0;
        margin: 0;
        flex-direction: column;
    }
    .s_event-preview .prev_img{
        display: none;
    }
    .s_event-preview{
        height: auto;
        flex: 1 1 auto;
        width: 100%;
        margin-bottom: 20px;
    }
    .s_event-preview .prev_img-full{
        display: block;
    }
    .s_event-preview .prev_img-full > img{
        width: 100%;
    }

    .s_event-info {
        padding: 0 20px 20px 20px;
        width: 100%;
        text-align: center;
    }

    .s_event-info > .event_name{
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--d800);
        font-size: 20px;
    }

    .s_event-info > .event_stat{
        display: none;
    }

    .s_event-info > .event_date{
        font-size: 16px;
    }

    .s_event-info > .event_loc{
        font-size: 18px;
    }

    .s_event__article{
        margin: 0 10px 10px 10px
    }

    .fe-btnsfunc{
        flex-direction: column;
        align-items: start;
    }
    .fe-btnsfunc .share-btns_container{
        margin-top: 14px;
    }


    .settingsContainer.flex-row{
        flex-direction: column-reverse;
    }

    .modeObjects{
        max-width: none;
    }

    .addPostFormItem{
        padding: 0 10px;
    }

    .form_item-container{
        padding: 20px 0;
    }

    .addPostFormBottom {
        padding: 5px 10px;
    }

    .addPostFormBottom #saveButton{
        padding: 0 20px;
    }

}