@media screen and (max-width: 1100px){

    .bikes-items .short_bike{
        width: calc(50% - 20px);
    }

    .news_search{
        flex-direction: column-reverse;
    }
    .news_search-filters{
        width: 100%;
        max-width: none;
        margin-left: 0;
        margin-bottom: 20px;
        padding: 20px;
    }

    .sidebar_cats a {
        display: inline-block;
        background: #97abb5;
        margin: 0 5px 5px 0;
        padding: 3px 16px;
        color: #FFF;
        border-radius: 25px;
    }

    .sidebar_cats a:hover, .sidebar_cats a.active, .sidebar_cats a.actlink{
        background: #a1b3bd;
        color: #FFF;
    }
    .sidebar_cats a.active, .sidebar_cats a.actlink{color: #ffffff;background: #069ad4}
    .news_search-filters{
        padding: 0;
    }

    /*ИВЕНТЫ*/
    .box_share.right {line-height: 20px}
    .box_share.right > span:first-child {display: none}
    .pagebox__image-overlay {padding: 12px 10px}
    .btn_b_r,
    .btn_b_rt {line-height: 30px; font-size: 13px}

    .box_share a {width: 24px; height: 24px; margin-top:5px}

    .box_share a.gl_40 {background-position: -552px 0}
    .box_share a.tw_40 {background-position: -552px -48px}
    .box_share a.fb_40 {background-position: -552px -96px}

    .news__content iframe {
        display: block;
        width: 100%;
        height: 38vh;
        border: 0;
        margin: 15px auto;
    }
}