
.addmenu {
    position: fixed;
    bottom: 0;
    background: var(--d800);
    width: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%) translateY(calc(100% + 80px));
    border-radius: 14px;
    padding: 20px 0;
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    transition: all .3s ease-in-out;
    z-index: 94;
}

.addmenu.active{
    transform: translateX(-50%) translateY(0);
    transition: all .3s ease-in-out;
    bottom: 80px
}

.addmenu__header {
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    position: relative;
}

.addmenu__header span.cross {
    display: block;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 16px;
    transform: translateY(-50%);
    padding: 1em;
    line-height: 1em;
}

.addmenu-list__item {
    display: flex;
    padding: 10px 0;
}

.addmenu-list > li{
    padding: 0 20px;
}

.addmenu-list > li:hover{
    background: var(--d800);
}

.addmenu-list > li:last-child .addmenu-list__item{
    margin-bottom: 0;
}

.addmenu-list__icon{
    flex: 0 0 auto;
    font-size: 28px;
    display: flex;
    align-items: center;
}

.addmenu-list__info{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    font-size: 18px;
    color: var(--g100)
}

.addmenu-list__info > span:first-child{

}

.addmenu-list__info > span:last-child{
    font-size: 0.8em;
    color: var(--g300)
}




.bottom_menu__profile > div{
    border-radius: 50px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border: 2px solid #3ea6cc;
}
.bottom_menu__profile img{
    max-width: 100%;
    max-height: 100%;
}

.slidemenu__header {
    line-height: 80px;
    font-size: 24px;
    text-align: center;
    position: relative;
}

.slidemenu__header > span{
    display: block;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 20px;
    font-size: 30px;
    transform: translateY(-50%);
}

.slidemenu__search > a {
    color: #545b64;
    border: 1px solid #808080;
    padding: 2px 20px 2px 50px;
    position: relative;
    border-radius: 50px;
    width: 90%;
    display: block;
    margin: auto;
}
.slidemenu__search > a > i {
    position: absolute;
    top: 50%;
    left: 14px;
    font-size: 22px;
    transform: translateY(-50%);
}
.slidemenu__search > a:hover{
    color: inherit;
}
.slidemenu__p {
    line-height: 80px;
    font-size: 20px;
    padding: 0 30px;
}